import { Component, OnInit } from "@angular/core";
import { Subscription } from "rxjs";
import { Contact } from "src/app/models/contact/contact";
import { Prospect } from "src/app/models/prospect/prospect";
import { AdhesionService } from "src/app/services/adhesion/adhesion.service";
import { LoginService } from "src/app/services/login/login.service";
import { TagService } from "src/app/services/tagService/tag-service.service";

@Component({
    selector: 'app-validation-page-wine-and-spirits',
    templateUrl: './validation-page-wine-and-spirits.component.html',
    styleUrls: ['./validation-page-wine-and-spirits.component.scss']
  })
  export class ValidationPageWineAndSpiritsComponent implements OnInit {
    isGPC678 = true;
    sub: Subscription[] = [];
    companyContact: Contact = null;
    constructor(private adhesionService: AdhesionService,
                private loginService: LoginService,
                public tagService: TagService ) {
      
    }
    ngOnInit(): void {
      this.sub.push(this.adhesionService.prospectUpdated.subscribe(
        (prospect: Prospect) => {
          if (this.loginService.state)
          {
            if (prospect && ( prospect.company?.need >= 1000 || prospect.company?.locationCount >= 1000)) {
              this.isGPC678 = true;
              this.tagService.sendTagWithLabel('Validation', 'Transaction success - SEPA - awaiting ERP');
            } else if (prospect && (prospect.company?.need < 1000 || prospect.company?.locationCount < 1000)) {
              this.isGPC678 = false;
              this.tagService.sendTagWithLabel('Validation', 'Transaction success - SEPA');
            }
          }
        })
      );
      this.sub.push(this.adhesionService.contactUpdated.subscribe(contact => {
        this.companyContact = contact;
      }));
    }
  }