<app-stepper [step]=2></app-stepper>
<div class="page-content">
  <app-support-sticker *ngIf="isNeedHelpEnabled" [currentForm]="null"></app-support-sticker>

  <gs1-form-group class="gs1-spacer-5" *ngIf="!companyManual" [label]="'Votre entreprise'">
    <div *ngIf="!companyManual && !companySelected" class="columns one-to-one row">
      <gs1-select-wrapper class="col-6">
        <span slot="helper">Cherchez par le nom d'entreprise ou son SIREN en entier</span>
        <ng-select [formControl]="researchField" name="search-company" [loadingText]="'Chargement...'" [notFoundText]="'Aucun résultat'" [bindValue]="'searchReference'" [bindLabel]="'name'" [items]="company$ | async"
        [clearable]="false" [placeholder]="'Cherchez par le nom d\'entreprise ou son SIREN en entier'" [typeahead]="companyInput$" [multiple]="false"
        [minTermLength]="3" (change)="companyChosen($event)"
        typeToSearchText="Entrez au moins 3 caractères"
        [loading]="companyLoading" [closeOnSelect]="true">
          <ng-template ng-loadingspinner-tmp>
            <span gs1Spinner [size]="'1.3em'"></span>
          </ng-template>
          <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
            <div class="company-search-result">
              <span class="gs1-little">{{item.name}}</span>
              <span class="gs1-color-ui-5 gs1-small">{{item.address}} {{item.codePosal}} {{item.locality}}</span>
            </div>
          </ng-template>
        </ng-select>
      </gs1-select-wrapper>
      <div class="col-6">
        <gs1-alert [type]="'info'" class="gs1-spacer-3">
          <p class="title-style gs1-color-blue">Vous êtes une administration, un établissement de santé ou un établissement bancaire ?</p>
          <p class="gs1-little">Contactez directement le service client au 01 40 22 18 00 pour votre adhésion.</p>
        </gs1-alert>
        <gs1-alert [type]="'info'" class="gs1-spacer-3">
        <p class="title-style gs1-color-blue">Acteur des filières vin ou spiritueux ? Vous êtes concerné par la réglementation européenne sur les vins, applicable en décembre 2023 ?</p>
        <p class="gs1-little">Vous êtes au bon endroit ! Poursuivez votre adhésion pour obtenir vos QR Codes. N’oubliez pas que 1 QR Code = 1 code GTIN.</p>
      </gs1-alert>
      </div>

    </div>
    <ng-container *ngIf="!companyManual && companySelected">
      <div class="columns two-to-one">
        <div class="columns one-to-one info-entreprises">
          <div>
            <span class="gs1-color-blue gs1-little">Votre entreprise</span>
            <span class="gs1-color-ui-5 gs1-little">{{selectedCompany.name}}</span>
          </div>
          <div>
            <span class="gs1-color-blue gs1-little">SIREN</span>
            <span class="gs1-color-ui-5 gs1-little">{{selectedCompany.siren}}</span>
          </div>
          <div>
            <span class="gs1-color-blue gs1-little">Adresse du siège social</span>
            <span class="gs1-color-ui-5 gs1-little">{{selectedCompany.address}}</span>
            <span class="gs1-color-ui-5 gs1-little">{{selectedCompany.address2}}</span>
            <span class="gs1-color-ui-5 gs1-little">{{selectedCompany.codePostal}} {{selectedCompany.locality}}</span>
          </div>
          <div>
            <span class="gs1-color-blue gs1-little">Code NAF ou APE</span>
            <span class="gs1-color-ui-5 gs1-little">{{selectedCompany.codeNaf}}</span>
            <span class="gs1-color-blue gs1-little">N° TVA intracommunautaire</span>
            <span class="gs1-color-ui-5 gs1-little">{{selectedCompany.intraNumber}}</span>
          </div>
        </div>

        <div class="new-search">
          <button gs1Button [name]="'new-search-button'" [appearance]="'clear'" [color]="'link'" [thin]="true" (click)="resetResearch()">Effectuer une nouvelle recherche</button>
        </div>
      </div>
    </ng-container>
  </gs1-form-group>

  <gs1-alert *ngIf="showIsResigned" [type]="'danger'" [content]="'Cette entreprise a déjà été adhérente à GS1 France. En finalisant cette nouvelle adhésion, vous obtiendrez un nouveau préfixe entreprise ainsi qu\'un nouveau GLN. Vous avez un besoin particulier ? Contactez notre service client au 01 40 22 18 00.'"></gs1-alert>
  <gs1-alert *ngIf="companySelected && companyForm.hasError('alreadyExists')" [type]="'danger'" [content]="'Cette entreprise est déjà adhérente de GS1 France, si vous avez besoin de nouveaux codes, veuillez-vous rapprocher du service client GS1 France au 01 40 22 18 00.'"></gs1-alert>
  <gs1-alert *ngIf="showIsDoubtful" [type]="'danger'" [content]="'Cette société a démissionné de GS1 France. Si vous souhaitez adhérer de nouveau à GS1 France, veuillez contacter le service client au 01 40 22 18 00.'"></gs1-alert>


  <form *ngIf="companyForm" [formGroup]="companyForm" #formContact="ngForm" (ngSubmit)="saveOrConfirmCA()">
    
    <ng-container *ngIf="companySelected && !companyForm.hasError('alreadyExists') && !showIsDoubtful">
      <div id="destination" #destinationRef>
        <gs1-form-group class="gs1-spacer-5" [label]="'Votre activité'">
          <gs1-select-wrapper>
            <label for="activity" *ngIf="showProduct">Votre secteur d'activité</label>
            <ng-select #activity formControlName="activity" name="activity" [bindValue]="'id'" [bindLabel]="'label'" [items]="activities |async"
              [clearable]="false" [searchable]="false" [loadingText]="'Chargement...'" [multiple]="false" [closeOnSelect]="true"></ng-select>
            <span slot="error" *ngIf="displayError('activity', 'required') && showProduct"> Veuillez renseigner votre secteur d'activité</span>
            <span slot="error" *ngIf="displayError('activity', 'required') && !showProduct"> Veuillez renseigner votre activité</span>
          </gs1-select-wrapper>
          <gs1-alert class="gs1-spacer-3" *ngIf="activityMessage && activityMessage.length > 1">
            <p class="gs1-little">{{activityMessage}}</p>
          </gs1-alert>
          <gs1-select-wrapper *ngIf="showProduct">
            <label for="productId">Votre produit principal</label>
            <ng-select *ngIf="showProduct" formControlName="productId" name="productId" [bindValue]="'id'" [bindLabel]="'label'" [items]="codesProduits |async"
              [clearable]="true" [searchable]="true" [loadingText]="'Chargement...'" [multiple]="false" [closeOnSelect]="true"></ng-select>
            <span slot="error" *ngIf="displayError('productId', 'required')">Veuillez renseigner votre produit principal</span>
          </gs1-select-wrapper>
        </gs1-form-group>
      </div>

      <gs1-form-group class="gs1-spacer-5" [label]="'Vos canaux de distribution'">
        <gs1-select-wrapper>
          <label for="canal-principal">Canal de distribution principal</label>
          <ng-select formControlName="canal" name="canal-principal" [bindValue]="'id'" [bindLabel]="'label'" [items]="canaux | async"
            [clearable]="false" [searchable]="false" [multiple]="false" [loadingText]="'Chargement...'" [closeOnSelect]="true"></ng-select>
          <span slot="error" *ngIf="displayError('canal', 'required')">Veuillez renseigner votre canal principal de distribution</span>
        </gs1-select-wrapper>

        <gs1-select-wrapper>
          <label for="canal-secondaire">Canaux de distribution secondaires (optionnel)</label>
          <span slot="helper">Séléctionnez jusqu'à 3 canaux</span>
          <ng-select formControlName="canauxSecondaires" name="canal-secondaire" [bindValue]="'id'" [bindLabel]="'label'" [items]="canauxSecond"
            [clearable]="false" [searchable]="false" [multiple]="true" [maxSelectedItems]="3" [closeOnSelect]="false"
            [hideSelected]="true"></ng-select>
          <span slot="error" *ngIf="displayError('canauxSecondaires', 'maxChoices')">Vous ne pouvez pas sélectionner plus de 3 canaux secondaires</span>
        </gs1-select-wrapper>

      </gs1-form-group>

      <gs1-form-group [label]="'Votre chiffre d\'affaires'">
        <gs1-select-wrapper class="groupForm">
          <label for="CA">Votre tranche de chiffre d'affaires</label>
          <span slot="helper">Votre chiffre d'affaires détermine le montant de votre adhésion</span>
          <ng-select appHelp [tag]="'J\'ai compris - Tranche CA'" panelClass="helpPanel" (helpClosed)="caSelect.open()" #caSelect
            formControlName="caTranche" name="CA" [bindValue]="'id'" [bindLabel]="'info'" [items]="caTranches | async"
            [clearable]="false" [searchable]="false" [multiple]="false" [loadingText]="'Chargement...'" [closeOnSelect]="true"
            [hideSelected]="true" [appendTo]="'body'"></ng-select>
          <span slot="error" *ngIf="displayError('caTranche', 'required')">Veuillez renseigner votre chiffre d'affaires</span>
        </gs1-select-wrapper>
        <div class="helpContent" style="width: 97.5%;">
          <p class="gs1-little" style="width: 100%;">Votre chiffre d'affaires détermine le montant de votre adhésion annuelle. Comme précisé dans nos Conditions Générales d'Adhésion, il est déclaré annuellement et peut-être vérifié par GS1 France.</p>
          <p class="gs1-little" style="width: 100%;">Le montant de votre adhésion est indépendant du nombre de codes dont vous avez besoin.</p>
        </div>
         <div class="helpTitle" style="width: 97.5%;">Sélectionnez la tranche correspondant à votre chiffre d'affaires</div>
      </gs1-form-group>
      
      <gs1-alert class="gs1-spacer-3" *ngIf="highRangeSelected">
        <span class="gs1-little">Vous avez besoin d'un devis ? Contactez le service client au 01 40 22 18 00.</span>
      </gs1-alert>

      <div *ngIf="tarifInfo && !isBlockedActivity" class="block-info tarif-detail gs1-little gs1-spacer-3">
        <div>
          <span>
            Droit d'entrée
            <i class="bi bi-question-circle" matTooltip="Payable une seule fois au moment de votre adhésion. Son montant est identique à celui de votre licence annuelle." [matTooltipPosition] = "'above'"></i>
          </span>
          <span>{{tarifInfo.membershipFeeHT | number:'1.2-2':'fr-FR'}} €</span>
        </div>
        <div>
          <span>
            Montant de votre première adhésion annuelle
            <i class="bi bi-question-circle" matTooltip="Le montant de l'adhésion pour votre première année est calculé au prorata semestriel." [matTooltipPosition]="'above'"></i>
          </span>
          <span>
            <span class="lg">({{tarifInfo.subscriptionAmountWithoutProrataHT | number:'1.2-2':'fr-FR'}} - {{tarifInfo.deductedProrata | number:'1.2-2':'fr-FR'}}) =&nbsp;</span>
            {{tarifInfo.subscriptionAmountWithProrataHT | number:'1.2-2':'fr-FR'}} €
          </span>
        </div>
        <mat-divider></mat-divider>
        <div class="bold">
          <span>Total HT</span>
          <span>{{tarifInfo.totalAmountHT | number:'1.2-2':'fr-FR'}} €</span>
        </div>
        <div>
          <span>TVA ({{tarifInfo.vaT_Rate | number:'1.0-2':'fr-FR'}}%)</span>
          <span>{{tarifInfo.vaT_Amount | number:'1.2-2':'fr-FR'}} €</span>
        </div>
        <mat-divider></mat-divider>
        <div class="bold">
          <span>Total TTC</span>
          <span id="total-ttc">{{tarifInfo.totalAmountTTC | number:'1.2-2':'fr-FR'}} €</span>
        </div>
      </div>

      <div class="action-bar">
        <button gs1Button [disabled]="!companyForm.valid">Etape suivante</button>
      </div>
    </ng-container>
  </form>
  <ng-template #confirmDialog>
    <h3 matDialogTitle>Merci de confirmer votre activité</h3>
    <mat-dialog-content>
      <p>Vous avez sélectionné l'activité {{activityLabel}}, merci de contacter le service client au 01 40 22 18 00 pour finaliser votre adhésion avec cette activité.</p>
    </mat-dialog-content>
    <mat-dialog-actions>
        <button gs1Button [appearance]="'outlined'" matDialogClose (click)="modifyActivity()">Changer l'activité</button>
        <a href="https://www.gs1.fr" (click)="saveAndSendLeavingTag()" gs1Button matDialogClose cdk-focus-start >Quitter le parcours</a>
    </mat-dialog-actions>
  </ng-template>
  <ng-template #caConfirmDialog>
    <h3 matDialogTitle>Merci de confirmer votre chiffre d'affaires</h3>
    <mat-dialog-content>
      <p class="gs1-little">
        Votre chiffre d'affaires détermine le montant de votre adhésion. Si vous vous êtes trompés de tranche, vous pouvez la corriger en cliquant sur le bouton "Je corrige".
        <!-- A titre d'information, un email de confirmation de création de votre profil sera envoyé à l'adresse <span class="important">{{contactForm.get('email').value}}</span>. Si cette adresse est incorrecte, vous pouvez la corriger.</p> -->
    </mat-dialog-content>
    <mat-dialog-actions>
      <button gs1Button matDialogClose [appearance]="'outlined'" (click)="correctCA()">Je corrige</button>
      <button gs1Button cdk-focus-start (click)="saveAndProceed()">Je confirme</button>
    </mat-dialog-actions>
  </ng-template>
</div>
