import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA} from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators, FormGroupDirective } from '@angular/forms';
import { CallcenterService } from 'src/app/services/callcenter/callcenter.service';
import { Subscription } from 'rxjs';
import { CallData } from 'src/app/models/call-data/call-data';
import { ServiceClientDialogData } from 'src/app/models/service-client-data/service-client-data';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-service-client-dialog',
  templateUrl: './service-client-dialog.component.html',
  styleUrls: ['./service-client-dialog.component.scss']
})
export class ServiceClientDialogComponent implements OnInit {
  contact: ServiceClientDialogData;
  rappelForm: FormGroup;
  modifyContact = false;
  notConfirmed = true;
  motifs: any[] = [
    {value: 'La création de mon profil', label: 'La création de mon profil'},
    {value: 'L\'identification de mon entreprise', label: 'L\'identification de mon entreprise'},
    {value: 'Le montant de mon adhésion', label: 'Le montant de mon adhésion'},
    {value: 'Mon besoin en codes', label: 'Mon besoin en codes'},
    {value: 'Le paiement de mon adhésion', label: 'Le paiement de mon adhésion'},
    {value: 'Un autre motif', label: 'Un autre motif'}
  ];
  genderIdValues = environment.genderIdValues;
  @ViewChild('formRappel') formRappel: FormGroupDirective;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ServiceClientDialogData,
    public fb: FormBuilder,
    public callcenterService: CallcenterService) {
      this.contact = data;
  }

  ngOnInit(): void {
    this.rappelForm = this.fb.group({
      genderId: [this.contact.genderId, [Validators.required]],
      comment: [null, [Validators.required, Validators.maxLength(100)]],
      motif: [null, Validators.compose([Validators.required, Validators.minLength(2), Validators.maxLength(50)])],
      lastname: [
        this.contact.lastname,
        Validators.compose([Validators.required, Validators.minLength(2), Validators.pattern(/[A-Za-zà-ù\-\s]+/)])],
      firstname: [
        this.contact.firstname,
        Validators.compose([Validators.required, Validators.minLength(2), Validators.pattern(/[A-Za-zà-ù\-\s]+/)])],
      phone: [this.contact.phone, [Validators.required]]
    });
    if (this.rappelForm.get('lastname').invalid || this.rappelForm.get('firstname').invalid || this.rappelForm.get('phone').invalid) {
      this.modifyContact = true;
    }
  }

  displayError(field: string, error: string): boolean {
    const fieldState = this.rappelForm.get(field);
    if (!(fieldState.dirty || fieldState.touched)) {
      return false;
    }
    return fieldState.hasError(error);
  }

  sendAskForCall() {
    if (this.rappelForm.valid) {
      const subscribed: Subscription = this.callcenterService.askForCall(this.rappelForm.value as CallData).subscribe(
        () => {
          this.notConfirmed = false;
          subscribed.unsubscribe();
        },
        () => subscribed.unsubscribe()
      );
    }
  }
}

