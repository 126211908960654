import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';

import { AdhesionService } from 'src/app/services/adhesion/adhesion.service';

import { OAuthService } from 'angular-oauth2-oidc';
import { LoginService } from 'src/app/services/login/login.service';



@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(public adhesionService: AdhesionService, public loginService: LoginService,
              private oauthService: OAuthService, private router: Router) {
  }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
      this.loginService.refreshStateMembership();
      if (this.oauthService.hasValidAccessToken() && this.loginService.state.isActiveMembership ) {
        this.loginService.hasContact = true;
        return true;
      } else {
        this.loginService.login(false);
      }
  }
}
