import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { User } from 'src/app/models/user/user';
import { Observable, of, BehaviorSubject, timer } from 'rxjs';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { LoaderService } from '../loader/loader.service';
import { tap, map, catchError, finalize, retry, delay, retryWhen, delayWhen } from 'rxjs/operators';
import { TarifData } from 'src/app/models/tarif-data/tarif-data';
import { Company } from 'src/app/models/company/company';
import { Contact } from 'src/app/models/contact/contact';
import { Prospect } from 'src/app/models/prospect/prospect';
import { ErrorService } from '../error/error.service';
import { Router } from '@angular/router';
import { Paiement } from 'src/app/models/paiement/paiement';
import { TagService } from '../tagService/tag-service.service';


@Injectable({
  providedIn: 'root'
})
export class AdhesionService {
  user: User;
  company: Company;
  id: string;
  contact: Contact;
  billContact: Contact;
  paiement: Paiement;
  tarif: TarifData;
  emailConfirm = false;
  isRejectedSepa = false;
  moneticoHtml = '';
  isDone = false;
  isAllowedCb = false;
  isProspect = false;
  canSepaBeSkipped = false;
  hasAlreadyTriedCB = false;
  isBreakMembership = false;
  isParcoursExists = false;
  isNotParcoursExists = false;
  tmpPassword: string;
  prospect: Prospect;
  userUpdated: BehaviorSubject<User> = new BehaviorSubject<User>(null);
  companyUpdated: BehaviorSubject<Company> = new BehaviorSubject<Company>(null);
  contactUpdated: BehaviorSubject<Contact> = new BehaviorSubject<Contact>(null);
  billContactUpdated: BehaviorSubject<Contact> = new BehaviorSubject<Contact>(null);
  paiementUpdated: BehaviorSubject<Paiement> = new BehaviorSubject<Paiement>(null);
  tarifUpdated: BehaviorSubject<TarifData> = new BehaviorSubject<TarifData>(null);
  prospectUpdated: BehaviorSubject<Prospect> = new BehaviorSubject<Prospect>(null);

  constructor(
    private http: HttpClient, private router: Router,
    private loader: LoaderService, public errorService: ErrorService,
    private tagService: TagService) {
  }

  saveAndProceed(currentStep?: number): Observable<Prospect | boolean> {
    this.loader.registerWait('saveAndProceed');
    const prospect = this.getProspect(currentStep);
    if (prospect.id) {
      return this.http.patch<Prospect | null>(`${environment.baseUrl}/api/prospects/${prospect.id}`, prospect, {observe: 'response'}).pipe(
        map((response: HttpResponse<Prospect>) => {
          if (response.body && response.body.id) {
            this.dispatchProspect(response.body);
          }
          if (response.status === 202 && response.body.isRejectedSepa) {
            this.isRejectedSepa = true;
          }
          if (response.status === 200 && response.body.moneticoHtml && response.body.moneticoHtml.length > 1) {
            this.moneticoHtml = response.body.moneticoHtml;
          }
          this.saveProspect();
          return response.body;
        }),
        catchError(err => {
          this.errorService.handleError(err);
          return of(false);
        }),
        finalize(() => this.loader.endWaiting('saveAndProceed'))
      );
    } else {
      return this.postProspect(prospect);
    }
  }
  private postProspect(prospect : any)
  {
    return this.http.post<Prospect>(`${environment.baseUrl}/api/prospects`, prospect).pipe(
      tap((data: Prospect) => {
        if(data.id === null) {
          this.errorService.handleNullLoginIdError();
          this.loader.endWaiting('implicitLogin');
          return;
        }
        this.dispatchProspect(data);
        this.saveProspect();
        this.tmpPassword = prospect.contact.password;
      }),
      catchError(err => {
        this.errorService.handleError(err);
        return of(false);
      }),
      finalize(() => this.loader.endWaiting('saveAndProceed'))
    );
  }
  public redirectToSetp2(email:string) {
    this.getContact(email)
      .pipe(
        catchError(err => of(null)),
        tap(() => this.loader.endWaiting('getContact'))
      ).subscribe( data  => {
        if (data) {
          let content = window.sessionStorage.getItem("contact");
          this.contact = JSON.parse(content);
          this.contact.contactId = data.contactId;
          this.contact.loginId = data.loginId;
          this.postProspect({contact : this.contact}).subscribe(async (nok) => {
            if (!nok || typeof(nok) !== 'boolean') {
              this.router.navigate([`mon-entreprise/${this.id}`]);
            };
          });
        }
    });

}
  saveAndExit(step?: number) {
    if (this.id) {
      const prospect = this.getProspect(step);

      this.http.patch<Prospect | null>(`${environment.baseUrl}/api/prospects/${prospect.id}`, prospect, {observe: 'response'})
        .subscribe(() => {
          this.saveProspect();
        }, (err) => this.errorService.handleError(err));
    }
  }

  createAndSignMandate(): Observable<any> {
    this.loader.registerWait('mandate', 'Vous êtes redirigés vers la page de signature du mandat SEPA.');
    const prospect = this.getProspect(4);
    return this.http.patch<Prospect>(`${environment.baseUrl}/api/prospects/${prospect.id}`, prospect).pipe(
      map((data: Prospect) => {
        this.saveProspect();
        return {isOk: true, content: data.mandateValidationURL};
      }),
      catchError((err) => {
        this.loader.endWaiting('mandate');
        if (typeof(err.error) === 'string' && err.error.startsWith('NOK')) {
          return of({isOk: false, content: err.error.split(',')[0]});
        } else if (err.status.toString() !== '500') {
          return of({isOk: false, content: null});
        }
        err.message = 'Nous n\'avons pas pu joindre le service de prélèvement SEPA, nous vous invitons à payer votre adhésion par carte bancaire.';
        this.errorService.handleError(err, true);
        return of({isOk: false, content: null});
      })
      // finalize(() => this.loader.endWaiting('mandate'))
    );
  }

  getContact(email): Observable<Contact> {
    return this.http.get<Contact>(`${environment.baseUrl}/api/prospects/contact?email=${email}`);
  }

  getTarif(category: string, zipCode: string): Observable<TarifData> {
    this.loader.registerWait('tarif');
    const dataPost = {
      ContributionBracketId: category,
      CountryCode: 'FR',
      PostalCode: zipCode
    };
    return this.http.get<TarifData>(`${environment.baseUrl}/api/pricing?ContributionBracketId=${category}&CountryCode=FR&PostalCode=${zipCode}`).pipe(
      tap( data => {
        this.tarif = data;
        this.tarifUpdated.next(data);
      }),
      catchError(err => {
        this.errorService.handleError(err);
        return of(null);
      }),
      finalize(() => this.loader.endWaiting('tarif'))
    );
  }

  getCompanyContact() {
    const contact: Contact = {
      lastname: this.user?.lastname || null,
      firstname: this.user?.firstname || null,
      companyName: this.company?.name || null,
      street: this.company?.address || null,
      street2: this.company?.address2 || null,
      zipCode: this.company?.codePostal.toString() || null,
      city: this.company?.locality || null,
      email: this.user?.email || null,
      phone: this.user?.phone1 || null,
      functionId: this.user?.functionId || null,
      genderId: this.user?.genderId || null,
      phone2: this.user?.phone2 || this.user?.phone1,
      contactId: this.user?.contactId || null,
      loginId: this.user?.contactId || null,


    };
    this.contact = contact;
    this.contactUpdated.next(this.contact);
  }

  getProspect(forStep?: number): Prospect {
    if (!forStep) {
      return {
        id: this.id || null,
        contact: this.user,
        company: this.company || null,
        paiement: this.paiement || null,
        billContact: this.billContact || null,
        isRejectedSepa: this.isRejectedSepa,
        moneticoHtml: this.moneticoHtml,
        isDone: this.isDone,
        isAllowedCb: this.isAllowedCb,
        canSepaBeSkipped: this.canSepaBeSkipped,
        hasAlreadyTriedCB: this.hasAlreadyTriedCB,
        isBreakMembership: this.isBreakMembership

      };
    } else if (forStep === 1) {
      return {
        id: this.id || null,
        contact: this.user
      };
    }else if (forStep === 2) {
      return {
        id: this.id || null,
        company: this.company || null
      };
    }else if (forStep === 3) {
      return {
        id: this.id || null,
        company: {
          siren: this.company.siren,
          need: Number(this.company.need) || null,
          locationCount: Number(this.company.locationCount) || null,
          brandOwnerShip: this.company.brandOwnerShip || null
        }
      };
    }else if (forStep === 4) {
      return {
        id: this.id || null,
        paiement: this.paiement || null,
        billContact: this.billContact || null,
        isRejectedSepa: this.isRejectedSepa,
        isAllowedCb: this.isAllowedCb,
        moneticoHtml: this.moneticoHtml,
        canSepaBeSkipped: this.canSepaBeSkipped,
        hasAlreadyTriedCB: this.hasAlreadyTriedCB
      };
    } else if (forStep === 3.5) {
      return {
        id: this.id || null,
        billContact: this.billContact || null
      };
    }
  }

  dispatchProspect(prospect: Prospect, redirect?: boolean, sendTag?: boolean) {
    if (prospect) {
      this.prospect = prospect;
      this.prospectUpdated.next(this.prospect);
      this.id = prospect.id || this.id;
      this.user = prospect.contact || this.user;
      this.company = prospect.company || this.company;
      if (this.company) {
        this.company.need = prospect.company?.need || this.company?.need;
        this.company.locationCount = prospect.company?.locationCount || this.company?.locationCount;
      }
      this.getCompanyContact();
      this.billContact = prospect.billContact || this.billContact;
      this.paiement = prospect.paiement || this.paiement;
      this.isRejectedSepa = prospect.isRejectedSepa || this.isRejectedSepa;
      this.isAllowedCb = prospect.isAllowedCb || this.isAllowedCb;
      this.moneticoHtml = prospect.moneticoHtml || this.moneticoHtml;
      this.isDone = prospect.isDone || this.isDone;
      this.isBreakMembership = prospect.isBreakMembership || this.isBreakMembership;
      this.canSepaBeSkipped = prospect.canSepaBeSkipped;
      this.hasAlreadyTriedCB = prospect.hasAlreadyTriedCB || this.hasAlreadyTriedCB;
      this.userUpdated.next(this.user);
      this.companyUpdated.next(this.company);
      this.contactUpdated.next(this.contact);
      this.billContactUpdated.next(this.billContact);
      this.paiementUpdated.next(this.paiement);
    }
    if (redirect) {
      this.redirectToCurrentStep(sendTag);
    }
  }

  redirectToCurrentStep(sendTag?: boolean) {
    if (this.id) {
      if (this.isFinished()) {
        this.getCompany().subscribe(
          (data: Company) => {
            if(data) {
              if(data.sector === 'VIN & SPIRITUEUX') {
                this.router.navigate([`confirmation-adhesion-vins-et-spiritueux/${this.id}`]);
              }else {
                this.router.navigate([`confirmation-adhesion/${this.id}`]);
              }
            }
              
            }),
            catchError(err => {
              this.errorService.handleError(err);
              return of(false);
            });
        
      }
      else if (this.isBreakMembership) {
        this.router.navigate([`mon-entreprise/${this.id}`]);
         }
      else if ((this.company?.need && this.company?.need > 0) || (this.company?.locationCount && this.company?.locationCount > 0)) {
       this.router.navigate([`paiement-adhesion/${this.id}`]);
      } else if (this.company && this.company.caTranche && this.company.siren && this.company.canal && this.company.activity) {
        this.router.navigate([`mes-besoins/${this.id}`]);
      } else if (this.user && this.user.lastname) {
        this.router.navigate([`mon-entreprise/${this.id}`]);
      } else {
        this.router.navigate([`mes-informations/${this.id}`]);
      } 
    } else {
      this.router.navigate(['mes-informations']);
    }
     if (sendTag) {
      this.tagService.sendTagWithCategory('Resume funnel', `Step ${this.tagService.getCurrentStep}`, 'Step 1');
    }
  }

  saveProspect() {
    const prospect: Prospect = this.getProspect();
    if (prospect.id) {
      prospect.contact.password = null;
      window.sessionStorage.setItem('gs1Prospect', JSON.stringify(prospect));
    }
  }

  async retrieveProspect(id?: string, sendTag?: boolean, email?: string) {
    if(!id && email) {
       await this.getLogin(email);
    }
    else if (id) {
      this.id = id;
      if(this.prospect == undefined) {
        this.retrieveProspectFromServer(id, sendTag);
      }
    } else if (window.sessionStorage.getItem('gs1Prospect')) {
      this.loader.registerWait('retrieveProspect');
      const prospect: Prospect = JSON.parse(localStorage.getItem('gs1Prospect'));
      if (prospect && prospect.company) {
        this.getTarif(prospect.company.caTranche, prospect.company.codePostal).subscribe(() => {
          this.loader.endWaiting('retrieveProspect');
          if (!id) {this.dispatchProspect(prospect, true); }
        });
      } else {
        this.dispatchProspect(prospect, true);
        this.loader.endWaiting('retrieveProspect');
      }
    }
  }

 async getLogin(email: string): Promise<string> {
    this.loader.registerWait('retrieveLogin');
    await this.http.get<any>(`${environment.baseUrl}/api/prospects/login?email=${encodeURIComponent(email)}`).pipe(
      catchError(err => {
        this.errorService.handleError(err);
        return(of(null));
      }),
      tap(() => this.loader.endWaiting('retrieveLogin'))).subscribe(data => {
       if(data && data.id) {
        this.retrieveProspectFromServer(data.id, null);
        return data.id;
       } else {
        this.router.navigate([`mes-informations`]);
       }
      });
      return null;
  }

  retrieveProspectFromServer(id: string, sendTag?: boolean) {
    this.loader.registerWait('retrieveProspect');
    console.log('get prospect')
    this.http.get<Prospect>(`${environment.baseUrl}/api/prospects/${id}`).pipe(
      catchError(err => {
        this.errorService.handleError(err);
        return(of(null));
      }),
      tap(() => this.loader.endWaiting('retrieveProspect'))
    ).subscribe(data => {
      if (data && data.company) {
        this.getTarif(data.company.caTranche, data.company.codePostal).subscribe(() => {
          this.dispatchProspect(data, true, sendTag);
          this.saveProspect();
        });
      } else if (data) {
        this.dispatchProspect(data, true, sendTag);
        this.saveProspect();
      }
    });
  }

  async canAccessCodeOnline(): Promise<boolean> {
    let timeout = false;
    setTimeout(() => {
      timeout = true;
    }, 60000);
    const gln = await this.http.get<Prospect>(`${environment.baseUrl}/api/prospects/${this.id}`).pipe(
      map(data => {
        if (!data.id || timeout && (!data.company || !data.company.gln || !data.company.hasPrivileges)) {
          return false;
        }
        if (!data.company.gln || !data.company.hasPrivileges) {
          throw data;
        }
        return true;
      }),
      retryWhen(error =>
        error.pipe(
          delayWhen(() => timer(5000))
        )
      )
    ).toPromise();
    return gln;
  }

  removeProspect() {
    window.sessionStorage.removeItem('gs1Prospect');
    window.localStorage.removeItem('gs1Prospect');
    // this.prospect = null;
    // this.user = null;

  }

  isFinished() {
    return (this.isDone && !this.isBreakMembership);
  }

  getCompany() {
    return this.http.get<Company>(`${environment.baseUrl}/api/prospects/companyInfo?idProspect=${this.id}`);
  }
}

