import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ContactInfosComponent } from './components/contact-infos/contact-infos.component';
import { CompanyInfosComponent } from './components/company-infos/company-infos.component';
import { NeedInfosComponent } from './components/need-infos/need-infos.component';
import { PaiementInfosComponent } from './components/paiement-infos/paiement-infos.component';
import { ValidationPageComponent } from './components/validation-page/validation-page.component';
import { ContactInfosGuard } from './guards/contact-infos/contact-infos.guard';
import { CompanyInfosGuard } from './guards/company-infos/company-infos.guard';
import { NeedInfosGuard } from './guards/need-infos/need-infos.guard';
import { PaiementInfosGuard } from './guards/paiement-infos/paiement-infos.guard';
import { ValidationGuard } from './guards/validation/validation.guard';
import { AuthGuard } from './guards/shared/oauth2.guard';
import { LoaderComponent } from './components/loader/loader.component';
import { Connect1Guard } from './guards/connect1/connect1.guard';
import { ValidationPageWineAndSpiritsComponent } from './components/validation-page-wine-and-spirits/validation-page-wine-and-spirits.component';


const routes: Routes = [
  {path: '', redirectTo: 'mes-informations', pathMatch: 'full'},
  {path: 'mes-informations', component: ContactInfosComponent, canActivate: [ContactInfosGuard]},
  {path: 'mes-informations/:id', component: ContactInfosComponent, canActivate: [AuthGuard, ContactInfosGuard]},
  {path: 'mon-entreprise/:id', component: CompanyInfosComponent, canActivate: [AuthGuard, CompanyInfosGuard]},
  {path: 'mes-besoins/:id', component: NeedInfosComponent, canActivate: [ AuthGuard, NeedInfosGuard ]},
  {path: 'paiement-adhesion/:id', component: PaiementInfosComponent, canActivate: [AuthGuard, PaiementInfosGuard]},
  {path: 'login', component: LoaderComponent, canActivate: [Connect1Guard]},
  {path: 'confirmation-adhesion/:id', component: ValidationPageComponent, canActivate: [AuthGuard, ValidationGuard]},
  {path: 'confirmation-adhesion-vins-et-spiritueux/:id', component: ValidationPageWineAndSpiritsComponent, canActivate: [AuthGuard, ValidationGuard]},
  {path: '**', redirectTo: 'mes-informations', pathMatch: 'full'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
