import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PopupService {
  private popupOpen = new BehaviorSubject<boolean>(false);
  
   popupOpen$ = this.popupOpen.asObservable();

   setPopupOpen(isOpen: boolean): void {
    this.popupOpen.next(isOpen);
  }
}
