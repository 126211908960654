import { Pipe, PipeTransform } from '@angular/core';
import { parsePhoneNumber, CountryCode} from 'libphonenumber-js/max';

@Pipe({
  name: 'phone'
})
export class PhonePipe implements PipeTransform {

  transform(value: number | string, country?: string): any {
    if (!value) {
      return value;
    }
    try {
      if (!country || country === 'FR') {
        const libPhoneNumber = parsePhoneNumber(value + '', 'FR');
        let phoneNumber = value + '';
        phoneNumber = phoneNumber.replace('+33', '0');
        let formatedNumber = '';
        while (phoneNumber.length > 0) {
          formatedNumber += phoneNumber.slice(0, 2) + ' ';
          phoneNumber = phoneNumber.slice(2);
        }
        return formatedNumber.trim();
      } else {
        const phoneNumber = parsePhoneNumber(value + '', country as CountryCode);
        return phoneNumber.formatNational();
      }
    } catch (e) {
      return value;
    }
  }

}
