import { Component, OnInit, HostBinding, HostListener, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ServiceClientDialogComponent } from '../dialogs/service-client-dialog/service-client-dialog.component';
import { AdhesionService } from 'src/app/services/adhesion/adhesion.service';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { TagService } from 'src/app/services/tagService/tag-service.service';

@Component({
  selector: 'app-support-sticker',
  templateUrl: './support-sticker.component.html',
  styleUrls: ['./support-sticker.component.scss']
})
export class SupportStickerComponent implements OnInit {

  @HostBinding('class.open') opened = false;

  @Input() currentForm: FormGroup;

  constructor(public dialog: MatDialog, public adhesionService: AdhesionService, public tagService: TagService) { }

  ngOnInit(): void {
  }

  @HostListener('click', ['$event'])
  @HostListener('mouseenter', ['$event'])
  open() {
    this.opened = !this.opened;
    this.tagService.sendTagWithStep('Help picto');
  }

  @HostListener('mouseleave', ['$event'])
  close() {
    this.opened = false;
  }

  launchModal() {
    this.dialog.open(ServiceClientDialogComponent, {
      maxWidth: 700,
      data: {
        phone: this.getDataValue('phone1'),
        lastname: this.getDataValue('lastname'),
        firstname: this.getDataValue('firstname'),
        genderId: this.getDataValue('genderId')
      }
    });
  }

  getDataValue(name) {
    if (this.adhesionService.user && this.adhesionService.user[name]) {
      return this.adhesionService.user[name];
    }
    if (this.currentForm && this.currentForm.get(name).value) {
      return this.currentForm.get(name).value;
    }
    return null;
  }

}
