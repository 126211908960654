import { Component, OnInit, ViewChild, OnDestroy, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators, FormGroupDirective, AbstractControl, FormControl, AsyncValidatorFn } from '@angular/forms';
import { Observable, Subscription, of } from 'rxjs';

import { StaticInfoService } from 'src/app/services/static-info/static-info.service';
import { CustomValidators } from 'src/app/validators/custom-validators/custom-validators';
import { AdhesionService } from 'src/app/services/adhesion/adhesion.service';
import { User } from 'src/app/models/user/user';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { ListSelect } from 'src/app/models/list-select/list-select';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { TagService } from 'src/app/services/tagService/tag-service.service';
import { LoaderService } from 'src/app/services/loader/loader.service';
import { Contact } from 'src/app/models/contact/contact';
import { LoginService } from 'src/app/services/login/login.service';
import { ErrorService } from 'src/app/services/error/error.service';
import { StateMembership } from 'src/app/models/StateMembership/SateMembership';
import { catchError, tap } from 'rxjs/operators';
import { NgxMatIntlTelInputComponent } from 'ngx-mat-intl-tel-input';
import { SmartPushService } from 'src/app/services/smartPush/smartPush.service';

@Component({
  selector: 'app-contact-infos',
  templateUrl: './contact-infos.component.html',
  styleUrls: ['./contact-infos.component.scss']
})
export class ContactInfosComponent implements OnInit, OnDestroy {

  contactForm: FormGroup;
  fonctions: Observable<ListSelect[]>;
  subscriptionsInput: Subscription[] = [];
  emailOnLoad: string = null;
  passwordShowing = false;
  passwordConfirmShowing = false;
  isProspect = false;
  isNotFullSsoAccount = false;
  genderIdValues = environment.genderIdValues;
  ssoEnv = this.dom.bypassSecurityTrustResourceUrl(environment.ssoFullLogin);
  Url: any;
  isLogged = false;
  CurrentContact: Contact;
  genderId: string;
  disableButton = false;
  @ViewChild('formContact') formContact: FormGroupDirective;
  @ViewChild('confirmDialog', { static: true }) confirmDialog: TemplateRef<any>;
  @ViewChild('phone1') phone1Field: NgxMatIntlTelInputComponent;
  @ViewChild('phone2') phone2Field: NgxMatIntlTelInputComponent;

  constructor(
    private router: Router,
    private titleService: Title,
    private fb: FormBuilder,
    public dialog: MatDialog,
    private staticService: StaticInfoService,
    private loader: LoaderService,
    public adhesionService: AdhesionService,
    private loginService: LoginService,
    public tagService: TagService,
    private dom: DomSanitizer,
    private smartPushService: SmartPushService ) {
      this.titleService.setTitle('mes informations personnelles - GS1 France');
    }



  ngOnInit(){
    window.addEventListener('message', (event) => {
      if (event.origin !== environment.ssoUrl) {
        return;
      }
      if (event.data
        && this.loginService.state
        && (!this.loginService.state.isActiveMembership || this.loginService.state.isNotFullSsoAccount)) {
        this.goNextStep();
      }
    });
    if (!this.CurrentContact
      && this.loginService.state
      && this.loginService.state.isActiveMembership
      && !this.loginService.state.isNotFullSsoAccount) {
       this.getContact();
       this.isLogged = true;
    }
    this.isProspect = this.adhesionService.isProspect ;

    if (this.adhesionService.id
      && !this.loginService.state.isNotFullSsoAccount) {
      this.isLogged = true;
    }
    /*if (window.localStorage.getItem('contact') && this.loginService.loginInfo.isLogged)
    { this.isLogged = true;
      this.CurrentContact = JSON.parse(window.localStorage.getItem('contact'));

    }*/
    this.fonctions = this.staticService.jobTitlesSub;
    this.subscriptionsInput.push(this.adhesionService.userUpdated.subscribe((user: User) => {
      this.getGender();
      this.contactForm = this.fb.group({
        genderId: [this.isLogged ? this.genderId || null : null, [Validators.required]],
        firstname: [this.isLogged ? this.CurrentContact?.firstname || null : null,
           Validators.compose([Validators.required, Validators.minLength(2),
             Validators.pattern(/[A-Za-zà-ù\-\s]+/)])],
        lastname: [this.isLogged ? this.CurrentContact?.lastname || null : null,
           Validators.compose([Validators.required, Validators.minLength(2),
             Validators.pattern(/[A-Za-zà-ù\-\s]+/)])],
        phone1: [this.isLogged ? this.CurrentContact?.phone : null, [Validators.required]],
        phone2: [this.isLogged ? this.CurrentContact?.phone2 : null, null],
        functionId: [this.isLogged ? this.CurrentContact?.functionId || null : null, [Validators.required]],
        email: [
          this.isNotFullSsoAccount || !this.loginService.hasContact ? this.CurrentContact?.email : null,
          Validators.compose([Validators.required, CustomValidators.mailValidator()]),
          this.isLogged  ? null : [this.loginService.getLoginInfo()]],
        password: [this.contactForm?.get('password')?.value || null, Validators.compose([Validators.required,
          Validators.minLength(8),
          Validators.maxLength(64),
          CustomValidators.patternValidator(/^(?=.*[a-z])(?=.*[A-Z])/g, {hasMinAndMaj: true}),
          CustomValidators.patternValidator(/^(?=.*[0-9])/g, {hasNumber: true}),
          CustomValidators.patternValidator(/^(?=.*[!@#\-=_+€£\$§&\^~°;:,\.\?\\\/<>\*%¨\*`'"\{\}µ²])/g, {hasSpecialCharacter: true})
        ])],
        passwordConfirm: [{value: this.contactForm?.get('passwordConfirm')?.value || null, disabled: true}, [Validators.required]]
      }, {validator: [
        CustomValidators.passwordMatchValidator,
        CustomValidators.passwordEmailMismatch
      ]});

      //this.updateFormWithUser(user);
      //this.updatePhoneField(this.CurrentContact?.phone, 'phone1');
      //this.updatePhoneField(this.CurrentContact?.phone2, 'phone2');
    }));

    this.forceUpperCase('lastname', true);
    this.forceUpperCase('firstname', false);

    this.subscriptionsInput.push(this.contactForm.get('email').statusChanges.subscribe(() => {
      this.isNotFullSsoAccount = this.checkFullSSOAccount();
      if (
        (this.contactForm.get('email').hasError('parcoursExists') || this.contactForm.get('email').hasError('emailExists'))
        && !this.contactForm.get('email').touched
      ) {
          this.contactForm.get('email').markAsTouched();
      }
    }));
    
    if (this.contactForm.get('password').valid)
      this.contactForm.get('passwordConfirm').enable();
    this.subscriptionsInput.push(this.contactForm.get('password').statusChanges.subscribe(() => {
      if (this.contactForm.get('password').valid) {
        this.contactForm.get('passwordConfirm').enable();
      } else {
        this.contactForm.get('passwordConfirm').disable();
      }
      this.contactForm.get('passwordConfirm').updateValueAndValidity();
    }))

    this.removeUnusedValidators();
    this.smartPushService.smartPushUpdate(['adhesion-page-1-4543']);

  }

  forceUpperCase(formControlName: string, forceAll: boolean) {
    this.subscriptionsInput.push(this.contactForm.get(formControlName).statusChanges.subscribe(() => {
        if (!this.contactForm.get(formControlName).value) {
          return;
        }
        if(forceAll) {
            if(this.contactForm.get(formControlName).value === this.contactForm.get(formControlName).value.toUpperCase())  {
                return;
               }
            this.contactForm.get(formControlName).setValue(this.contactForm.get(formControlName).value.toUpperCase());
            return;
        }
        if(this.contactForm.get(formControlName).value[0] === this.contactForm.get(formControlName).value[0].toUpperCase())  {
            return;
           }
        this.contactForm.get(formControlName).setValue(this.contactForm.get(formControlName).value.charAt(0).toUpperCase() + this.contactForm.get(formControlName).value.slice(1));
         
      }));
}

  updatePhoneField(value: string, field: string) {
    if (!value) {
      return;
    }
   // this[field + 'Field'].writeValue(value);
    //this[field + 'Field'].onPhoneNumberChange();
    this.contactForm.get(field).setValue(value);
    this.contactForm.get(field).updateValueAndValidity();
  }

  removeUnusedValidators(): void {
    if (this.isLogged ) {
      const formControlEmail: FormControl = this.contactForm.get('email') as FormControl;
      const formControlPassword: FormControl = this.contactForm.get('password') as FormControl;
      const formControlpasswordConfirm: FormControl = this.contactForm.get('passwordConfirm') as FormControl;
      formControlEmail.disable();
      formControlPassword.clearValidators();
      formControlPassword.setValidators(null);
      formControlPassword.updateValueAndValidity();
      formControlpasswordConfirm.clearValidators();
      formControlpasswordConfirm.setValidators(null);
      formControlpasswordConfirm.updateValueAndValidity();
    }
  }

  getGender(): void {
    if (this.CurrentContact?.genderId && ['Madame', 'Monsieur'].includes(this.CurrentContact.genderId)) {
      this.genderId = this.genderIdValues[this.CurrentContact.genderId];
    } else if (
        this.CurrentContact?.genderId
        && [this.genderIdValues.Monsieur, this.genderIdValues.Madame].includes(this.CurrentContact.genderId)
      ) {
        this.genderId = this.CurrentContact.genderId;
    } else {
      this.genderId = null;
    }
  }

  updateFormWithUser(user: User | Contact) {
    for (const key in user) {
      if (user.hasOwnProperty(key)) {
        const field = this.contactForm.get(key);
        if (field) {
          field.setValue(user[key]);
          field.markAsDirty();
          field.updateValueAndValidity();
          if (key === 'password') {
            const otherField = this.contactForm.get('passwordConfirm');
            if (otherField) {
              otherField.setValue(user[key]);
              otherField.markAsDirty();
              otherField.updateValueAndValidity();
            }
          }
        }
      }
    }
    if (user && user.email) {
      this.emailOnLoad = user.email;
    }
    let phone = user instanceof User ?  user.phone1 : user.phone;
    this.updatePhoneField(phone, 'phone1');
  }

  getValidity(field: string, error: string): boolean {
    const fieldState: AbstractControl = this.contactForm.get(field);
    if (!fieldState.dirty && !fieldState.touched || fieldState.hasError('required')) {
      return false;
    }
    if (fieldState.hasError(error)) {
      return false;
    }
    return true;
  }

  displayError(field: string, error: string): boolean {
    return CustomValidators.displayError(this.contactForm, field, error);
  }

  saveOrConfirmMail() {
    if (!this.contactForm.valid) {
      return
    }
    if ((this.adhesionService.id && this.emailOnLoad === this.contactForm.get('email').value)
     || (this.loginService && this.loginService.state && this.loginService.state.isEmailsExist)) {
      this.saveAndProceed();

    } else {
      this.tagService.sendTagWithLabel('Pre-validation', 'Je crée mon profil');
      const dialogConfig = new MatDialogConfig();
      dialogConfig.maxWidth = "850px"
      this.dialog.open(this.confirmDialog, dialogConfig);
    }
  }

  login() {
    if (this.loginService.state?.idProspect) {
    this.tagService.sendTagWithLabel('Validation', 'Je poursuis mon adhésion');
    }
    this.loginService.login(false);
  }

  onLeavePasswordConfirm() {
    if (!this.contactForm.valid) {
      const controls = this.contactForm.controls;
      for (const field in controls) {
        if (field !== 'email' && controls.hasOwnProperty(field)) {
          this.contactForm.get(field).markAsTouched();
          this.contactForm.get(field).updateValueAndValidity();
        }
      }
      return;
    }
  }

  async saveAndProceed(tag?: boolean) {
    this.disableButton = true;
    if (!this.contactForm.valid) {
      const controls = this.contactForm.controls;
      for (const field in controls) {
        if (controls.hasOwnProperty(field)) {
          this.contactForm.get(field).markAsDirty();
          this.contactForm.get(field).updateValueAndValidity();
        }
      }
      return;
    }
    if (tag) {
      this.tagService.sendTagWithLabel('Validation', 'Je crée mon profil > Je confirme');
    }
    this.adhesionService.user = new User(this.contactForm.value);
    if (this.isLogged) {
      this.adhesionService.user.email = this.loginService.state.newMembershipEmail;
      this.adhesionService.user.contactId = this.CurrentContact?.contactId;
      this.adhesionService.user.loginId = this.CurrentContact?.loginId;
      this.loginService.state.isActiveMembership = true;
    }
    if (!this.isLogged)
    {
        this.loader.registerWait('implicitLogin');
    }
    if(!this.isNotFullSsoAccount){
      this.adhesionService.saveAndProceed(1).subscribe(async (nok) => {
        if (!nok || typeof(nok) !== 'boolean') {
          const email = this.adhesionService.prospect.contact.email;
          this.loginService.state = new StateMembership(email, this.adhesionService.id);
          this.loginService.state.isNotFullSsoAccount = this.isNotFullSsoAccount;
          this.loginService.refreshStateMembership();
          if (!this.isLogged) {
            await this.implicitLogin(this.adhesionService.user?.email, this.adhesionService.tmpPassword);
          } else{
            this.loader.endWaiting('implicitLogin');
            this.router.navigate([`mon-entreprise/${this.adhesionService.id}`]);
          }
        }
      });
    }else if (this.adhesionService.user)
    {
      window.sessionStorage.setItem('contact', JSON.stringify(this.adhesionService.user));
      await this.implicitLogin(this.adhesionService.user.email, this.adhesionService.user.password);
    }
    

    return;
  }

 async implicitLogin(email: string, password: string){
      this.loginService.state.isImplicitLogin = true;
      this.loginService.refreshStateMembership();
      const iframe = document.getElementsByTagName('iframe').namedItem('iframeconnect1').contentWindow;
      iframe.postMessage({ email, password }, environment.ssoUrl);
      // if (environment.env === 'local') {
      //   setTimeout(() => {
      //     this.goNextStep();
      //   }, 1000);
      // }
  }


  ngOnDestroy() {
    for (const subscription of this.subscriptionsInput) {
      subscription.unsubscribe();
    }
  }

  private getContact() {
      this.loader.registerWait('getContact');
      this.adhesionService.getContact(this.loginService.state?.newMembershipEmail)
        .pipe(
          catchError(err => of(null)),
          tap(() => this.loader.endWaiting('getContact'))
        ).subscribe( data  => {
          if (data) {
            window.sessionStorage.setItem('contact', JSON.stringify(data));
            this.CurrentContact = data;
            this.adhesionService.contact = data;
            this.updateFormWithUser(data);
          }
      });

  }

  private goNextStep() {
    this.loginService.state.isActiveMembership = true;
    this.loader.endWaiting('implicitLogin');
    this.loginService.redirectConnect1(false);
    // this.router.navigate([`mon-entreprise/${this.adhesionService.id}`]);
  }

  correctEmail() {
    this.tagService.sendTagWithLabel('Pre-validation', 'Je crée mon profil > Je corrige');
    const formControlEmail: FormControl = this.contactForm.get('email') as FormControl;
    formControlEmail?.enable();
  }
  checkFullSSOAccount(): boolean
  {
    if (this.loginService.state)
    {
      return this.loginService.state.isNotFullSsoAccount;
    }
  }

}
