<ng-container [ngSwitch]="notConfirmed">
  <ng-container *ngSwitchCase="true">
    <form class="modalForm" [formGroup]="rappelForm" #formRappel="ngForm" (ngSubmit)="sendAskForCall()">
        <h3 mat-dialog-title>Besoin d'aide ?<mat-icon>support_agent</mat-icon></h3>
        
      <div mat-dialog-content>
          <p class="gs1-little">Le service client de GS1 France peut vous rappeler dans un délai de 24 heures, entre 8h30 et 17h30 du lundi au vendredi.</p>
          <gs1-select-wrapper>
            <label for="motif">Veuillez précisez ci-dessous le motif du rappel</label>
            <ng-select formControlName="motif" name="motif" [items]="motifs" [bindLabel]="'label'" [bindValue]="'value'"
              [searchable]="false" [multiple]="false" [closeOnSelect]="true">
            </ng-select>
            <span slot="error" *ngIf="displayError('motif', 'required')">Un motif est requis</span>
          </gs1-select-wrapper>

          <gs1-input [type]="'textarea'" formControlName="comment" [name]="'comment'" [placeholder]="'Merci d\'ajouter un commentaire (100 caractères maximum)'">
            <span slot="error" *ngIf="displayError('comment', 'required')">Un commentaire est requis</span>
            <span slot="error" *ngIf="displayError('comment', 'minlength')">Un commentaire doit comprendre au moins 2 caractères</span>
            <span slot="error" *ngIf="displayError('comment', 'maxlength')">Le commentaire ne peut dépasser 100 caractères</span>
          </gs1-input>
          
          <gs1-radios [block]="true">
            <gs1-radio formControlName="genderId" [name]="'monsieur-contact'" [value]="genderIdValues.Monsieur">Monsieur</gs1-radio>
            <gs1-radio formControlName="genderId" [name]="'madame-contact'" [value]="genderIdValues.Madame">Madame</gs1-radio>
            <span slot="error" *ngIf="displayError('genderId', 'required')">Le genre est requis</span>
          </gs1-radios>
 
          <div *ngIf="!modifyContact" class="contacts">
            <span>{{rappelForm.get("lastname").value}} {{rappelForm.get("firstname").value}}</span>
            <span>{{rappelForm.get("phone").value}}</span>
            <button type="button" gs1Button [appearance]="'clear'" [color]="'link'" [thin]="true" (click)="modifyContact = true">Modifier</button>
          </div>

          <div *ngIf="modifyContact">
            <div class="columns one-to-one">
              <gs1-input [label]="'Nom'" formControlName="lastname" [name]="'lastname-contact'">
                <span slot="error" *ngIf="displayError('lastname', 'required')">Un nom est requis</span>
                <span slot="error" *ngIf="displayError('lastname', 'minlength')">Un nom doit comprendre au moins 2 caractères</span>
                <span slot="error" *ngIf="displayError('lastname', 'pattern')">Ce nom n'est pas valide</span>
              </gs1-input>
              <gs1-input [label]="'Prénom'" formControlName="firstname" [name]="'firstname-contact'">
                <span slot="error" *ngIf="displayError('firstname', 'required')">Un prénom est requis</span>
                <span slot="error" *ngIf="displayError('firstname', 'minlength')">Un prénom doit comprendre au moins 2 caractères</span>
                <span slot="error" *ngIf="displayError('firstname', 'pattern')">Ce prénom n'est pas valide</span>
              </gs1-input>

              <gs1-phone [label]="'Téléphone'" formControlName="phone" [name]="'phone-contact'">
                <span slot="error" *ngIf="displayError('phone', 'required')">Le téléphone principal est requis</span>
                <span slot="error" *ngIf="displayError('phone', 'validatePhoneNumber')">Ce téléphone n'est pas valide</span>
              </gs1-phone>
            </div>
          </div>
      </div>
      <div mat-dialog-actions>
        <button gs1Button [appearance]="'outlined'" mat-dialog-close>Annuler</button>
        <button gs1Button type="submit" [disabled]="!rappelForm.valid">Rappelez-moi</button>
      </div>
    </form>
  </ng-container>
  <ng-container *ngSwitchCase="false">
    <h3 mat-dialog-title>Besoin d'aide ?<mat-icon>support_agent</mat-icon></h3>
    <div mat-dialog-content>
      <span class="gs1-little">
        Votre demande de rappel a bien été transmise au service de GS1 France.<br/>
        Vous pouvez à tout moment poursuivre votre adhésion en saisissant votre email à l'étape 1 du parcours.
      </span>
    </div>
    <div mat-dialog-actions>
      <button gs1Button mat-dialog-close>Fermer</button>
    </div>
  </ng-container>
</ng-container>
