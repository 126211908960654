import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Contact } from 'src/app/models/contact/contact';
import { AdhesionService } from 'src/app/services/adhesion/adhesion.service';
import { FormBuilder, FormGroup, Validators, AbstractControl, FormControl } from '@angular/forms';
import { CustomValidators } from 'src/app/validators/custom-validators/custom-validators';
import {ValidatorService} from 'angular-iban';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription, Observable } from 'rxjs';
import { Paiement } from 'src/app/models/paiement/paiement';
import { environment } from 'src/environments/environment';
import { TarifData } from 'src/app/models/tarif-data/tarif-data';
import { ScrollToService } from '@nicky-lenaers/ngx-scroll-to';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { BillContactDialogComponent } from '../dialogs/bill-contact-dialog/bill-contact-dialog/bill-contact-dialog.component';
import { COUNTRIES } from 'src/config/countries';
import { TagService } from 'src/app/services/tagService/tag-service.service';
import { SharedSettingService } from 'src/app/services/sharedsetting/sharedsetting.service';
import { SmartPushService } from 'src/app/services/smartPush/smartPush.service';
import { Company } from 'src/app/models/company/company';

@Component({
  selector: 'app-paiement-infos',
  templateUrl: './paiement-infos.component.html',
  styleUrls: ['./paiement-infos.component.scss']
})
export class PaiementInfosComponent implements OnInit, OnDestroy {

  companyContact: Contact = null;
  email: any = null;
  phone: any = null;
  editEmailShowing = false;
  editPhoneShowing = false;
  mailCodeShowing = false;
  smsCodeShowing = true;
  showCBPayment = false;
  forceCBPayment = false;
  isDisabled = false;
  showErrorCB = false;
  tempBillContact: any = null;
  tarif: Observable<TarifData> = null;
  paiement: Paiement = null;
  paiementForm: FormGroup;
  cga: FormControl;
  subscriptions: Subscription[] = [];
  billContactDialog: MatDialogRef<BillContactDialogComponent>;
  isbillAlreadyExist = false;

  errorOnMandat = false;
  errorOnSignature = false;
  errorOnSEPAPaiement = false;
  isNeedHelpEnabled=false;
  constructor(
    private titleService: Title,
    public adhesionService: AdhesionService,
    private router: Router,
    private fb: FormBuilder,
    public scroller: ScrollToService,
    private dialogService: MatDialog,
    public tagService: TagService,
    private sharedsettingService:SharedSettingService,
    private smartPushService: SmartPushService

  ) {
    this.titleService.setTitle('Payer mon adhésion - GS1 France');
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub: Subscription) => {
      sub.unsubscribe();
    });
  }

  async ngOnInit(): Promise<void> {
    const [iscrisp]=await Promise.all([this.sharedsettingService.isCripsEnabled()]);

    if(iscrisp)
    this.sharedsettingService.InjectScript();
    const [isNeedhelp]=await Promise.all([this.sharedsettingService.isNeedhelpEnabled()]);
    this.isNeedHelpEnabled =isNeedhelp;
    
    this.tarif = this.adhesionService.tarifUpdated;
    this.paiementForm = this.fb.group({
      phone: [null, Validators.compose([Validators.required, CustomValidators.frenchMobileNumberValidator()])],
      email: [null, Validators.compose([Validators.required, Validators.email, CustomValidators.mailValidator()])],
      iban: [null, Validators.compose([Validators.required, ValidatorService.validateIban, CustomValidators.ibanValidator()]) ],
    });
    this.cga = this.fb.control(false, [Validators.required]);
    this.adhesionService.getCompanyContact();
    this.subscriptions.push(this.adhesionService.paiementUpdated.subscribe(
      paiement => {
        if (paiement) {
          this.paiement = paiement;
          if (!this.paiement.email) {
            this.paiement.email = this.adhesionService.user.email;
          }
          if (!this.paiement.phone) {
            this.paiement.phone = this.adhesionService.user.phone1;
          }
          this.paiementForm.get('iban').setValue(paiement.iban);
          if (paiement.iban) {
            this.paiementForm.get('iban').markAsTouched();
          }
          this.updatePhoneAndMail(paiement.email, paiement.phone);
          if (paiement.mandateStatus && paiement.mandateStatus === 'KO') {
            this.paiementForm.get('iban').setErrors({refused: true});
          } else if (paiement.mandateStatus && paiement.mandateStatus === 'OK') {
            this.paiementForm.get('iban').disable();
            this.isDisabled = true;
            history.pushState(null, null, location.href);
            window.onpopstate = () => {
                history.go(1);
            };
          }
          if (this.adhesionService.isRejectedSepa || this.adhesionService.hasAlreadyTriedCB) {
            if (this.adhesionService.hasAlreadyTriedCB) {
              this.showErrorCB = true;
              this.cga.setValue(true);
              this.tagService.sendTagWithLabel('Failure', 'Transaction failed - SEPA & CB');
            } else {
              if (paiement.mandateStatus && paiement.mandateStatus === 'OK') {
                this.errorOnSEPAPaiement = true;
                this.cga.setValue(true);
              } else {
                this.errorOnSignature = true;
              }
            }
            this.showCBPayment = true;
            setTimeout(() => {
              this.scroller.scrollTo({
                target: 'refused'
              });
            }, 100);

          }
        }
      }
    ));
    this.subscriptions.push(this.adhesionService.contactUpdated.subscribe(contact => {
      this.companyContact = contact;
      if (!this.paiement) {
        this.paiement = {
          email: this.companyContact.email,
          phone: this.companyContact.phone
        };
      }
      this.updatePhoneAndMail(contact.email, contact.phone);
    }
    ));
    this.smartPushService.smartPushUpdate(['adhesion-page-4-4546']);
  }

  updatePhoneAndMail(email: string, phone: string) {
    if (email) {
      this.paiementForm.get('email').setValue(email);
    }
    if (phone) {
      this.paiementForm.get('phone').setValue(phone);
      this.checkPhoneForDisplay(phone);
    }
  }

  checkPhoneForDisplay(phone: string) {
    if (!phone ||
      phone.substring(0, 3) !== '+33' ||
      (phone.charAt(3) !== '6' && phone.charAt(3) !== '7')
    ) {
      this.mailCodeShowing = true;
      this.smsCodeShowing = false;
      this.paiementForm.get('phone').setValidators(null);
    }
  }

  createAndSignMandate() {
    if (this.paiementForm.valid) {
      this.adhesionService.paiement = this.paiementForm.value;
      if (this.editEmailShowing && this.adhesionService.paiement.email !== this.adhesionService.contact.email) {
        this.tagService.sendTagWithLabel('Click', 'SEPA - Validation changement email');
      } else if (this.editPhoneShowing && this.adhesionService.paiement.phone !== this.adhesionService.contact.phone) {
        this.tagService.sendTagWithLabel('Click', 'SEPA - Validation changement num tél');
      }
      this.tagService.sendTagWithLabel('Click', 'Continuer - SEPA');
      this.adhesionService.createAndSignMandate().subscribe(
        signUrl => {
          if (signUrl.isOk) {
            if (signUrl.content.startsWith('http')) {
              window.location.href = signUrl.content;
            }
          } else {
            this.setSignatureError();
          }
        },
        () => {
          this.setSignatureError();
        }
      );
    }
  }

  setSignatureError() {
    this.errorOnMandat = true;
    this.showCBPayment = true;
    this.paiementForm.get('iban').setErrors({refused: true});
  }

  displayError(field: string, error: string): boolean {
    return CustomValidators.displayError(this.paiementForm, field, error);
  }

  showEditEmail(){
    if (this.paiement.mandateStatus !== 'OK') {
      this.editEmailShowing = true;
    }
  }

  showEditPhone(){
    if (this.paiement.mandateStatus !== 'OK') {
      this.editPhoneShowing = true;
    }
  }

  getUrlCGA(): string {
    return `${environment.baseUrl}/api/documents/cga`;
  }

  getUrlMandat(): string {
    return `${environment.baseUrl}/api/prospects/${this.adhesionService.id}/mandate`;
  }

  getCountryName(code: string): string {
    return COUNTRIES.find(country => country.alpha2Code === code).name;
  }

  saveAndProceed() {
    if (this.cga.value === true && this.paiement && (this.paiement.mandateStatus === 'OK' || this.forceCBPayment || this.showCBPayment || this.showErrorCB)) {
      this.paiement.isForcedCB = this.paiement.mandateStatus !== 'OK';
      this.adhesionService.paiement = this.paiement;
      this.adhesionService.paiement.acceptedCGA = this.cga.value;
      this.tagService.sendTagWithLabel('Validation', this.showCBPayment ? 'Payer par CB' : 'Paiement SEPA');
      this.adhesionService.saveAndProceed(4).subscribe((data) => {
        if (this.adhesionService.moneticoHtml && this.adhesionService.moneticoHtml.length > 1) {
          const parser = new DOMParser();
          const html = parser.parseFromString(this.adhesionService.moneticoHtml, 'text/html');
          document.body.append(html.getElementsByName('form')[0]);
          const form = window.document.getElementsByName('form')[0] as HTMLFormElement;
          form.submit();
        } else if (this.adhesionService.isRejectedSepa === true) {
          this.errorOnSEPAPaiement = true;
          this.showCBPayment = true;
          this.tagService.sendTagWithLabel('Failure', 'Failed SEPA but CB possible');
        } else if (data !== false) {
          //this.adhesionService.isDone = true;
          this.subscriptions.push(this.adhesionService.getCompany().subscribe(
            (data: Company) => {
               if(data) {
                 if(data.sector === 'VIN & SPIRITUEUX') {
                    this.router.navigate([`confirmation-adhesion-vins-et-spiritueux/${this.adhesionService.id}`]);
                 } else {
                    this.router.navigate([`confirmation-adhesion/${this.adhesionService.id}`]);
                  }
               }
              }));
        }
      });
    }
  }

  showBillContactForm() {
    const dataForDiralog = this.adhesionService.billContact && this.adhesionService.billContact !== undefined ?
     this.adhesionService.billContact : {companyName: this.companyContact.companyName};
    if (this.tempBillContact || this.adhesionService.billContact && this.adhesionService.billContact !== undefined) {
      this.tagService.sendTagWithLabel('Click', 'Modifier le contact');
    } else {
      this.tagService.sendTagWithLabel('Click', 'Ajouter un contact de facturation');
    }
    this.billContactDialog = this.dialogService.open(BillContactDialogComponent, {
      data: dataForDiralog,
      maxWidth: '100vw',
      maxHeight: '100vh',
      height: 'auto',
      width: '50%'
      
    });
    this.subscriptions.push(this.billContactDialog.afterClosed().subscribe((result: any) => {
      if (result.country && result.country.name) {
        result.country = result.country.alpha2Code;
      }
      // rall back contact facturation
    /*  if (this.billContactDialog && this.billContactDialog.componentInstance
      && this.billContactDialog.componentInstance.showExistContact){
      this.isbillAlreadyExist = true;

    }
    else
    {
    this.isbillAlreadyExist = false;
    }*/
      this.tempBillContact = result;
    }));
  }

  canPreferCB() {
    if (!this.forceCBPayment && !this.showCBPayment && !this.showErrorCB &&
       (!this.adhesionService.canSepaBeSkipped || !this.adhesionService.isAllowedCb) ||
       this.adhesionService.hasAlreadyTriedCB || (
       this.adhesionService.paiement && this.adhesionService.paiement.mandateStatus === 'OK')
       ) {
      return false;
    }
    return true;
  }

  hasPaiementErrors() {
    return this.errorOnMandat || this.errorOnSEPAPaiement || this.errorOnSignature || this.showErrorCB;
  }
}

