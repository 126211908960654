<div >
  <div [ngClass]="{'completed': step > 1, 'current': step === 1}">
    <span  class="gs1-big">1
    </span>
    <span class="stepName lg">
       <i *ngIf="step > 1" class="bi bi-check-circle-fill gs1-color-ui-3"></i>
       <i *ngIf="step < 1" class="bi bi bi-circle gs1-color-ui-3"></i>

      <i *ngIf="step === 1" class="bi bi-check-circle-fill gs1-color-orange"></i>



      Création de profil
    </span>
      <span class="stepName sm ">Profil</span> 
  </div>
  <div [ngClass]="{'completed': step > 2, 'current': step === 2}">
    <span  class="gs1-big">2
    </span>
    <span class="stepName lg">
       <i *ngIf="step > 2" class="bi bi-check-circle-fill gs1-color-ui-3"></i>
       <i *ngIf="step < 2" class="bi bi bi-circle gs1-color-ui-3"></i>

    <i *ngIf="step === 2" class="bi bi-check-circle-fill gs1-color-orange"></i>
  
  Votre entreprise</span>
  <!-- <span class="stepName sm">Entreprise</span> -->

</div>
  <div [ngClass]="{'completed': step > 3, 'current': step === 3}">
    
    <span  class="gs1-big">3
    </span>
    <span class="stepName lg">
       <i *ngIf="step > 3" class="bi bi-check-circle-fill gs1-color-ui-3"></i>
       <i *ngIf="step < 3" class="bi bi bi-circle gs1-color-ui-3"></i>

      <i *ngIf="step === 3" class="bi bi-check-circle-fill gs1-color-orange"></i>
    
  Votre besoin
</span>
    <span class="stepName sm">Besoin</span>
  
  </div>
  <div [ngClass]="{'completed': step > 4, 'current': step === 4}">
    <span  class="gs1-big">4
    </span>
    <span class="stepName lg">
       <i *ngIf="step > 4" class="bi bi-check-circle-fill gs1-color-ui-3"></i>
       <i *ngIf="step < 4" class="bi bi bi-circle gs1-color-ui-3"></i>

      <i *ngIf="step === 4" class="bi bi-check-circle-fill gs1-color-orange"></i>
      Paiement
    </span>  
      <span class="stepName sm">Paiement</span>
    </div>
</div>
