import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { Observable, of, Subject } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Setting } from 'src/app/models/MembershipSetting/setting';

import { environment } from 'src/environments/environment';
import { AdhesionService } from '../adhesion/adhesion.service';
import { ErrorService } from '../error/error.service';
import { LoaderService } from '../loader/loader.service';
import { SettingService } from '../setting/setting.service';

@Injectable({
  providedIn: 'root'
})
export class SharedSettingService {
  

  constructor(private settingService: SettingService) {}


   async isCripsEnabled(): Promise<boolean> {
     const settings = await this.settingService.getSettings();

  
      if (settings) {
          
          let isEnableCrisp = settings.find(
            x=> x.description.toLocaleLowerCase() == 'enablecrisp'

          ).defaultValue.toLocaleLowerCase() ==='true';
          
        return isEnableCrisp;
      }
   return false;
 
        
}



async isNeedhelpEnabled(): Promise<boolean> {
  const settings = await this.settingService.getSettings();


   if (settings) {
       
       let isEnableCrisp = settings.find(
         x=> x.description.toLocaleLowerCase() == 'enablepopinneedhelp'

       ).defaultValue.toLocaleLowerCase() ==='true';
       
     return isEnableCrisp;
   }
return false;

     
}
InjectScript() {
  const env=environment.env;
  if(this.isCripsEnabled())
  {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.innerHTML = 'window.$crisp=[];window.CRISP_WEBSITE_ID="bb948a60-045c-467a-9884-ff77159322af";(function(){d=document;s=d.createElement("script");s.src="https://client.crisp.chat/l.js";s.async=1;d.getElementsByTagName("head")[0].appendChild(s);})();';
    script.innerHTML +=`$crisp.push(["set", "session:segments", [["Parcours d'adhésion-${env}"]]])`;
    const head = document.getElementsByTagName('head')[0];
    if (head !== null && head !== undefined) {
      document.head.appendChild(script);
    }

  }


}
}