<div class="main-div">
    <h3>Félicitations, vous venez de rejoindre la communauté GS1 France <br>
        comptant plus de 58 000 adhérents !</h3>
    <h4 *ngIf="isGPC678">
        Un mail récapitulatif de votre adhésion vous sera envoyé à <span class="email">{{companyContact?.email}}</span> pour vous confirmer votre adhésion et vous 
        partager les liens utiles pour accéder à nos services. 
    </h4>
    <h4 *ngIf="!isGPC678">
        Un mail récapitulatif de votre adhésion vous sera envoyé dans un délai de 5 minutes  à <span class="email">{{companyContact?.email}}</span> 
        pour vous confirmer votre adhésion et vous partager les liens utiles pour accéder à nos services.
    </h4>
    <gs1-alert *ngIf="isGPC678" [type]="'info'" class="gs1-spacer-3 alert">
        <p class="title-style gs1-color-blue">Vos codes seront accessibles dans un délai de 48 heures ouvrées après le traitement de vos informations.</p>
        <p class="gs1-little">Vous serez tenu informé par mail dès qu’elles seront validées.</p>
    </gs1-alert>
    <h3>Pour générer un QR Code augmenté GS1, créez d’abord l’identifiant <br>
        unique de votre bouteille : le GTIN.</h3>
    <div class="text-with-image">
        <p class="image-text-1 gs1-color-blue">1. Connectez-vous sur CodeOnline avec vos identifiants GS1 France.</p>
        <p class="image-text-2 gs1-color-blue">2. Démarrez simplement en cliquant sur « Nouveau » puis « Créer un GTIN »,
            et remplissez toutes les informations relatives à votre bouteille.</p>
        <p class="image-text-3"><i class="bi-check-circle-fill"></i>Une fois le GTIN créé, cliquez sur « Créer le QR Code », puis téléchargez-le 
            afin de l’apposer sur votre bouteille.</p>
    </div>
    <gs1-link-card>
        <h3>Besoin d’aide ?</h3>
        <h4>
            Le service client GS1 France reste à votre disposition pour vous accompagner après votre adhésion, ou bien 
            pour la création de votre premier GTIN sur CodeOnline. <br><br>

            Contactez-nous <a class="underlined" href="mailto:serviceclient@gs1fr.org">par mail</a> ou par téléphone au 01 40 22 18 00.
        </h4>
    </gs1-link-card>
</div>