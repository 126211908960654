import { AfterContentInit, ContentChildren, Directive, HostListener, QueryList, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appNoCopyPaste]'
})
export class NoCopyPasteDirective implements AfterContentInit {

  @ContentChildren('input') inputs: QueryList<HTMLInputElement>;

  constructor(private _renderer: Renderer2) { }

  ngAfterContentInit(): void {
    this.inputs.forEach((item) => {
      this._renderer.listen(item, 'paste', ($event) => $event.preventDefault());
      this._renderer.listen(item, 'copy', ($event) => $event.preventDefault());
      this._renderer.listen(item, 'cut', ($event) => $event.preventDefault());
    });
  }

  @HostListener('paste', ['$event'])
  blockPaste(e: ClipboardEvent) {
    e.preventDefault();
  }

  @HostListener('copy', ['$event'])
  blockCopy(e: KeyboardEvent) {
    e.preventDefault();
  }

  @HostListener('cut', ['$event'])
  blockCut(e: KeyboardEvent) {
    e.preventDefault();
  }
}
