import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import { GoogleTagManagerService } from 'angular-google-tag-manager';

import { AppRoutingModule } from './app-routing.module';
import { HelpDirective } from './directives/help.directive';
import { NoCopyPasteDirective } from './directives/no-copy-paste.directive';
import { AppComponent } from './app.component';
import { HeaderComponent } from './components/header/header.component';
import { ContactInfosComponent } from './components/contact-infos/contact-infos.component';
import { StepperComponent } from './components/stepper/stepper.component';
import { LoaderComponent } from './components/loader/loader.component';
import { HelpDialogComponent } from './components/dialogs/help-dialog/help-dialog.component';
import { CompanyInfosComponent } from './components/company-infos/company-infos.component';
import { NeedInfosComponent } from './components/need-infos/need-infos.component';
import { PaiementInfosComponent } from './components/paiement-infos/paiement-infos.component';
import { ValidationPageComponent } from './components/validation-page/validation-page.component';
import { SupportStickerComponent } from './components/support-sticker/support-sticker.component';
import { ServiceClientDialogComponent } from './components/dialogs/service-client-dialog/service-client-dialog.component';
import { PhonePipe } from './pipes/phone/phone.pipe';
import { ErrorComponent } from './components/dialogs/error/error/error.component';
import { BillContactDialogComponent } from './components/dialogs/bill-contact-dialog/bill-contact-dialog/bill-contact-dialog.component';
import { MaterialModule } from './material/material.module';
import { OAuthModule, OAuthService, OAuthStorage } from 'angular-oauth2-oidc';
import { TokenInterceptor } from './commun/tokeninterceptor';
import { InitService } from './services/init/init.service';
import { Gs1ComponentsLibraryModule } from '@gs1france/gs1-components-library';
import { NgSelectModule } from '@ng-select/ng-select';
import { ValidationPageWineAndSpiritsComponent } from './components/validation-page-wine-and-spirits/validation-page-wine-and-spirits.component';
import { NgxMatIntlTelInputComponent } from 'ngx-mat-intl-tel-input';
import { AngularIbanModule } from 'angular-iban';
import { ExpiredTokenPopupComponent } from './components/expired-token-popup/expired-token-popup.component';
 
registerLocaleData(localeFr, 'fr-FR');

export function initAuth(initService: InitService): () => Promise<void> {
  return initService.initAuth();
}

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    ContactInfosComponent,
    StepperComponent,
    LoaderComponent,
    HelpDirective,
    HelpDialogComponent,
    CompanyInfosComponent,
    NeedInfosComponent,
    PaiementInfosComponent,
    ValidationPageComponent,
    SupportStickerComponent,
    NoCopyPasteDirective,
    ServiceClientDialogComponent,
    PhonePipe,
    ErrorComponent,
    BillContactDialogComponent,
    ValidationPageWineAndSpiritsComponent,
    ExpiredTokenPopupComponent
    ],
  imports: [
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    ReactiveFormsModule,
    FormsModule,
    NgxMatIntlTelInputComponent,
    HttpClientModule,
    AngularIbanModule,
    ScrollToModule.forRoot(),
    MaterialModule,
    OAuthModule.forRoot(),
    //BackButtonDisableModule.forRoot(),
    Gs1ComponentsLibraryModule, NgSelectModule
  ],
  providers: [
    InitService,
    { provide: OAuthStorage, useFactory: getLocalStorage },
    {provide: APP_INITIALIZER, useFactory: (initService: InitService) => initService.initAuth(), deps: [InitService], multi: true},
    {provide: 'googleTagManagerId', useValue: 'GTM-MRWFXTG'},
    {provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true},
    { provide: 'LOCALSTORAGE', useFactory: getLocalStorage },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}

export function getLocalStorage() {
  return (typeof window !== "undefined") ? window.localStorage : null;
}
