import { Injectable } from '@angular/core';
import { GS1LoadingService } from '@gs1france/gs1-components-library';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {

  loadingChanged: Subject<boolean> = new Subject<boolean>();
  messageChanged: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  private loading = false;
  private waitingList = [];
  private messageList = [];

  constructor(private _loading: GS1LoadingService) {
  }

  registerWait(name: string, message?: string) {
    this._loading.startLoading(name, message)
    this.waitingList.push(name);
    if (message) {
      this.messageList.push({name, message});
      this.messageChanged.next(this.messageList[0].message);
    }
    if (!this.loading && this.waitingList.length > 0) {
      this.loading = true;
      this.loadingChanged.next(this.loading);
    }
  }

  endWaiting(name: string) {
    if (this.waitingList.includes(name)) {
      this._loading.stopLoading(name);
      this.waitingList.splice(this.waitingList.indexOf(name), 1);
      this.messageList = this.messageList.filter((pair) => pair.name !== name);
      if (this.waitingList.length === 0 && this.loading) {
        this.messageList = [];
        this.loading = false;
        this.loadingChanged.next(this.loading);
        this.messageChanged.next(null);
      } else if (this.messageList.length > 0) {
        this.messageChanged.next(this.messageList[0].message);
      } else {
        this.messageChanged.next(null);
      }
    }
  }

  getState(): boolean {
    return this.loading;
  }

  getWaitingList(): string[] {
    return this.waitingList;
  }

}
