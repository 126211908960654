import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, of, Subject, BehaviorSubject } from 'rxjs';
import {tap, catchError, finalize} from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { LoaderService } from '../loader/loader.service';
import { ListSelect, Options } from 'src/app/models/list-select/list-select';
import { ErrorService } from '../error/error.service';
@Injectable({
  providedIn: 'root'
})
export class StaticInfoService {
  caTranches: ListSelect[];
  caTranchesSub: BehaviorSubject<ListSelect[]> = new BehaviorSubject([]);
  jobTitles: ListSelect[];
  jobTitlesSub: BehaviorSubject<ListSelect[]> = new BehaviorSubject([]);
  canaux: ListSelect[];
  canauxSub: BehaviorSubject<ListSelect[]> = new BehaviorSubject([]);
  activities: ListSelect[];
  activitiesSub: BehaviorSubject<ListSelect[]> = new BehaviorSubject([]);
  codesProduits: ListSelect[];
  codesProduitsSub: BehaviorSubject<ListSelect[]> = new BehaviorSubject([]);
  loaded = false;

  constructor(private http: HttpClient, private loader: LoaderService, public errorService: ErrorService) { }

  initValues() {
    if (this.loaded) {
      this.callNexts();
    } else {
      this.loader.registerWait('initStatic');
      this.http.get<any>(`${environment.baseUrl}/api/ListsValue`)
        .pipe(
          catchError(err => {
            this.errorService.handleError(err);
            return of(null);
          }),
          finalize(() => this.loader.endWaiting('initStatic'))
        ).subscribe(
          (data: Options) => {
            if (data) {
              this.activities = data.Activite.sort(this.sortList);
              this.caTranches = data.ContributionBracket;
              this.canaux = data.SalesChannel.sort(this.sortList);
              this.jobTitles = data.Job.sort(this.sortList);
              this.codesProduits = data.Products.sort(this.sortList);
              this.loaded = true;
              this.callNexts();
            }
          }
        );
    }
  }

  callNexts(): void {
    this.jobTitlesSub.next(this.jobTitles);
    this.caTranchesSub.next(this.caTranches);
    this.activitiesSub.next(this.activities);
    this.canauxSub.next(this.canaux);
    this.codesProduitsSub.next(this.codesProduits);
  }

  sortList(a: ListSelect, b: ListSelect): number {
    if (a.order !== 0)
    {
      return a.order < b.order ? -1 : a.order > b.order ? 1 : 0;
    }

    return a.label < b.label ? -1 : a.label > b.label ? 1 : 0;
  }
}
