import { Component, OnDestroy, OnInit } from '@angular/core';
import { GS1LoadingService } from '@gs1france/gs1-components-library';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit, OnDestroy  {

  constructor(private _loading: GS1LoadingService) { }
  ngOnInit(): void {
    this._loading.startLoading('loaderComponent')
  }
  ngOnDestroy(): void {
    this._loading.stopLoading('loaderComponent')
  }

}
