import { AuthConfig } from 'angular-oauth2-oidc';
import { environment } from 'src/environments/environment';

export const authConfig: AuthConfig = {
  issuer: environment.ssoUrl,
  loginUrl: environment.ssoUrl + '/connect/authorize',
  logoutUrl: environment.ssoUrl + '/Account/logout',
  redirectUri: environment.ssoReturnUrl ,
  clientId: 'membershipclient',
  scope: 'accesMembershipClient',
  oidc : true,
  userinfoEndpoint : environment.ssoUrl + '/connect/userinfo',
  tokenEndpoint : environment.ssoUrl + '/connect/token',
  requestAccessToken: true,
  useSilentRefresh: true,
  silentRefreshRedirectUri: window.location.origin + '/assets/silent-refresh.html',
  useIdTokenHintForSilentRefresh: true
};
