export class StateMembership {
  newMembershipEmail: string;
  activeMembershipEmail?: string;
  idProspect: string;
  isImplicitLogin = false;
  isActiveMembership = false;
  isEmailsExist = false;
  isNotFullSsoAccount = false;
  constructor(email: string, idProspect: string, isActive?: boolean) {
    this.newMembershipEmail = email;
    this.idProspect = idProspect;
    this.isActiveMembership = isActive;
  }
}
