import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { AdhesionService } from 'src/app/services/adhesion/adhesion.service';
import { LoginService } from 'src/app/services/login/login.service';

@Injectable({
  providedIn: 'root'
})
export class Connect1Guard implements CanActivate {
  [x: string]: any;
  /**
   *
   */
  constructor(public adhesionService: AdhesionService, public loginService: LoginService, public router: Router) {

  }

  canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      const stateMembership = this.loginService.refreshUserMembership();
      if (stateMembership) {

        if (stateMembership.isImplicitLogin){
          stateMembership.isImplicitLogin = false;
          this.loginService.refreshStateMembership();
        }
        if(stateMembership.isNotFullSsoAccount)
        {
          this.adhesionService.redirectToSetp2(stateMembership.newMembershipEmail);
          return true;
        }
        if (stateMembership.isActiveMembership) {
          this.adhesionService.retrieveProspect(stateMembership.idProspect, null, stateMembership.activeMembershipEmail);
        } else {
          this.loginService.deleteCacheProspect();
          this.loginService.login(true);
          return true;
        }

        if (!stateMembership.idProspect && !this.adhesionService.id && !stateMembership.activeMembershipEmail) {
          this.router.navigate([`mes-informations`]);
        }
      }

      return true;
  }

}
