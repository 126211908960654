import { Component, OnInit, Inject, ElementRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { DialogData } from 'src/app/models/dialog-data/dialog-data';

@Component({
  selector: 'app-help-dialog',
  templateUrl: './help-dialog.component.html',
  styleUrls: ['./help-dialog.component.scss']
})
export class HelpDialogComponent implements OnInit {
  screenWidthEnough = false;
  heightTest = '';

  constructor(private matDialogRef: MatDialogRef<HelpDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: DialogData) { }

  ngOnInit(): void {
    const iOS = ['iPad Simulator','iPhone Simulator','iPod Simulator','iPad','iPhone','iPod'].includes(navigator.platform)
  || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
    //const iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    const config: MatDialogConfig  = new MatDialogConfig();
    const rect = this.data.element.nativeElement.getBoundingClientRect();
    const width = this.data.element.nativeElement.offsetWidth;
    this.screenWidthEnough = (iOS ? screen.width : window.innerWidth) > (rect.left + width) * 2;
    if (this.screenWidthEnough) {
      config.position = { left: `${width + rect.left + 25}px`, bottom: `${window.innerHeight - rect.bottom + 30 }px` };
    } else {
      config.position = { bottom: `${ ((iOS) ? screen.height / window.devicePixelRatio : window.innerHeight) - rect.top + 25 }px`};
    }
    this.heightTest = config.position.bottom;
    this.matDialogRef.updatePosition(config.position);
  }

}
