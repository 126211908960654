import { Component, OnInit, OnDestroy } from '@angular/core';
import {  Subscription } from 'rxjs';
import { startWith, delay } from 'rxjs/operators';
import { StaticInfoService } from './services/static-info/static-info.service';
import { GS1LoadingService } from '@gs1france/gs1-components-library';
import { PopupService } from './services/popup/popup.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'adhesion-app';
  subscriptionLoader: Subscription;
  show = false;
  isTokenPopupOpen = false;
  constructor(
    public loaderService: GS1LoadingService,
    public staticService: StaticInfoService,
    private popupService: PopupService
    
    ) {
 

  }

   ngOnInit(): void {
    this.subscriptionLoader = this.loaderService.isLoading.pipe(
      startWith(false),
      delay(0)
    ).subscribe(loading => {
      this.show = loading;
    });
    this.popupService.popupOpen$.subscribe(isOpen => {
      this.isTokenPopupOpen = isOpen;
    });
    this.staticService.initValues();
    
     
  }  

  ngOnDestroy(): void {
    if (this.subscriptionLoader) {
      this.subscriptionLoader.unsubscribe();
    }
  }
}
