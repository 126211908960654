import { Component, OnInit, ViewChild, Inject, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormGroupDirective } from '@angular/forms';
import { CustomValidators } from 'src/app/validators/custom-validators/custom-validators';
import { AdhesionService } from 'src/app/services/adhesion/adhesion.service';
import { LoaderService } from 'src/app/services/loader/loader.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Contact } from 'src/app/models/contact/contact';
import { ListSelect } from 'src/app/models/list-select/list-select';
import { StaticInfoService } from 'src/app/services/static-info/static-info.service';
import { Observable, Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { COUNTRIES } from 'src/config/countries';
import { TagService } from 'src/app/services/tagService/tag-service.service';

@Component({
  selector: 'app-bill-contact-dialog',
  templateUrl: './bill-contact-dialog.component.html',
  styleUrls: ['./bill-contact-dialog.component.scss']
})
export class BillContactDialogComponent implements OnInit, OnDestroy {
  billContactForm: FormGroup;
  currentContact: Contact;
  fonctions: Observable<ListSelect[]>;
  genderIdValues = environment.genderIdValues;
  subscriptions: Subscription[] = [];
  @ViewChild('formBuildContact') formBuildContact: FormGroupDirective;
  countryCode = '';
  countries = COUNTRIES;

  constructor(
    private fb: FormBuilder,
    private staticService: StaticInfoService,
    public dialogRef: MatDialogRef<BillContactDialogComponent>,
    public adhesionService: AdhesionService,
    public loaderService: LoaderService,
    private tagService: TagService,
    @Inject(MAT_DIALOG_DATA) data)
  {
    if (data) {
      this.currentContact = data;
    }
  }
  ngOnDestroy(): void {
    this.subscriptions.forEach((sub: Subscription) => {
      sub.unsubscribe();
    });
  }

  ngOnInit(): void {
    this.fonctions = this.staticService.jobTitlesSub;
    this.billContactForm = this.fb.group({
      genderId: [this.currentContact?.genderId || null, [Validators.required]],
      firstname: [
        this.currentContact?.firstname || null,
        Validators.compose([Validators.required, Validators.minLength(2), Validators.pattern(/[A-Za-zà-ù\-\s]+/)])
      ],
      lastname: [
        this.currentContact?.lastname || null,
        Validators.compose([Validators.required, Validators.minLength(2), Validators.pattern(/[A-Za-zà-ù\-\s]+/)])
      ],
      street: [this.currentContact?.street || null, [Validators.required]],
      street2: [this.currentContact?.street2, null],
      zipCode: [this.currentContact?.zipCode || null, [Validators.required]],
      city: [this.currentContact?.city || null, [Validators.required]],
      phone: [this.currentContact?.phone || null, [Validators.required]],
      functionId: [this.currentContact?.functionId || null, [Validators.required]],
      email: [
        this.currentContact?.email,
        Validators.compose([
          Validators.required, Validators.email,
          CustomValidators.mailValidator(), CustomValidators.contactAndBillEmailsMismatch(this.adhesionService.contact.email)
        ])
      ],
      companyName: [this.currentContact.companyName || this.adhesionService.company.name, [Validators.required]]
    });
    this.forceUpperCase('lastname', true);
    this.forceUpperCase('firstname', false);
  }

  forceUpperCase(formControlName: string, forceAll: boolean) {
    this.subscriptions.push(this.billContactForm.get(formControlName).statusChanges.subscribe(() => {
        if (!this.billContactForm.get(formControlName).value) {
          return;
        }
        if(forceAll) {
            if(this.billContactForm.get(formControlName).value === this.billContactForm.get(formControlName).value.toUpperCase())  {
                return;
               }
            this.billContactForm.get(formControlName).setValue(this.billContactForm.get(formControlName).value.toUpperCase());
            return;
        }
        if(this.billContactForm.get(formControlName).value[0] === this.billContactForm.get(formControlName).value[0].toUpperCase())  {
            return;
           }
        this.billContactForm.get(formControlName).setValue(this.billContactForm.get(formControlName).value.charAt(0).toUpperCase() + this.billContactForm.get(formControlName).value.slice(1));
         
      }));
}


  saveContact(): void {
    const prospect = this.adhesionService.getProspect();

    if (prospect && prospect.billContact)
    {
      this.tagService.sendTagWithLabel('Click', 'Modifier le contact - Confirmation');
    }
    else
    {
      this.tagService.sendTagWithLabel('Click', 'Confirmation ajout contact facturation');
    }
    if (this.billContactForm.valid) {
      this.adhesionService.billContact = this.billContactForm.value;
      this.adhesionService.billContactUpdated.next(this.adhesionService.billContact);
      this.loaderService.registerWait('billContact');
      this.adhesionService.saveAndProceed(3.5).subscribe(() => {
        this.loaderService.endWaiting('billContact');
        this.dialogRef.close(this.billContactForm.value);
      });
    }
  }

  displayError(field: string, error: string): boolean {
    if (this.billContactForm) {
      return CustomValidators.displayError(this.billContactForm, field, error);
    }
    return null;
  }

}
