import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { CompanyData } from 'src/app/models/company-data/company-data';
import { Company } from 'src/app/models/company/company';
import { LoaderService } from '../loader/loader.service';
import { tap, catchError, finalize } from 'rxjs/operators';
import { ErrorService } from '../error/error.service';

@Injectable({
  providedIn: 'root'
})
export class DqeService {

  constructor(private http: HttpClient, public loader: LoaderService, public errorService: ErrorService) { }

  searchCompany(criteria: string): Observable<CompanyData[]> {
    let data = '';
    let criteriaparam = criteria.split(' ').join('');
    criteriaparam = criteria.split('"').join('');
    if ((criteria != null) && (criteria !== '') && !isNaN(Number(criteriaparam.toString()))) {
      data += `Siren=${criteriaparam}`;
    } else {
      data += `CompanyName=${criteriaparam}`;
    }
    return this.http.get<CompanyData[]>(`${environment.baseUrl}/api/Companies/Search?${data}`).pipe(
      catchError(err => {
        this.errorService.handleError(err);
        return of ([]);
      })
    );
  }

  searchCompanyInfo(ref: string): Observable<Company> {
    // return of ({siren: 23333333, name: "Test"});
    this.loader.registerWait('searchCompany');
    return this.http.get<Company>(`${environment.baseUrl}/api/Companies?SearchReference=${ref}`).pipe(
      catchError(err => {
        this.errorService.handleError(err);
        return of(null);
      }),
      finalize(() => this.loader.endWaiting('searchCompany'))
    );
  }
}
