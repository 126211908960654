import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Setting } from 'src/app/models/MembershipSetting/setting';

import { environment } from 'src/environments/environment';
import { AdhesionService } from '../adhesion/adhesion.service';
import { ErrorService } from '../error/error.service';
import { LoaderService } from '../loader/loader.service';

@Injectable({
  providedIn: 'root'
})
export class SettingService {
  

  constructor(private http: HttpClient,
              public oauthService: OAuthService,
              private loader: LoaderService,
              public adhesionService: AdhesionService,
              public errorService: ErrorService, ) {}


              public async getSettings(): Promise<Setting[]> {
                 
                return this.http.get<Setting[]>(`${environment.baseUrl}/api/MembershipSettings`).toPromise() ;
              }
              
}

