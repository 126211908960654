import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, ActivatedRoute } from '@angular/router';
import { OAuthService } from 'angular-oauth2-oidc';
import { Observable } from 'rxjs';
import { of } from 'rxjs/internal/observable/of';
import { catchError } from 'rxjs/internal/operators/catchError';
import { tap } from 'rxjs/operators';
import { StateMembership } from 'src/app/models/StateMembership/SateMembership';
import { AdhesionService } from 'src/app/services/adhesion/adhesion.service';
import { LoaderService } from 'src/app/services/loader/loader.service';
import { LoginService } from 'src/app/services/login/login.service';

@Injectable({
  providedIn: 'root'
})
export class ContactInfosGuard implements CanActivate {

  constructor(public adhesionService: AdhesionService,private loginService: LoginService) {}

  canActivate(
    next: ActivatedRouteSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      const email = next.queryParams['email'];

      if (next.url.length === 2 && next.url[0].path=="mes-informations"  && email ) {
        const id=next.url[1].path;
        this.loginService.getLoginInfofromecli(email,id);
        this.loginService.login(false);
        return true;
      }

      if (!this.adhesionService.id && next.url.length === 2 ) {
        this.adhesionService.retrieveProspect(next.url[1].path);
        return true;
      }
      if (this.adhesionService.isDone && !this.adhesionService.isBreakMembership ) {
        this.adhesionService.redirectToCurrentStep();
        return false;
      }
      return true;

  }


}
