import { Title } from '@angular/platform-browser';
import { AdhesionService } from 'src/app/services/adhesion/adhesion.service';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { Prospect } from 'src/app/models/prospect/prospect';
 import { TagService } from 'src/app/services/tagService/tag-service.service';
import { LoginService } from 'src/app/services/login/login.service';
import { environment } from 'src/environments/environment';
import { SmartPushService } from 'src/app/services/smartPush/smartPush.service';
import { Component, OnInit } from '@angular/core';
 
@Component({
  selector: 'app-validation-page',
  templateUrl: './validation-page.component.html',
  styleUrls: ['./validation-page.component.scss']
})
export class ValidationPageComponent implements OnInit {

  sub: Subscription[] = [];
  showing = '';
  timeout = false;
  hasPrivileges = false;
   

  constructor(
    private titleService: Title,
    public adhesionService: AdhesionService,
    public tagService: TagService,
    private loginService: LoginService,
    private smartPushService: SmartPushService
 
    ) {
    this.titleService.setTitle('adhésion confirmée ! - GS1 France');
  }

  async ngOnInit(): Promise<void> {
     
    this.sub.push(this.adhesionService.prospectUpdated.subscribe(
      (prospect: Prospect) => {
        if (this.loginService.state)
        {
          this.displayRightInfos(prospect);
        }
      })
    );
    this.smartPushService.smartPushUpdate(['adhesion-page-finale-4547']);
  }

  async displayRightInfos(prospect: Prospect) {
    // if (prospect && prospect.isRejectedSepa && !prospect.isAllowedCb) {
    //   this.showing = 'errorSepa';
    //   this.tagService.sendTagWithLabel('Failure', 'Transaction failed - SEPA');
    // } else {
      if (prospect && prospect.isRejectedSepa) {
        this.tagService.sendTagWithLabel('Validation', 'Transaction success - SEPA > CB');
      }
      if (prospect && ( prospect.company?.need >= 1000 || prospect.company?.locationCount >= 1000)) {
        this.showing = 'GCP6-8';
        this.tagService.sendTagWithLabel('Validation', 'Transaction success - SEPA - awaiting ERP');
      } else if (prospect && (prospect.company?.need < 1000 || prospect.company?.locationCount < 1000)) {
        this.showing = 'GCP9-10';
        this.tagService.sendTagWithLabel('Validation', 'Transaction success - SEPA');
        //await this.fetchGLN();
      }
    // }
    this.sendProductDataLayer(this.adhesionService.prospect);
  }

  async fetchGLN() {
    const hasAccess = await this.adhesionService.canAccessCodeOnline();
    this.timeout = !hasAccess;
    this.hasPrivileges = hasAccess;
  }

  sendProductDataLayer(prospect: Prospect){
    if (prospect && this.loginService && this.loginService.state) {
      this.adhesionService.getTarif(prospect.company.caTranche, prospect.company.codePostal)
        .subscribe(tarif => {
          this.tagService.sendProductDataLayer(prospect, tarif);
          this.logOut();
        });
    }
  }

  logOut(){
    if (this.adhesionService.isDone || (this.adhesionService.isRejectedSepa && !this.adhesionService.isAllowedCb))
    {

      this.loginService.logOut();
    }
  }

  getCodeonlineUrl(): string {
    return environment.codeonlineUrl;
  }
}
