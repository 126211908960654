import { ValidationErrors, ValidatorFn, AbstractControl, AsyncValidatorFn, FormGroup, FormControl, Validators } from '@angular/forms';

export class CustomValidators {

  constructor() {}

  static patternValidator(regex: RegExp, error: ValidationErrors): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!control.value) {
        return null;
      }
      const valid = regex.test(control.value);
      return valid ? null : error;
    };
  }

  static mailValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!control.value) {
        return null;
      }
      const mailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,20})+$/;
      const valid = mailRegex.test(control.value);
      return valid ? null : {email: true};
    };
  }

  static maxChoicesValidator(limit: number): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      return control.value && control.value.length > limit ? {maxChoices: true} : null;
    };
  }

  static passwordMatchValidator(control: AbstractControl) {
    const password: string = control.get('password').value;
    const confirmPassword: string = control.get('passwordConfirm').value;
    if (password !== confirmPassword) {
      control.get('passwordConfirm').setErrors({ confirmpass: true });
    }
  }

  static hasBeenCheckedForAtLeast(minRequired = 1): ValidatorFn {
    return function validate(formGroup: FormGroup) {
      let checked = 0;

      Object.keys(formGroup.controls).forEach(key => {
        const control = formGroup.controls[key];
        if (control.value === true) {
          checked ++;
        }
      });

      if (checked < minRequired) {
        return {notSelected: true};
      }

      return null;
    };
  }

  static passwordEmailMismatch(control: AbstractControl) {
    const password: string = control.get('password').value;
    const email: string = control.get('email').value;
    if (password && email && password.toLowerCase() === email.toLowerCase()) {
      control.get('password').setErrors({equalEmail: true});
    }
  }

  static contactAndBillEmailsMismatch(contactEmail): ValidatorFn {
    return (control: AbstractControl) => {
      if (!control.value) {
        return null;
      }
      const email: string = control.value;
      if (contactEmail && email && contactEmail.toLowerCase() === email.toLowerCase()) {
        return {emailsMatches: true};
      }
      return null;
    };
  }

  static displayError(form: FormGroup, control: string, error: string): boolean {
    const field = form.get(control);
    if (!(field.dirty || field.touched)) {
      return false;
    }
    return form.get(control).hasError(error);
  }

  static frenchMobileNumberValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!control.value || control.value.length !== 12) {
        return null;
      }
      const phoneNumber: string = control.value.charAt(3);
      if ( phoneNumber !== '6' && phoneNumber !== '7' ) {
        return { notMobileNumber: true };
      }
      else {
        return null;
      }
    };
  }

  static ibanValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!control.value) {
        return null;
      }
      const ibanRegex = /^(FR)[\w]{25}$/igm;
      const val = control.value.replace(/\s/g, '');

      const valid = ibanRegex.test(val);
      return valid ? null : {iban: true};
    };
  }
}
