export const environment = {
  production: false,
  baseUrl: 'https:///uat-Gateway.gs1.fr/MembershipService',
  env: 'uat',
  genderIdValues: {
    Monsieur: '860330000',
    Madame: '860330001'
  },
  lblBlockedActivity: 'blockedActivity',
  ssoUrl : 'https://uat-connect1.gs1.fr',
  ssoFullLogin:'https://uat-connect1.gs1.fr/account/connectuser',
  ssoReturnUrl: 'https://uat-adherer.gs1.fr/login',
  ssoLogoutUrl: 'https://uat-connect1.gs1.fr/connect/endsession',
  ssoLogoutReturnUrl: 'https://www.gs1.fr',
  codeonlineUrl: 'https://uat.codeonline.fr/'
};
