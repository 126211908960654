import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorComponent } from 'src/app/components/dialogs/error/error/error.component';

@Injectable({
  providedIn: 'root'
})
export class ErrorService {

  constructor(public dialog: MatDialog) { }

  handleError(error?: any, forced = false) {
    let msg: string;
    if (error && typeof error === 'string') {
      msg = error;
    } else if (error && error.message) {
      msg = error.message;
    } else {
      msg = 'Aucune information supplémentaire';
    }
    this.dialog.open(ErrorComponent, {
      data: {
        message: msg,
        forced
      }
    });
  }
  handleNullLoginIdError() {
    this.dialog.open(ErrorComponent, {
      data: {
        message: "Notre service est momentanément indisponible. Merci de réessayer dans quelques minutes.",
        forced: true
      }
   })
  }
}
