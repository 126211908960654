<app-stepper [step]="1"></app-stepper>
<div class="page-content">
<!--   <app-support-sticker [currentForm]="contactForm"></app-support-sticker>
 -->  <iframe style="max-height: 0; border: none;" id="iframeconnect1" name="iframeconnect1"  sandbox="allow-top-navigation allow-same-origin allow-scripts allow-forms" role="form" referrerpolicy="same-origin" [src]="ssoEnv"></iframe>
  <gs1-alert class="gs1-spacer-4" [type]="'info'" >
    <span class="gs1-little">Sauf information contraire, tous les champs du formulaire sont obligatoires.</span>
  </gs1-alert>
  <form [formGroup]="contactForm" #formContact="ngForm" (ngSubmit)="saveOrConfirmMail()">
    
    <gs1-form-group [label]="'Identifiant'" class="email-block gs1-spacer-5">
      <div class="login-input">
      <gs1-input formControlName="email" name="email" [showValid]="true" [label]="'Votre email professionnel'" [helper]="'Il sera également votre identifiant de connexion'">
        <span slot="error" *ngIf="displayError('email', 'required')">L'adresse email est requise</span>
        <span slot="error" *ngIf="displayError('email', 'email')">Votre email doit être de la forme xxx@xxx.xx et sans accents</span>
        <span slot="error" *ngIf="displayError('email', 'emailExists')">Veuillez poursuivre en cliquant sur le bouton Me connecter.</span>
        <span slot="error" *ngIf="displayError('email', 'parcoursExists')">Veuillez poursuivre en cliquant sur le bouton Me connecter.</span>
        <span slot="error" *ngIf="displayError('email', 'memberShipAndProspect')">Veuillez poursuivre en cliquant sur le bouton Me connecter.</span>
      </gs1-input>
      <div *ngIf="!isLogged && contactForm.get('email').status === 'PENDING'" class="loading-login">
        <div gs1Spinner class="spinner" [size]="'15px'"></div>
        <span class="gs1-small">Nous vérifions l'existence de ce mail</span>
      </div>
      <div style="margin-top: 40px;margin-left: 45px;" *ngIf="(contactForm.get('email').hasError('memberShipAndProspect') || contactForm.get('email').hasError('parcoursExists') || contactForm.get('email').hasError('emailExists'))&&!isLogged">
        <button gs1Button type="button" [thin]="true" id="continue" (click)="login()">Me connecter</button>
      </div>
    </div>
       
    </gs1-form-group>
    <ng-container *ngIf="contactForm.get('email').valid === true || isLogged">
      <gs1-form-group class="gs1-spacer-5" [label]="'Vos informations personnelles'">
        <gs1-radios [block]="true">
          <gs1-radio formControlName="genderId" [name]="'monsieur'" [value]="genderIdValues.Monsieur">Monsieur</gs1-radio>
          <gs1-radio formControlName="genderId" [name]="'madame'" [value]="genderIdValues.Madame">Madame</gs1-radio>
          <span slot="error" *ngIf="displayError('genderId', 'required')">Le genre est requis</span>
        </gs1-radios>
        <div class="columns one-to-one">
          <gs1-input formControlName="lastname" [label]="'Nom'" [name]="'lastname'">
            <span slot="error" *ngIf="displayError('lastname', 'required')">Un nom est requis</span>
            <span slot="error" *ngIf="displayError('lastname', 'minlength')">Un nom doit comprendre au moins 2 caractères</span>
            <span slot="error" *ngIf="displayError('lastname', 'pattern')">Ce nom n'est pas valide</span>
          </gs1-input>
  
          <gs1-input formControlName="firstname" [label]="'Prénom'" [name]="'firstname'">
            <span slot="error" *ngIf="displayError('firstname', 'required')">Un prénom est requis</span>
            <span slot="error" *ngIf="displayError('firstname', 'minlength')">Un prénom doit comprendre au moins 2 caractères</span>
            <span slot="error" *ngIf="displayError('firstname', 'pattern')">Ce prénom n'est pas valide</span>
          </gs1-input>
          <gs1-phone formControlName="phone1" [name]="'phone-1'" [label]="'Téléphone professionnel principal'" [helper]="'De préférence votre mobile'">
            <span slot="error" *ngIf="displayError('phone1', 'required')">Le téléphone principal est requis</span>
            <span slot="error" *ngIf="displayError('phone1', 'validatePhoneNumber')">Ce téléphone n'est pas valide</span>
          </gs1-phone>
          
          <gs1-phone formControlName="phone2" [name]="'phone-2'" [label]="'Téléphone professionnel secondaire (optionnel)'" [helper]="'Fixe ou mobile'">
            <span slot="error" *ngIf="displayError('phone2', 'validatePhoneNumber')">Ce téléphone n'est pas valide</span>
          </gs1-phone>
  
          <gs1-select-wrapper>
            <label for="functionId">Fonction</label>
            <ng-select formControlName="functionId" name="functionId" [bindValue]="'id'" [bindLabel]="'label'" [items]="fonctions | async"
              [clearable]="false" [searchable]="false" [multiple]="false"  [closeOnSelect]="true"></ng-select>
            <span slot="error" *ngIf="displayError('functionId', 'required')">La fonction est requise</span>
          </gs1-select-wrapper>
        </div>
      </gs1-form-group>
      <gs1-form-group *ngIf="!isLogged || isNotFullSsoAccount" [label]="'Vos informations de profil'">
          
        <div  class="columns one-to-one">
          <div>
            <gs1-input appNoCopyPaste formControlName="password" [type]="passwordShowing ? 'text' : 'password'" [label]="'Mot de passe'" [showValid]="true" [name]="'password'" #passwordInput>
              <i class="bi bi-eye-{{passwordShowing ? 'fill': 'slash-fill'}}" slot="suffixe" (click)="passwordShowing = !passwordShowing"></i>
              <span slot="error" *ngIf="displayError('password', 'equalEmail')">Votre mot de passe ne peut pas être identique à votre email.</span>
            </gs1-input>
            <div class="validity-states gs1-little">
              <span class="validity-info" [class]="(getValidity('password', 'minlength') && getValidity('password', 'maxlength')) ? 'gs1-color-forest': 'gs1-color-ui-5'">
                  Entre 8 et 64 caractères
                  <i class="bi bi-{{getValidity('password', 'minlength') && getValidity('password', 'maxlength') ? 'check-lg' : 'x-lg'}}"></i>
              </span>
              <span class="validity-info" [class]="getValidity('password', 'hasSpecialCharacter') ? 'gs1-color-forest': 'gs1-color-ui-5'">
                Contient un caractère spécial (&, @, %, $...)
                <i class="bi bi-{{getValidity('password', 'hasSpecialCharacter') ? 'check-lg' : 'x-lg'}}"></i>
              </span>
              <span class="validity-info" [class]="getValidity('password', 'hasNumber') ? 'gs1-color-forest': 'gs1-color-ui-5'">
                Contient un chiffre
                <i class="bi bi-{{getValidity('password', 'hasNumber') ? 'check-lg' : 'x-lg'}}"></i>
              </span>
              <span class="validity-info" [class]="getValidity('password', 'hasMinAndMaj') ? 'gs1-color-forest': 'gs1-color-ui-5'">
                Contient une majuscule et une minuscule
                <i class="bi bi-{{getValidity('password', 'hasMinAndMaj') ? 'check-lg' : 'x-lg'}}"></i>
              </span>
            </div>
          </div>
          <div>
            <gs1-input appNoCopyPaste formControlName="passwordConfirm" (blur)="onLeavePasswordConfirm()" [showValid]="true" [type]="passwordShowing ? 'text' : 'password'" [label]="'Confirmation du mot de passe'" [disabled]="!contactForm.get('password').valid" [name]="'password-confirm'" #passwordInput>
              <i class="bi bi-eye-{{passwordShowing ? 'fill': 'slash-fill'}}" slot="suffixe" (click)="passwordShowing = !passwordShowing"></i>
              <span slot="error" *ngIf="displayError('passwordConfirm', 'required')">Veuillez confirmer votre mot de passe</span>
              <span slot="error" *ngIf="displayError('passwordConfirm', 'confirmpass')">Les mots de passe saisis ne sont pas identiques.</span>
            </gs1-input>
          </div>
        </div>
  
      </gs1-form-group>
      <div class="action-bar gs1-spacer-3">
        <button gs1Button *ngIf="!isLogged" [disabled]="formContact.valid !== true" id="nextStep">Je crée mon profil</button>
        <button gs1Button *ngIf="isLogged && isProspect"  [disabled]="formContact.valid !== true"  id="nextStep">Je continue mon parcours d'adhésion</button>
        <button gs1Button *ngIf="isLogged && !isProspect"  [disabled]="formContact.valid !== true"  id="nextStep">J'adhére une nouvelle entreprise</button>
      </div>
    </ng-container>
  </form>
  <div class="gs1-legal">
    <p>Les informations recueillies ici par GS1 France font l’objet d’un traitement destiné à traiter la demande d’adhésion et, le cas échéant, à exécuter les services souscrits et assurer le suivi des relations clients. Elles permettent également à GS1 France d’adresser aux personnes dont les données personnelles sont collectées une lettre d’information électronique, diverses communications, des invitations à des groupes de travail et/ou évènements ainsi que des enquêtes de satisfaction afin d’améliorer les services proposés par GS1 France.</p>
    <p>Conformément aux dispositions de la loi n°78-17 du 6 janvier 1978 dite « Informatique et Libertés » modifiée, au Règlement UE 2016/679 ou à toute autre disposition normative qui viendrait le compléter ou s’y substituerait, chaque personne, dont les données personnelles sont collectées, dispose d’un droit général d’accès, de modification, de rectification et de suppression des données personnelles la concernant. Toute personne dont les données personnelles sont collectées dispose également du droit de s’opposer, pour des motifs légitimes, au traitement de ces données. Ces droits peuvent être exercés à tout moment en adressant un courrier électronique à <a href="mailto:serviceclient@gs1fr.org">serviceclient@gs1fr.org</a> ou un courrier postal à Service client GS1 France, 21 boulevard Haussmann 75009 Paris, en nous indiquant la dénomination sociale de son entreprise, sa référence adhérent, ses nom et prénom, accompagnés de la copie de sa pièce d’identité.</p>
    <p>Pour plus d’information, vous pouvez consulter notre <a href="https://www.gs1.fr/Articles/Politique-de-confidentialite?_ga=2.209485460.745918811.1592316893-1681267013.1592316893" target="_blank">Politique de Confidentialité</a>.</p>
  </div>
  <ng-template #confirmDialog>
    <h3 matDialogTitle>Merci de confirmer votre email</h3>
    <mat-dialog-content>
      <p class="gs1-little">
        Pour rappel, votre email <span class="important">{{contactForm.get('email').value}}</span> sera l'identifiant pour vous connecter aux outils de GS1 France. Si cette adresse est <b>incorrecte</b>, vous pouvez la corriger en cliquant sur le bouton "Je corrige".
        <!-- A titre d'information, un email de confirmation de création de votre profil sera envoyé à l'adresse <span class="important">{{contactForm.get('email').value}}</span>. Si cette adresse est incorrecte, vous pouvez la corriger.</p> -->
    </mat-dialog-content>
    <mat-dialog-actions>
      <button gs1Button matDialogClose [appearance]="'outlined'" *ngIf="!isLogged" (click)="correctEmail()">Je corrige</button>
      <button gs1Button cdk-focus-start [disabled]="disableButton" (click)="saveAndProceed(true)" >Je confirme</button>
    </mat-dialog-actions>
  </ng-template>
</div>
