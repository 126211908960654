import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AdhesionService } from 'src/app/services/adhesion/adhesion.service';
import { LoginService } from 'src/app/services/login/login.service';

@Injectable({
  providedIn: 'root'
})
export class PaiementInfosGuard implements CanActivate {

  constructor(public adhesionService: AdhesionService
    ,         private loginService: LoginService
    ) {}

  canActivate(
    next: ActivatedRouteSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      if (!this.loginService.state)
      {
       this.loginService.refreshStateMembership();
      }
      if (!this.adhesionService.id && next.url.length === 2 && this.loginService.state) {
        this.adhesionService.retrieveProspect(next.url[1].path);
        this.loginService.hasContact = true;
        return true;
      } else if ((this.adhesionService.company?.need || this.adhesionService.company?.locationCount) && !this.adhesionService.isDone) {
        this.loginService.hasContact = true;
        return true;
      }
      this.adhesionService.redirectToCurrentStep();
      return false;
  }

}
