<app-stepper [step]=4></app-stepper>
<div class="page-content">
  <app-support-sticker *ngIf="isNeedHelpEnabled" [currentForm]="null"></app-support-sticker>

  <gs1-link-card [title]="'Montant de votre adhésion'">
    <div class="price-card gs1-spacer-1">
      <div class="columns price-info">
        <div class="gs1-color-blue gs1-little rappel"> <p><i>Pour rappel, le montant de votre adhésion est calculé sur la base de votre chiffre d'affaires.</i></p></div>
        <div class="price"><span class="real-price">{{(tarif | async)?.totalAmountTTC | number:'1.2-2':'fr-FR'}} € TTC</span><p class="ht-price">{{(tarif | async)?.totalAmountHT | number:'1.2-2':'fr-FR'}} € HT</p></div>
      </div>
    </div>    
  
  </gs1-link-card>

  <div class="columns one-ton-one contact">
    <div>
      <h3>Contact d'entreprise</h3>
      <span class="gs1-little gs1-spacer-3">Personne ou service de contact dans l'entreprise</span>
      <div *ngIf="companyContact && companyContact.companyName" class="info-entreprise gs1-little gs1-color-ui-6 gs1-card">
        <span class="name">{{companyContact.companyName}}</span>
        <span>{{companyContact.lastname}} {{companyContact.firstname}}</span>
        <span>{{companyContact.street}}</span>
        <span *ngIf="companyContact.street2">{{companyContact.street2}}</span>
        <span>{{companyContact.zipCode}} {{companyContact.city}}</span>
        <span>{{companyContact.country}}</span>
        <span  class="phone">{{companyContact.phone | phone}}</span>
        <span >{{companyContact.email}}</span>
      </div>
    </div>
    <div>
      <h3>Contact de facturation</h3>
      <span class="gs1-little gs1-spacer-3">Personne ou service à qui seront adressées les factures</span>
      <div class="info-entreprise gs1-card gs1-little gs1-color-ui-6" *ngIf="adhesionService.billContactUpdated | async;else noBillContact">
        <span class="name">{{companyContact.companyName}}</span>
        <span>{{adhesionService.billContact.lastname}} {{adhesionService.billContact.firstname}}</span>
        <span>{{adhesionService.billContact.street}}</span>
        <span *ngIf="adhesionService.billContact.street2">{{adhesionService.billContact.street2}}</span>
        <span>{{adhesionService.billContact.zipCode}} {{adhesionService.billContact.city}}</span>
        <span>{{adhesionService.billContact.country ? getCountryName(adhesionService.billContact.country) : ''}}</span>
        <span *ngIf="!isbillAlreadyExist" class="phone">{{adhesionService.billContact.phone | phone}}</span>
        <span *ngIf="!isbillAlreadyExist">{{adhesionService.billContact.email}}</span>
        <button gs1Button [appearance]="'clear'" [color]="'link'" id="modify-bill-contact" (click)="showBillContactForm()">Modifier</button>
      </div>
      <ng-template #noBillContact>
        <span class="gs1-card gs1-little">Par défaut, le contact entreprise est utilisé mais vous pouvez <button gs1Button [appearance]="'clear'" [thin]="true" [color]="'link'" id="add-bill-contact" (click)="showBillContactForm()">ajouter un contact de facturation</button></span>
      </ng-template>
    </div>
  </div>

  <mat-divider></mat-divider>
  <form [formGroup]="paiementForm" *ngIf="paiementForm && (!hasPaiementErrors() || paiement && paiement.mandateStatus === 'OK')" #formPaiement="ngForm" (ngSubmit)="createAndSignMandate()">
    <h3>Coordonnées SEPA</h3>
    <div *ngIf="!hasPaiementErrors()" class="sepa gs1-little gs1-spacer-5">
      <p>Les coordonnées SEPA de votre entreprise sont demandées afin de permettre le prélèvement annuel de votre adhésion.</p>
      <ol class="custom-list">
        <li class="no-field">Après la saisie d'un IBAN <b>français</b> valide, cliquez sur le bouton Continuer pour lire votre mandat.</li>
        <li *ngIf="smsCodeShowing">Un code SMS est ensuite envoyé au numéro suivant pour valider votre mandat.
          <span class="field" *ngIf="!editPhoneShowing">{{ paiement.phone }} <button mat-button id="showSMS" [disabled]="isDisabled" color="accent" (click)='showEditPhone()' >Modifier</button></span>
          <div class="columns one-to-one">
            <gs1-phone *ngIf="editPhoneShowing" [showValid]="true" [name]="'phone'" formControlName="phone">
              <span slot="error" *ngIf="displayError('phone', 'required')">Le téléphone principal est requis</span>
              <span slot="error" *ngIf="displayError('phone', 'validatePhoneNumber')">Ce téléphone n'est pas valide</span>
              <span slot="error" *ngIf="displayError('phone', 'notMobileNumber')">Ce numéro de téléphone n'est pas un mobile</span>
            </gs1-phone>
          </div>
        </li>
        <li *ngIf="mailCodeShowing">Un code est ensuite envoyé à l'email suivant pour valider votre mandat.
          <span class="field" *ngIf="!editEmailShowing">{{ paiement.email }} <button gs1Button [appearance]="'clear'" id="showEmail" [disabled]="isDisabled"  [color]="'link'" (click)='showEditEmail()' >Modifier</button></span>
          <div class="columns one-to-one">
            <gs1-input *ngIf="editEmailShowing" [name]="'email'" formControlName="email" type="email">
              <span slot="error" *ngIf="displayError('email', 'required')">L'adresse email est requise</span>
              <span slot="error" *ngIf="displayError('email', 'email')">Votre email doit être de la forme xxx@xxx.xx</span>
            </gs1-input>
          </div>
        </li>
        <li class="no-field">
          Une fois votre mandat validé, vous pouvez effectuer le paiement sur cette page.
          <div class="columns one-to-one">
            <gs1-input [name]="'iban'" formControlName="iban" [placeholder]="'Exemple : FR7630006000011234567890189'" [label]="'Code IBAN'"
              [showValid]="true" [showIcons]="!paiement.iban">
              <span slot="error" *ngIf="displayError('iban', 'required')">L'Iban est requis</span>
              <span slot="error" *ngIf="displayError('iban', 'iban')">L'iban saisi n'est pas valide</span>
              <span slot="error" *ngIf="displayError('iban', 'refused')">Votre IBAN n'a pas été validé par notre établissement bancaire, veuillez contacter le service client au 01.40.22.18.00 pour finaliser l'adhésion</span>
            </gs1-input>
          </div>
        </li>
      </ol>
    </div>
    
    
    <div class="action-bar gs1-spacer-3" *ngIf="!hasPaiementErrors() && (!paiement || paiement && paiement.mandateStatus !== 'OK')">
      <button gs1Button [disabled]="!paiementForm.valid">Continuer</button>
    </div>
  </form>

  <ng-container *ngIf="!forceCBPayment && !hasPaiementErrors() && canPreferCB()">
    <mat-divider id="prefer-cb-divider"></mat-divider>
    <button gs1Button [appearance]="'clear'" [color]="'link'" (click)="forceCBPayment = true">Je préfère payer tous les ans ma cotisation par carte bleue.</button>
  </ng-container>

  <ng-container *ngIf=" paiement != null && (paiement.mandateStatus === 'OK' || forceCBPayment || showCBPayment)">
    <a gs1Button *ngIf="paiement && paiement.mandateStatus === 'OK'" [appearance]="'outlined'" (click)="tagService.sendTagWithLabel('Download', 'Mandat de prélèvement')" href="{{getUrlMandat()}}" target="_blank"> <i class="bi bi-download"></i> Télécharger le mandat <span class="lg">de prélèvement</span></a>
    <mat-divider *ngIf="paiement && paiement.mandateStatus === 'OK' || !hasPaiementErrors()"></mat-divider>
    <div *ngIf="hasPaiementErrors()" class="block-info gs1-spacer-5" id="refused">
      <gs1-alert *ngIf="errorOnMandat || errorOnSignature" [type]="'danger'" [title]="'Le prélèvement Sepa n\'a pu être effectué'"
        [content]="'Nous avons rencontré une difficulté sur le prélèvement mais vous pouvez effectuer le <b>paiement par carte bancaire.</b>'">
      </gs1-alert>
      <gs1-alert *ngIf="errorOnSEPAPaiement" [type]="'danger'" [title]="'Le prélèvement Sepa n\'a pu être effectué'"
        [content]="'Nous avons rencontré une difficulté sur le prélèvement mais vous pouvez effectuer le <b>paiement par carte bancaire</b>. Ce paiement remplace le premier prélèvement de votre adhésion.'">
      </gs1-alert>
      <gs1-alert *ngIf="showErrorCB" [type]="'danger'" [title]="'Le paiement par carte n\'a pas abouti'"
        [content]="'Nous avons rencontré une difficulté sur le paiement par carte mais vous pouvez <b>essayer une autre carte bancaire</b>. Si le problème persiste, merci de contacter le service client au 01 40 22 18 00.'">
      </gs1-alert>
    </div>
    <gs1-checkbox [formControl]="cga" [name]="'cga'" class="gs1-spacer-5">
      <span>En cochant cette case, l'adhérent reconnait avoir pris connaissance des <a href="{{getUrlCGA()}}" (click)="$event.stopPropagation();tagService.sendTagWithLabel('Download', 'CGV')" target="_blank">conditions générales d'adhésion</a> GS1 France, les accepter dans leur intégralité, et garantit que le signataire est dûment habilité à le représenter.</span>
    </gs1-checkbox>
    <div class="action-bar">
      <button gs1Button [disabled]="cga.value !== true" (click)="saveAndProceed()">Payer mon adhésion {{showCBPayment || forceCBPayment ? 'par carte ' : ''}} ({{(tarif | async)?.totalAmountTTC | number:'1.2-2':'fr-FR'}} €)</button>
    </div>
  </ng-container>

</div>
