import { Component,  ElementRef,  OnInit,  TemplateRef, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { AdhesionService } from 'src/app/services/adhesion/adhesion.service';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { DqeService } from 'src/app/services/dqe/dqe.service';
import { startWith, debounceTime, switchMap, tap, finalize, distinctUntilChanged, catchError } from 'rxjs/operators';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { CompanyData } from 'src/app/models/company-data/company-data';
import { StaticInfoService } from 'src/app/services/static-info/static-info.service';
import { concat, Observable, of, Subject } from 'rxjs';
import { CustomValidators } from 'src/app/validators/custom-validators/custom-validators';
import { TarifData } from 'src/app/models/tarif-data/tarif-data';
import { Company } from 'src/app/models/company/company';
import { ListSelect } from 'src/app/models/list-select/list-select';
import { TagService } from 'src/app/services/tagService/tag-service.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { MatSelect } from '@angular/material/select';
import { ScrollToConfigOptions, ScrollToService } from '@nicky-lenaers/ngx-scroll-to';
import { ErrorService } from 'src/app/services/error/error.service';
import { NgSelectComponent } from '@ng-select/ng-select';
import { SharedSettingService } from 'src/app/services/sharedsetting/sharedsetting.service';
import { SmartPushService } from 'src/app/services/smartPush/smartPush.service';

@Component({
  selector: 'app-company-infos',
  templateUrl: './company-infos.component.html',
  styleUrls: ['./company-infos.component.scss']
})
export class CompanyInfosComponent implements OnInit {

  researchField: FormControl = new FormControl();
  filteredResearch: CompanyData[];
  companyForm: FormGroup;
  companySelected = false;
  companyManual = false;
  isLoading = false;
  showIsResigned = false;
  showIsDoubtful = false;
  showProduct = false;
  isNeedHelpEnabled=false;
  tarifInfo: TarifData;
  caTranches: Observable<ListSelect[]>;
  selectedCompany: Company;
  canaux: Observable<ListSelect[]>;
  canauxSecond: ListSelect[] = [];
  activities: Observable<ListSelect[]>;
  codesProduits: Observable<ListSelect[]>;
  highRangeSelected = false;
  activityMessage = '';
  activityLabel = '';

  company$: Observable<CompanyData[]>;
  companyLoading = false;
  companyInput$ = new Subject<string>();

  @ViewChild('confirmDialog', { static: true }) confirmDialog: TemplateRef<any>;
  @ViewChild('activity') activitySelectList: NgSelectComponent;
  @ViewChild('destinationRef') destination: ElementRef;
  @ViewChild('caConfirmDialog', { static: true }) caConfirmDialog: TemplateRef<any>;

  isBlockedActivity = false;
  lblBlockedActivity = environment.lblBlockedActivity;
  constructor(
    public adhesionService: AdhesionService,
    public dqeService: DqeService,
    public staticService: StaticInfoService,
    private router: Router,
    private titleService: Title,
    private fb: FormBuilder,
    public dialog: MatDialog,
    public tagService: TagService,
    private scrollToService: ScrollToService,
    private errorService: ErrorService,
    private sharedsettingService:SharedSettingService,
    private smartPushService: SmartPushService

    ) {
      this.titleService.setTitle('mon entreprise - GS1 France');
    }

  async ngOnInit(): Promise<void> {
    const [iscrisp]=await Promise.all([this.sharedsettingService.isCripsEnabled()]);

    if(iscrisp)
    this.sharedsettingService.InjectScript();
    const [isNeedhelp]=await Promise.all([this.sharedsettingService.isNeedhelpEnabled()]);
    this.isNeedHelpEnabled =isNeedhelp;
    this.caTranches = this.staticService.caTranchesSub;
    this.canaux = this.staticService.canauxSub;
    this.activities = this.staticService.activitiesSub;
    this.codesProduits = this.staticService.codesProduitsSub;
    this.adhesionService.company = null;
    this.companyForm = this.fb.group({
      name: [this.populateInitialValue('name'), Validators.compose([Validators.required])],
      address: [this.populateInitialValue('address')],
      address2: [this.populateInitialValue('address2'), null],
      codePostal: [this.populateInitialValue('codePostal'), Validators.compose([Validators.required])],
      locality: [this.populateInitialValue('locality'), Validators.compose([Validators.required])],
      country: [this.populateInitialValue('country'), null],
      intraNumber: [this.populateInitialValue('intraNumber'), null],
      siren: [this.populateInitialValue('siren'), Validators.compose([Validators.required])],
      codeNaf: [this.populateInitialValue('codeNaf'), Validators.compose([Validators.required])],
      activity: [this.populateInitialValue('activity'), Validators.required],
      canal: [this.populateInitialValue('canal'), Validators.required],
      canauxSecondaires: [{value: this.populateInitialValue('canauxSecondaires'), disabled: true}, CustomValidators.maxChoicesValidator(3)],
      caTranche: [this.populateInitialValue('caTranche'), Validators.required]
    });
    this.company$ = concat(
      of([]),
      this.companyInput$.pipe(
        distinctUntilChanged(),
        tap((value: any) => {
          if (value && value.length > 2)
            this.companyLoading = true
          else this.companyLoading = false
        }),
        debounceTime(500),
        switchMap(term => !!term && term.length > 2 ? this.dqeService.searchCompany(term).pipe(
          tap(() => this.companyLoading = false)
        ): of([]))
      )
    )
    this.companyForm.get('canal').valueChanges.subscribe(
      (value) => this.updateCanauxSecondaires(value)
    );
    this.companyForm.get('activity').valueChanges.subscribe( (id: string) =>
    {
      this.setMessageActivity(id);
      this.companyForm.get('caTranche').setValue(this.companyForm.get('caTranche').value);
    });

    this.companyForm.get('caTranche').valueChanges
      .pipe(
        switchMap((value: string) => {
          if (!this.isBlockedActivity && value && this.selectedCompany) {
            this.selectedCompany.caTranche = value;
            return value  ? this.adhesionService.getTarif(value, this.selectedCompany.codePostal).pipe() : null;
          }
          return of(null);
        })
      ).subscribe((tarif: TarifData) => {
        this.setPrice(tarif);
      });

    if (this.adhesionService.company) {
      this.companySelected = true;
      this.selectedCompany = this.adhesionService.company;
      if (this.selectedCompany.caTranche) {
        this.adhesionService.getTarif(this.adhesionService.company.caTranche, this.selectedCompany.codePostal).subscribe(
          tarif => this.setPrice(tarif)
        );
      }
      if (this.selectedCompany.activity) {
        this.setMessageActivity(this.selectedCompany.activity);
      }
      if (this.selectedCompany.activate !== undefined && this.selectedCompany.activate === false) {
        this.shouldEnterProduct(true);
      }
    }
    this.smartPushService.smartPushUpdate(['adhesion-page-2-4544']);
  }

  shouldEnterProduct(should: boolean) {
    this.showProduct = should;
    if (should === true) {
      this.companyForm.addControl('productId', new FormControl(null, Validators.required));
    } else {
      this.companyForm.removeControl('productId');
    }
    this.companyForm.updateValueAndValidity();
  }

  setMessageActivity(id: string) {
    if (!id) {
      return;
    }
    for (const activity of this.staticService.activities) {
      if (activity.id === id) {
        if (activity.info === this.lblBlockedActivity)
        {
        this.isBlockedActivity = true;
        this.activityLabel = activity.label && activity.label.length > 0 ? activity.label : '';
        this.activityMessage = '';
        }
        else
        {
        this.activityMessage = activity.info && activity.info.length > 0 ? activity.info : '';
        this.isBlockedActivity = false;
        }
      }
    }
  }

  modifyActivity(offset: number = 300) {
    offset = this.destination.nativeElement.offsetTop;
    if (this.activitySelectList) {
      this.activitySelectList.focus();
    }
    const config: ScrollToConfigOptions = {
      offset
    };

    this.scrollToService.scrollTo(config);
  }

  saveAndSendLeavingTag(){
    this.tagService.sendTagWithStep('Leave funnel confirmation');
    this.adhesionService.saveAndExit(2);
    this.adhesionService.removeProspect();
  }

  setPrice(tarif: TarifData) {
    if (!tarif) {
      return;
    }
    this.tarifInfo = tarif;
    let cat;
    const tranches = this.staticService.caTranches;
    for (const tranche of tranches) {
      if (tranche.id === this.companyForm.get('caTranche').value) {
        cat = tranche.label;
      }
    }
    if (['A', 'B', 'C'].includes(cat)) {
      this.highRangeSelected = true;
    } else {
      this.highRangeSelected = false;
    }
  }

  resetResearch(noTag?: boolean) {
    this.researchField.reset();
    this.companyForm.reset();
    this.companyForm.get('activity').reset();
    this.companyForm.get('canal').reset();
    this.companyForm.get('canauxSecondaires').reset();
    this.companyForm.get('caTranche').reset();
    this.shouldEnterProduct(false);
    this.tarifInfo = null;
    this.showIsResigned = false;
    this.showIsDoubtful = false;
    this.selectedCompany = null;
    this.companySelected = false;
    if (!noTag) {
      this.tagService.sendTagWithLabel('Click', 'Effectuer une nouvelle recherche');
    }
  }

  displayError(field: string, error: string): boolean {
    return CustomValidators.displayError(this.companyForm, field, error);
  }

  updateCanauxSecondaires(value: string) {
    this.canauxSecond = [];
    for (const canal of this.staticService.canaux) {
      if (canal.id !== value) {
        this.canauxSecond.push(canal);
      }
    }
    this.companyForm.get('canauxSecondaires').reset();
    if (value)
      this.companyForm.get('canauxSecondaires').enable();
  }

  populateInitialValue(name): any | null {
    /*if (this.adhesionService.company) {
      return this.adhesionService.company[name];
    }*/
    return null;
  }

  companyChosen($event: CompanyData) {
    if (!$event.name) {
      return;
    }
    const labelSelected = $event.name;
    this.dqeService.searchCompanyInfo($event.searchReference).subscribe((company: Company) => {
      if (!labelSelected.includes(company.name)) {
        this.resetResearch(true);
        this.errorService.handleError('Erreur technique, veuillez sélectionner à nouveau votre entreprise', true);
        return;
      }
      this.adhesionService.company = company;
      this.selectedCompany = company;
      this.companySelected = true;
      if (!company.activate) {
        this.shouldEnterProduct(true);
      }
      for (const key in this.selectedCompany) {
        if (this.companyForm.get(key) && this.companyForm.get(key) !== null) {
          this.companyForm.get(key).setValue(this.selectedCompany[key]);
          this.companyForm.get(key).markAsDirty();
          this.companyForm.get(key).updateValueAndValidity();
        }
      }

      if (company.isResigned) {
        if(company.isDoubtful) {
          this.showIsDoubtful = true;
        } else {
          this.showIsResigned = true;
        }
        
      }
      if (company.alreadyExist) {
        this.companyForm.setErrors({alreadyExists: true});
      } else {
        this.companyForm.setErrors({alreadyExists: false});
      }
    });
  }

  displayCompleteOption(id) {
    if (!id) {
      return '';
    }
    const index = this.filteredResearch.findIndex(option => option.searchReference === id);
    return this.filteredResearch[index].name;
  }

  saveAndProceed() {
    if (!this.companyForm.valid) {
      for (const field in this.companyForm.controls) {
        if (this.companyForm.controls.hasOwnProperty(field)) {
          this.companyForm.get(field).markAsDirty();
        }
      }
      return;
    }
    this.tagService.sendTagWithLabel('Validation', 'Etape Suivante');
    this.adhesionService.company = this.companyForm.value as Company;
    this.adhesionService.saveAndProceed(2).subscribe((nok) => {
      if (!nok) {
        this.dialog.closeAll();
        this.router.navigate([`mes-besoins/${this.adhesionService.id}`]);
      }
    });
  }
  saveOrConfirmCA() {
    if (!this.companyForm.valid) {
      for (const field in this.companyForm.controls) {
        if (this.companyForm.controls.hasOwnProperty(field)) {
          this.companyForm.get(field).markAsDirty();
        }
      }
      return;
    }
     else if (this.isBlockedActivity) {
      this.dialog.open(this.confirmDialog);
      return;
    }
    else {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.maxWidth = "850px"
      this.dialog.open(this.caConfirmDialog, dialogConfig);
    }
  }

  correctCA() {
    this.dialog.closeAll();
  }

}
