import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root'
  })
  export class SmartPushService {


    smartPushUpdate(tags: string[]) {
        window.dispatchEvent(
            new CustomEvent('STPUSHUpdate', {
              detail: {
                filters: {
                  tags: tags,
                  thematics: [],
                },
              },
            }),
          );
    }
  }