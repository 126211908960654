import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AbstractControl, AsyncValidatorFn, ValidationErrors } from '@angular/forms';
import { Router } from '@angular/router';
import { OAuthService } from 'angular-oauth2-oidc';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { StateMembership } from 'src/app/models/StateMembership/SateMembership';
import { authConfig } from 'src/config/auth.config';
import { environment } from 'src/environments/environment';
import { AdhesionService } from '../adhesion/adhesion.service';
import { ErrorService } from '../error/error.service';
import { LoaderService } from '../loader/loader.service';
import jwt_decode from 'jwt-decode';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  state: StateMembership;
  hasContact = false;
  user: boolean;

  constructor(private http: HttpClient,
              public oauthService: OAuthService,
              private loader: LoaderService,
              public adhesionService: AdhesionService,
              public errorService: ErrorService, ) {}

  public redirectConnect1(force: boolean) {
    this.refreshStateMembership();
    if (this.state) {
      if (force) {
        authConfig.redirectUri = authConfig.redirectUri;
        authConfig.customQueryParams = {
          prompt: 'login',
          Email : this.state.newMembershipEmail,
        };
      } else {
        authConfig.customQueryParams = {
          Email : this.state.newMembershipEmail
        };
      }
      this.loader.registerWait('redirectConnect1');
      this.oauthService.configure(authConfig);
      this.oauthService.initImplicitFlow();
    }
  }

  public refreshStateMembership(): StateMembership {
    if (!this.state){
        this.state = JSON.parse(window.sessionStorage.getItem('stateMembership'));
    } else {
        window.sessionStorage.setItem('stateMembership', JSON.stringify(this.state));
    }
    return this.state;
  }

  public logOut() {
    sessionStorage.clear();
  }
  public logOutOnce() {
    if (sessionStorage.length > 0) 
      sessionStorage.clear();
    if (localStorage.length > 0) 
      localStorage.clear();
   }
  public getLogoutUrl(): string {
    if (!this.oauthService || !this.oauthService.hasValidAccessToken()) {
      return environment.ssoLogoutReturnUrl;
    }
    return `${environment.ssoLogoutUrl}?id_token_hint=${this.oauthService.getIdToken()}&post_logout_redirect_uri=${encodeURIComponent(environment.ssoLogoutReturnUrl)}`;
  }

  public login(force?: boolean) {
    this.refreshStateMembership();

    if (this.state && this.state.idProspect) {
      authConfig.scope = authConfig.scope + ' isProspect' ;
    } else {
      authConfig.scope = authConfig.scope + ' isMembership';
    }
    this.redirectConnect1(force);
  }

  public loginImplicit(loginInfo: StateMembership) {
    this.state = loginInfo;
    this.refreshStateMembership();
  }

  getLoginInfo(): AsyncValidatorFn {
    return (control: AbstractControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> => {
      if (
        this.user && this.adhesionService.user.email === control.value ||
        this.loader.getState() && (this.loader.getWaitingList().includes('saveAndProceed') || this.loader.getWaitingList().includes('implicitLogin'))
      ) {
        return of(null);
      }

      return this.http.get(`${environment.baseUrl}/api/prospects/login?email=${encodeURIComponent(control.value)}`, {observe: 'response'})
        .pipe(
            map((response: HttpResponse<{id: string, email: string, isProspectMemberShip: boolean, isNotFullSsoAccount: boolean }>) => {
              if (response.status === 204) {
                this.adhesionService.isNotParcoursExists = true;
                return null;
              }
              const data = response.body;
              this.state = new StateMembership(data.email, data.id);
              if (data && data.isNotFullSsoAccount)
              {
                this.state.isNotFullSsoAccount = true;
                return null;
              }
              this.refreshStateMembership();

              if (!data.id && data.isProspectMemberShip) {
                return {memberShipAndProspect: true};
              }

              if (!data.id) {
                this.state.isEmailsExist = true;
                return {emailExists: true};
              }
              else{
                this.adhesionService.isProspect = true;
              }
              if (data.id === this.adhesionService.id) {
                return null;
              }
              this.state.idProspect = data.id;
              return {parcoursExists: true};
            }),
            catchError(err => {
              this.adhesionService.errorService.handleError(err);
              return of({errorServer: true});
            })
        );
    };
  }



  getLoginInfofromecli(email:string,id:string) {
    

    this.state = new StateMembership(email, id);
    
    this.refreshStateMembership();
  }
  public deleteCacheProspect() {
    sessionStorage.removeItem('gs1Prospect');
  }

  private getCurrentUserFromToken(): string {
    if (this.oauthService.hasValidAccessToken()) {
      const token: any = jwt_decode(this.oauthService.getAccessToken());
      return token.email;
    }
    return null;
  }

  public refreshUserMembership(): StateMembership {
    this.refreshStateMembership();

    if (this.state && !this.oauthService.hasValidAccessToken()) {
      this.redirectConnect1(false);
      return this.state;
    }

    const user = this.getCurrentUserFromToken();

    if (user ) {
      if(this.state == null) {
        this.state = new StateMembership(user, null, null)
      }
      this.state.activeMembershipEmail = user;
      if (this.state.newMembershipEmail && this.state.newMembershipEmail.toUpperCase() === user.toUpperCase()) {
        this.state.isActiveMembership = true;
      } else {
        this.state.isActiveMembership = false;
      }
      this.refreshStateMembership();
    }

    return this.state;
  }


}
