import { ChangeDetectorRef, Component, ElementRef, HostListener, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormGroup, FormGroupDirective, FormBuilder, Validators, AbstractControl, FormControl } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { AdhesionService } from 'src/app/services/adhesion/adhesion.service';
import { startWith, debounceTime, tap, finalize, switchMap, delay } from 'rxjs/operators';
import { Router } from '@angular/router';
import { CustomValidators } from 'src/app/validators/custom-validators/custom-validators';
import { Observable, of, Subscription } from 'rxjs';
import { TarifData } from 'src/app/models/tarif-data/tarif-data';
import { TagService } from 'src/app/services/tagService/tag-service.service';
import { LoginService } from 'src/app/services/login/login.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { EnumType } from 'typescript';
import { ScrollToConfigOptions, ScrollToService } from '@nicky-lenaers/ngx-scroll-to';
import { SharedSettingService } from 'src/app/services/sharedsetting/sharedsetting.service';
import { SmartPushService } from 'src/app/services/smartPush/smartPush.service';

@Component({
  selector: 'app-need-infos',
  templateUrl: './need-infos.component.html',
  styleUrls: ['./need-infos.component.scss']
})
export class NeedInfosComponent implements OnInit, OnDestroy {

  needForm: FormGroup;
  needFormGln: FormGroup;

  @ViewChild('formNeedGln') formNeedGln: FormGroupDirective;
  @ViewChild('formNeed') formNeed: FormGroupDirective;
  @ViewChild('noNeedLeaveDialog', { static: true }) leaveModal: TemplateRef<any>;
  @ViewChild('needFormGroup') needFormGroupRef: ElementRef<any>;
  @ViewChild('confirmDialog', { static: true }) confirmDialog: TemplateRef<any>;

  range = 0;
  rangeGln = 0;
  realRange = 0;
depassement = false;
  isLoading = false;
  tarif: Observable<TarifData> = null;
  infoBlockToShow = '';
  showNeeds = false;
  searchCodeResult = false;
  codeFound = '';
  leaving = false;
  subscriptions: Subscription[] = [];
  nbCode : number;
  nbLocation : number
  codesRange = [
    {id: 4, key: 1000000, value: '1000000'},
    {id: 3, key: 100000, value: '100000'},
    {id: 2, key: 10000, value: '10000'},
    {id: 1, key: 1000, value: '1000'},
    {id: 0, key: 100, value: '100'}
  ];

  checkNames = {
    isBrandOwner: 'Votre marque propre',
    isBrandTiers: 'Marque tierce',
    isWithoutBrand: 'Sans marque'
  };
  
  checkedToOpen = false;
  checkedToOpenGln = false
  isNeedHelpEnabled=false;

  constructor(public adhesionService: AdhesionService, private fb: FormBuilder, private router: Router,
              private changeDetector: ChangeDetectorRef, private titleService: Title, private tagService: TagService,
              private loginService: LoginService,  public dialog: MatDialog,
              private scrollToService: ScrollToService,
              private sharedsettingService:SharedSettingService,
              private cdRef: ChangeDetectorRef,
              private smartPushService: SmartPushService
              ) {
    this.titleService.setTitle('mes besoins - GS1 France');
  }

  ngOnDestroy(): void {
    this.sendBrandTags();
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  async ngOnInit(): Promise<void> {
    const [iscrisp]=await Promise.all([this.sharedsettingService.isCripsEnabled()]);

    if(iscrisp)
    this.sharedsettingService.InjectScript();
    const [isNeedhelp]=await Promise.all([this.sharedsettingService.isNeedhelpEnabled()]);
    this.isNeedHelpEnabled =isNeedhelp;

    this.tarif = this.adhesionService.tarifUpdated;
    this.needForm = this.fb.group({
      need: [
        this.populateInitialValue('need'),
        Validators.compose([Validators.required, Validators.max(999999), Validators.min(1), Validators.pattern(/^\d+$/)])],
      brandOwnership : new FormGroup({
        isBrandOwner : new FormControl(this.populateInitialValue('isBrandOwner', 'brandOwnership'), null),
        isBrandTiers : new FormControl(this.populateInitialValue('isBrandTiers', 'brandOwnership'), null),
        isWithoutBrand : new FormControl(this.populateInitialValue('isWithoutBrand', 'brandOwnership'), null)
      }, CustomValidators.hasBeenCheckedForAtLeast(1)),
      isMaker: [null, null],
      codeResearchedAndFound : [null, null]
    });

    this.needFormGln = this.fb.group({
      glnNeed: [
        null,
        Validators.compose([Validators.required, Validators.max(999999), Validators.min(1), Validators.pattern(/^\d+$/)])]});


    

    this.subscriptions.push(
      this.needForm.get('need').valueChanges.pipe(
        tap((input) => {
          if (this.checkedToOpen && this.needForm.get('need').valid && !isNaN(Number(input))) {
            this.nbCode=Number(input);
            this.isLoading = true;
          } else {
            this.range = 0;
            this.nbCode=undefined;

          }
        }),
        startWith(''),
        debounceTime(500),
        switchMap(value => this.checkedToOpen && this.needForm.get('need').valid && !isNaN(Number(value)) ? this.getCodeRanges(value).pipe(
          finalize(() => {
            this.isLoading = false;
          })
        ) : [])
      ).subscribe( (range: any) => {
        this.isLoading = false;
        this.range = range;
        this.getRealRange();

      })
    );

    this.subscriptions.push(
      this.needFormGln.get('glnNeed').valueChanges.pipe(
        tap((input) => {
          if (this.checkedToOpenGln && this.needFormGln.get('glnNeed').valid && !isNaN(Number(input))) {
            this.nbLocation= Number(input);
            this.isLoading = true;
          } else {
            this.rangeGln = 0;
            this.nbLocation=undefined;

          }
        }),
        startWith(''),
        debounceTime(500),
        switchMap(value => this.checkedToOpenGln && this.needFormGln.get('glnNeed').valid && !isNaN(Number(value)) ? this.getCodeRanges(value).pipe(
          finalize(() => {
            this.isLoading = false;
          })
        ) : [])
      ).subscribe( (rangeGln: any) => {
        this.isLoading = false;
        this.rangeGln = rangeGln;
        this.getRealRange();
      })
    );


    


    this.subscriptions.push(
      this.needForm.get('brandOwnership').valueChanges.subscribe(() => this.selectGoodInfoBlock())
    );

    this.subscriptions.push(this.needForm.get('codeResearchedAndFound').valueChanges.subscribe(value => {
      if (value === true) {
        this.openLeaveModal();
      }
    }))
    this.smartPushService.smartPushUpdate(['adhesion-page-3-4545']);
  }


  
  getRealRange():void{
    if (this.checkedToOpen && this.checkedToOpenGln){
      this.realRange = this.range>this.rangeGln ? this.range : this.rangeGln;
      if(this.needForm.get('need').value + this.needFormGln.get('glnNeed').value >= this.realRange)
        this.depassement = true;
      else
        this.depassement = false;
      this.cdRef.detectChanges();
    }
    else if (this.checkedToOpen && !this.checkedToOpenGln)
      this.realRange = this.range;
    else if (!this.checkedToOpen && this.checkedToOpenGln)
      this.realRange = this.rangeGln;
    else
      this.realRange = 0;
  }


  @HostListener('window:beforeunload', ['$event'])
  closingBrowser() {
    this.sendBrandTags();
  }


  changingcheck(val: boolean) {
    this.checkedToOpen = val;
    if(!val)
    this.nbCode=undefined;
    else
    this.nbCode=this.needForm.get('need').value ;
    if(!this.checkedToOpenGln)
    {
      this.nbLocation=undefined;
    }
    this.getRealRange();
  }
  changingcheckGln(val: boolean) {
    this.checkedToOpenGln = val;
    if(!val)
    this.nbLocation=undefined
    else
    this.nbLocation=this.needFormGln.get('glnNeed').value
    if(!this.checkedToOpen)
    {
      this.nbCode=undefined;
    }
    
    this.getRealRange();
  }
  
  sendBrandTags() {
    const brandSelected = this.needForm.get('brandOwnership').value;
    for (const key in brandSelected) {
      if (brandSelected[key] === true) {
        this.tagService.sendTagWithCategory('Click', `${this.checkNames[key]} - cochage`, 'Step 3' );
        if (this.leaving === true) {
          this.tagService.sendTagWithLabel('Validation', `${this.checkNames[key]} - GTIN retrouvé - Quitter le parcours`);
        }
      }
    }
  }

  isMaker(maker: boolean) {
    this.setShowNeed(maker);
    this.setSearchCodeResult(!maker);
    this.tagService.sendTagWithLabel('Click', `Sans marque - ${maker ? 'Oui' : 'Non'}`);
  }

  selectGoodInfoBlock(): void {
    const checkBoxesValue = this.needForm.get('brandOwnership').value;

    // There is no way to reset a radio button except by removing it from the DOM...
    this.infoBlockToShow = '';
    this.setShowNeed(false);
    this.setSearchCodeResult(false);
    this.needForm.get('isMaker').setValue(null);
    this.needForm.get('codeResearchedAndFound').setValue(null);
    this.changeDetector.detectChanges();

    if (checkBoxesValue.isBrandOwner) {
      this.infoBlockToShow += 'Owner';
      if (checkBoxesValue.isWithoutBrand || checkBoxesValue.isBrandTiers) {
        this.infoBlockToShow += 'And';
      }
      this.setShowNeed(true);
    }
    if (checkBoxesValue.isWithoutBrand) {
      this.infoBlockToShow += 'Without';
      if (checkBoxesValue.isBrandTiers) {
        this.infoBlockToShow += 'And';
      }
    }
    if (checkBoxesValue.isBrandTiers) {
      this.infoBlockToShow += 'Other';
    }
  }

  setShowNeed(state: boolean): void {
    this.showNeeds = state;
  }

  setSearchCodeResult(state: boolean): void {
    this.searchCodeResult = state;
  }

  populateInitialValue(name, parent?): any | null {
    if (parent && this.adhesionService.company && this.adhesionService.company[parent] && this.adhesionService.company[parent][name]) {
      return this.adhesionService.company[parent][name];
    } else if (this.adhesionService.company && this.adhesionService.company[name]) {
      return this.adhesionService.company[name];
    }
    if (parent) {
      return false;
    }
    return null;
  }

  getCodeRanges(value): Observable<any> {
    const ask = Number(value);
    let potentialRange;
    for (const row of this.codesRange) {
      if (ask < row.key) {
        potentialRange = row.value;
      }
    }
    return of(potentialRange);
  }

  displayError(field: string, error: string): boolean {
    return CustomValidators.displayError(this.needForm, field, error);
  }

  displayErrorGln(field: string, error: string): boolean {
    return CustomValidators.displayError(this.needFormGln, field, error);
  }
  saveAndProceed() {
    if (this.realRange !== 0 && (this.checkedToOpen || this.checkedToOpenGln) && !this.isLoading) {
      this.tagService.sendTagWithLabel('Validation', 'Je valide mon besoin');
      this.adhesionService.company.need = this.checkedToOpen ?  this.needForm.get('need').value : 0;
      this.adhesionService.company.locationCount = this.checkedToOpenGln ?  this.needFormGln.get('glnNeed').value : 0;
      this.adhesionService.company.brandOwnerShip = this.checkedToOpen ? this.needForm.get('brandOwnership').value : null;

      this.adhesionService.saveAndProceed(3).subscribe((nok) => {
        if (nok) {
          this.dialog.closeAll();
          this.router.navigate([`paiement-adhesion/${this.adhesionService.id}`]);
        }
      });
    }
  }

  sendLeavingTag() {
    this.leaving = true;
    this.loginService.logOut();
  }

  openLeaveModal() {
    this.dialog.open(this.leaveModal);
  }

  displayTooltip(event: any) {
    const target: HTMLElement = event.target || event.srcElement || event.currentTarget;
    target.classList.toggle('clicked');
  }

  scrollToNeed() {
    const offset = this.needFormGroupRef.nativeElement.offsetTop;
    const config: ScrollToConfigOptions = {
      offset
    };
    this.scrollToService.scrollTo(config);
  }

  saveOrConfirmNeed() {
    if (this.realRange !== 0 && (this.checkedToOpen || this.checkedToOpenGln) && !this.isLoading) {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.maxWidth = "850px"
      this.dialog.open(this.confirmDialog, dialogConfig);
    }
  }
  correctNeed() {
    this.dialog.closeAll();
  }
  get showSummary() {
    if((!this.checkedToOpen && !this.checkedToOpenGln) || (this.checkedToOpen && !this.needForm.valid) || (this.checkedToOpenGln && !this.needFormGln.valid)) {
      return false;
    }
    return true;
  }

}
