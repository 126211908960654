import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { GoogleTagManagerService } from 'angular-google-tag-manager';

import { Prospect } from 'src/app/models/prospect/prospect';
import { TarifData } from 'src/app/models/tarif-data/tarif-data';
import { StaticInfoService } from '../static-info/static-info.service';
@Injectable({
  providedIn: 'root'
})
export class TagService {
  constructor(private router: Router, public gtmService: GoogleTagManagerService, public staticService: StaticInfoService) { }

  sendTagWithStep(action: string) {
    this.gtmService.pushTag({
      event: 'interaction',
      category: 'All funnel',
      action,
      label: `Step ${this.getCurrentStep()}`
    });
  }

  sendTagWithLabel(action: string, label: string) {
    this.gtmService.pushTag({
      event: 'interaction',
      category: `Step ${this.getCurrentStep()}`,
      action,
      label
    });
  }

  sendTagWithCategory(action: string, label: string, category: string) {
    this.gtmService.pushTag({
      event: 'interaction',
      category,
      action,
      label
    });
  }

  sendProductDataLayer(prospect: Prospect, tarif: TarifData) {
    const dataLayer = this.gtmService.getDataLayer();

    const nameProduct = this.staticService.caTranchesSub.subscribe(
    (list) => {
      const tranche = list.find((e) => e.id === prospect.company.caTranche);
      dataLayer.push({
        event: 'purchase',
        ecommerce: {
          purchase: {
            actionField: {
              id: prospect.id,
              affiliation: 'Parcours en ligne',
              revenue: tarif.totalAmountTTC,
              tax: tarif.vaT_Amount,
              shipping: '',
              coupon: ''
            },
            products: [
             {
              name: tranche.info,
              id: prospect.company.caTranche,
              price: tarif.membershipFeeHT,
              brand: 'GCP',
              category: ( prospect.company.need < 1000 || prospect.company.locationCount < 1000) ? 'GCP 9/10' : 'GCP 6/7/8',
              quantity: prospect.company.need > prospect.company.locationCount ? prospect.company.need : prospect.company.locationCount
             }]
          }
        }
      });
    });


  }

  getCurrentStep() {
    if (this.router.routerState.snapshot.url.includes('mes-informations')) {
      return '1';
    } else if (this.router.routerState.snapshot.url.includes('mon-entreprise')) {
      return '2';
    } else if (this.router.routerState.snapshot.url.includes('mes-besoins')) {
      return '3';
    } else if (this.router.routerState.snapshot.url.includes('paiement-adhesion')) {
      return '4';
    } else if (this.router.routerState.snapshot.url.includes('confirmation-adhesion')) {
      return '5';
    }
    return 'unknown';
  }
}
