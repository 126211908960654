
  
  <div class="main-div" [ngClass]="{'standard-header': !isWineAndSpirits, 'wine-and-spirits-header': isWineAndSpirits}">
    <button gs1Button [appearance]="'outlined'" class="quitApp" [color]="'white'" id="@@quitApp" i18n (click)="openLeaveModal()">Quitter</button>


    <h1 id="@@appDescription" i18n>{{headerText}}</h1>
    <ng-template #confirmLeaveDialog>
      <h3 matDialogTitle>Quitter le parcours d'adhésion</h3>
      <mat-dialog-content>
        <p class="gs1-little">Vous allez être redirigé sur le site web de GS1 France. <ng-container *ngIf="!isDone()">Vous pouvez à tout moment finaliser votre adhésion en reprenant votre parcours.</ng-container></p>
      </mat-dialog-content>
      <mat-dialog-actions>
          <button gs1Button [appearance]="'outlined'" matDialogClose>Annuler</button>
          <button (click)="sendLeavingTag()" gs1Button matDialogClose cdk-focus-start >Je confirme</button>
      </mat-dialog-actions>
    </ng-template>
  </div>

