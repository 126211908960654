export class User {
  email: string;
  lastname: string;
  firstname: string;
  phone1: string;
  phone2?: string;
  functionId: string;
  password: string;
  genderId: string;
  isLogged: boolean;
  contactId: string;
  loginId: string;

  constructor(userData) {
    this.email = userData.email;
    this.functionId = userData.functionId;
    this.lastname = userData.lastname;
    this.firstname = userData.firstname;
    this.phone1 = userData.phone1;
    this.phone2 = userData.phone2 || null;
    this.password = userData.password;
    this.genderId = userData.genderId;
    this.contactId = userData.id;
    this.loginId = userData.loginId;

  }
}
