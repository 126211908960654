<div class="page-content">
  <app-support-sticker   [currentForm]="null"></app-support-sticker>
  <ng-container *ngIf="showing === 'errorSepa'">
    <gs1-alert class="gs1-spacer-3" [type]="'danger'" [title]="'La transaction a échoué'">
      <p class="gs1-little">Le prélèvement pour votre première année d'adhésion n'a pas fonctionné.</p>
      <p class="gs1-little">Nous vous invitons à contacter le Service Client au 01.40.22.18.00 du lundi au vendredi de 8h30 à 17h30 pour finaliser le paiement de votre adhésion.</p>
    </gs1-alert>
  </ng-container>

  <ng-container *ngIf="showing === 'GCP6-8'">
    <gs1-alert class="gs1-spacer-3" [type]="'success'" [title]="'Vous venez d\'adhérer à GS1 France !'">
      <p class="gs1-little">Un email récapitulatif de votre adhésion vient de vous être envoyé à l'adresse : {{adhesionService.user.email}}</p>
    </gs1-alert>
    <div class="wait">
      <i class="bi bi-stopwatch-fill gs1-color-blue"></i>
      <p>
        Vos codes seront accessibles dans un délai de 48h ouvrées après traitement de vos informations. <br/>
        Vous serez tenu informé par mail dès qu'elles seront validées.
      </p>
    </div>
  </ng-container>

  <ng-container *ngIf="showing === 'GCP9-10'">
    <gs1-alert class="gs1-spacer-3" [type]="'success'" [title]="'Vous venez d\'adhérer à GS1 France !'"></gs1-alert>
    <div class="wait">
      <i class="bi bi-stopwatch-fill gs1-color-blue"></i>
      <p>
        Un email récapitulatif de votre adhésion vous sera envoyé dans un délai de 5 minutes.<br/>
        Dans ce mail, un lien vous permettra d'accéder à CodeOnline, l'application GS1 France pour créer vos codes.
      </p>
    </div>
  </ng-container>
  
  
  <div id="services">
    <mat-divider></mat-divider>
    <h3>Découvrez dès maintenant nos services</h3>
    <div class="columns autres-produits">
      <div class="autre-produit gs1-card">
        <span>Services premium</span>
        <div class="image" id="premium"></div>
        <div>
          <a href="https://www.gs1.fr/Nos-services/Les-Services-Premium" gs1Button [appearance]="'outlined'">En savoir plus</a>
        </div>
      </div>
      <div class="autre-produit gs1-card">
        <span>Nos publications</span>
        <div class="image" id="publications"></div>
        <div><a href="https://www.gs1.fr/nos-publications" gs1Button [appearance]="'outlined'">En savoir plus</a></div>
      </div>
      <div class="autre-produit gs1-card">
        <span>Les groupes de travail</span>
        <div class="image" id="groupes"></div>
        <div><a href="https://www.gs1.fr/groupes-travail" gs1Button [appearance]="'outlined'">En savoir plus</a></div>
      </div>
    </div>
  </div>
</div>
