import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { Observable, throwError, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { ExpiredTokenPopupComponent } from '../components/expired-token-popup/expired-token-popup.component';
import { PopupService } from '../services/popup/popup.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor(
    private oauthService: OAuthService,
    private dialog: MatDialog,
    private popupService: PopupService
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = this.oauthService.getAccessToken();

    if (token) {
      const tokenExpirationDate = this.oauthService.getAccessTokenExpiration();
      const currentTime = new Date();
      const expirationDate = typeof tokenExpirationDate === 'number'
        ? new Date(tokenExpirationDate)
        : tokenExpirationDate;

      //  if (expirationDate && currentTime > expirationDate) {
      //   this.dialog.open(ExpiredTokenPopupComponent, {
      //       disableClose: true
      //     });
      //     this.popupService.setPopupOpen(true); 
      //              return of<HttpEvent<any>>();   
      //   }
    }

     req = req.clone({
      setHeaders: {
        'Content-Type': 'application/json; charset=utf-8',
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });

    //  return next.handle(req).pipe(
    //   catchError((err: any) => {
    //      if (err instanceof HttpErrorResponse && err.status === 401) {
    //         this.dialog.open(ExpiredTokenPopupComponent, {
    //             disableClose: true
    //           });
    //           this.popupService.setPopupOpen(true);
    //                      return of<HttpEvent<any>>();   
    //     }

    //      return throwError(() => err);
    //   })
    // );


    return next.handle(req).pipe(
      catchError(err => {
          return throwError(() => err);
      })
  );
  }
}
