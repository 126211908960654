import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { LoaderService } from '../loader/loader.service';
import { tap, catchError, finalize } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { CallData } from 'src/app/models/call-data/call-data';
import { ErrorService } from '../error/error.service';

@Injectable({
  providedIn: 'root'
})
export class CallcenterService {

  constructor(public http: HttpClient, public loader: LoaderService, public errorService: ErrorService) { }

  askForCall(data: CallData): Observable<any> {
    this.loader.registerWait('callcenter');
    return this.http.post(`${environment.baseUrl}/api/callcenter`, data).pipe(
      catchError(err => {
        this.errorService.handleError(err);
        return of(null);
      }),
      finalize(() => this.loader.endWaiting('callcenter'))
    );
  }
}

