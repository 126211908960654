// The library used to select a country doesn't allow to easily set a value so this file exists
// Look at https://github.com/angular-material-extensions/select-country/issues/11 to see the issue
// An update will be needed once the issue is released

export const COUNTRIES = [
  {
    name: 'Afghanistan',
    alpha2Code: 'AF',
  },
  {
    name: 'Albanie',
    alpha2Code: 'AL',
  }, {
  name: 'Algérie',
  alpha2Code: 'DZ',
  },
  {
    name: 'Samoa américaines',
    alpha2Code: 'AS',
  },
  {
    name: 'Andorre',
    alpha2Code: 'AD',
  },
  {
    name: 'Angola',
    alpha2Code: 'AO',
  },
  {
    name: 'Antigua-et-Barbuda',
    alpha2Code: 'AG',
  },
  {
    name: 'Argentine',
    alpha2Code: 'AR',
  },
  {
    name: 'Arménie',
    alpha2Code: 'AM',
  },
  {
    name: 'Australie',
    alpha2Code: 'AU',
  },
  {
    name: 'Autriche',
    alpha2Code: 'AT',
  },
  {
    name: 'Azerbaïdjan',
    alpha2Code: 'AZ',
  },
  {
    name: 'Bahamas',
    alpha2Code: 'BS',
  },
  {
    name: 'Bahreïn',
    alpha2Code: 'BH',
  },
  {
    name: 'Bangladesh',
    alpha2Code: 'BD',
  },
  {
    name: 'Barbados',
    alpha2Code: 'BB',
  }, {
  name: 'Belarus',
  alpha2Code: 'PAR',
  },
  {
    name: 'Belgique',
    alpha2Code: 'BE',
  },
  {
    name: 'Belize',
    alpha2Code: 'BZ',
  },
  {
    name: 'Bénin',
    alpha2Code: 'BJ',
  },
  {
    name: 'Bermuda',
    alpha2Code: 'BM',
  },
  {
    name: 'Bhoutan',
    alpha2Code: 'BT',
  },
  {
    name: 'Bolivie',
    alpha2Code: 'BO',
  },
  {
    name: 'Bonaire, Saint-Eustache et Saba',
    alpha2Code: 'BQ',
  },
  {
    name: 'Bosnie-Herzégovine',
    alpha2Code: 'BA',
  },
  {
    name: 'Botswana',
    alpha2Code: 'BW',
  },
  {
    name: 'Île Bouvet',
    alpha2Code: 'BV',
  },
  {
    name: 'Brésil',
    alpha2Code: 'BR',
  },
  {
    name: 'Brunei',
    alpha2Code: 'BN',
  },
  {
    name: 'Bulgarie',
    alpha2Code: 'BG',
  },
  {
    name: 'Burkina Faso',
    alpha2Code: 'BF',
  },
  {
    name: 'Burundi',
    alpha2Code: 'BI',
  },
  {
    name: 'Cabo Verde',
    alpha2Code: 'CV',
  },
  {
    name: 'Cambodge',
    alpha2Code: 'KH',
  },
  {
    name: 'Cameroun',
    alpha2Code: 'CM',
  },
  {
    name: 'Canada',
    alpha2Code: 'CA',
  },
  {
    name: 'République centrafricaine',
    alpha2Code: 'CF',
  },
  {
    name: 'Chad',
    alpha2Code: 'TD',
  },
  {
    name: 'Chili',
    alpha2Code: 'CL',
  },
  {
    name: 'Chine',
    alpha2Code: 'CN',
  },
  {
    name: 'Colombie',
    alpha2Code: 'CO',
  },
  {
    name: 'Congo (République démocratique du)',
    alpha2Code: 'CD',
  },
  {
    name: 'Congo (République du)',
    alpha2Code: 'CG',
  },
  {
    name: 'Costa Rica',
    alpha2Code: 'CR',
  },
  {
    name: 'Croatie',
    alpha2Code: 'HR',
  },
  {
    name: 'Cuba',
    alpha2Code: 'CU',
  }, {
  name: 'Chypre',
  alpha2Code: 'CY',
  },
  {
    name: 'Czechia',
    alpha2Code: 'CZ',
  },
  {
    name: 'Côte d \' Ivoire ',
    alpha2Code: 'CI',
  },
  {
    name: 'Danemark',
    alpha2Code: 'DK',
  },
  {
    name: 'Djibouti',
    alpha2Code: 'DJ',
  },
  {
    name: 'Dominique',
    alpha2Code: 'DM',
  },
  {
    name: 'République dominicaine',
    alpha2Code: 'DO',
  },
  {
    name: 'Equateur',
    alpha2Code: 'EC',
  },
  {
    name: 'Egypte',
    alpha2Code: 'EG',
  },
  {
    name: 'El Salvador',
    alpha2Code: 'SV',
  },
  {
    name: 'Guinée équatoriale',
    alpha2Code: 'GQ',
  },
  {
    name: 'Erythrée',
    alpha2Code: 'ER',
  },
  {
    name: 'Estonie',
    alpha2Code: 'EE',
  },
  {
    name: 'Eswatini',
    alpha2Code: 'SZ',
  },
  {
    name: 'Ethiopie',
    alpha2Code: 'ET',
  },
  {
    name: 'Fidji',
    alpha2Code: 'FJ',
  },
  {
    name: 'Finlande',
    alpha2Code: 'FI',
  },
  {
    name: 'France',
    alpha2Code: 'FR',
  },
  {
    name: 'France',
    alpha2Code: 'FR-DOM',
  },
  {
    name: 'France',
    alpha2Code: 'FR-TOM',
  },
  {
    name: 'Gabon',
    alpha2Code: 'GA',
  },
  {
    name: 'Gambie',
    alpha2Code: 'GM',
  },
  {
    name: 'Géorgie',
    alpha2Code: 'GE',
  },
  {
    name: 'Allemagne',
    alpha2Code: 'DE',
  },
  {
    name: 'Ghana',
    alpha2Code: 'GH',
  },
  {
    name: 'Grèce',
    alpha2Code: 'GR',
  },
  {
    name: 'Groenland',
    alpha2Code: 'GL',
  },
  {
    name: 'Guadeloupe',
    alpha2Code: 'GP',
  },
  {
    name: 'Grenada',
    alpha2Code: 'GD',
  },
  {
    name: 'Guatemala',
    alpha2Code: 'GT',
  },
  {
    name: 'Guinée',
    alpha2Code: 'GN',
  },
  {
    name: 'Guinée-Bissau',
    alpha2Code: 'GW',
  },
  {
    name: 'Guyana',
    alpha2Code: 'GY',
  },
  {
    name: 'Haïti',
    alpha2Code: 'HT',
  },
  {
    name: 'Honduras',
    alpha2Code: 'HN',
  },
  {
    name: 'Hong Kong',
    alpha2Code: 'HK',
  },
  {
    name: 'Hongrie',
    alpha2Code: 'HU',
  },
  {
    name: 'Islande',
    alpha2Code: 'EST',
  },
  {
    name: 'Inde',
    alpha2Code: 'IN',
  },
  {
    name: 'Indonésie',
    alpha2Code: 'ID',
  },
  {
    name: 'Iran',
    alpha2Code: 'IR',
  },
  {
    name: 'Iraq',
    alpha2Code: 'IQ',
  }, {
  name: 'Irlande',
  alpha2Code: 'IE',
  },
  {
    name: 'Israël',
    alpha2Code: 'IL',
  },
  {
    name: 'Italie',
    alpha2Code: 'IT',
  },
  {
    name: 'Jamaica',
    alpha2Code: 'JM',
  },
  {
    name: 'Japon',
    alpha2Code: 'JP',
  },
  {
    name: 'Jersey',
    alpha2Code: 'JE',
  },
  {
    name: 'Jordan',
    alpha2Code: 'JO',
  },
  {
    name: 'Kazakhstan',
    alpha2Code: 'KZ',
  },
  {
    name: 'Kenya',
    alpha2Code: 'KE',
  },
  {
    name: 'Kiribati',
    alpha2Code: 'KI',
  },
  {
    name: 'Corée (République populaire démocratique de)',
    alpha2Code: 'KP',
  },
  {
    name: 'Corée (République de) \ t',
    alpha2Code: 'KR',
  },
  {
    name: 'Koweït',
    alpha2Code: 'KW',
  },
  {
    name: 'Kirghizistan',
    alpha2Code: 'KG',
  },
  {
    name: 'Laos',
    alpha2Code: 'LA',
  },
  {
    name: 'Lettonie',
    alpha2Code: 'LV',
  },
  {
    name: 'Liban',
    alpha2Code: 'LB',
  },
  {
    name: 'Lesotho',
    alpha2Code: 'LS',
  },
  {
    name: 'Liberia',
    alpha2Code: 'LR',
  },
  {
    name: 'Libye',
    alpha2Code: 'LY',
  },
  {
    name: 'Liechtenstein',
    alpha2Code: 'LI',
  },
  {
    name: 'Lituanie',
    alpha2Code: 'LT',
  },
  {
    name: 'Luxembourg',
    alpha2Code: 'LU',
  },
  {
    name: 'Macao',
    alpha2Code: 'MO',
  },
  {
    name: 'Madagascar',
    alpha2Code: 'MG',
  },
  {
    name: 'Malawi',
    alpha2Code: 'MW',
  },
  {
    name: 'Malaisie',
    alpha2Code: 'MON',
  },
  {
    name: 'Maldives',
    alpha2Code: 'MV',
  },
  {
    name: 'Mali',
    alpha2Code: 'ML',
  },
  {
    name: 'Malte',
    alpha2Code: 'MT',
  },
  {
    name: 'Mauritania',
    alpha2Code: 'MR',
  },
  {
    name: 'Mauritius',
    alpha2Code: 'MU',
  },
  {
    name: 'Mexique',
    alpha2Code: 'MX',
  },
  {
    name: 'Moldova',
    alpha2Code: 'MD',
  },
  {
    name: 'Monaco',
    alpha2Code: 'MC',
  },
  {
    name: 'Mongolie',
    alpha2Code: 'MN',
  },
  {
    name: 'Monténégro',
    alpha2Code: 'ME',
  },
  {
    name: 'Maroc',
    alpha2Code: 'MA',
  }, {
  name: 'Mozambique',
  alpha2Code: 'MZ',
  },
  {
    name: 'Myanmar',
    alpha2Code: 'MM',
  },
  {
    name: 'Namibie',
    alpha2Code: 'NA',
  },
  {
    name: 'Nauru',
    alpha2Code: 'NR',
  },
  {
    name: 'Népal',
    alpha2Code: 'NP',
  },
  {
    name: 'Pays-Bas',
    alpha2Code: 'NL',
  },
  {
    name: 'Nouvelle Calédonie',
    alpha2Code: 'NC',
  },
  {
    name: 'Nouvelle-Zélande',
    alpha2Code: 'NZ',
  },
  {
    name: 'Nicaragua',
    alpha2Code: 'NI',
  },
  {
    name: 'Niger',
    alpha2Code: 'NE',
  },
  {
    name: 'Nigeria',
    alpha2Code: 'NG',
  },
  {
    name: 'Norvège',
    alpha2Code: 'NON',
  },
  {
    name: 'Oman',
    alpha2Code: 'OM',
  },
  {
    name: 'Pakistan',
    alpha2Code: 'PK',
  },
  {
    name: 'Palau',
    alpha2Code: 'PW',
  },
  {
    name: 'Palestine',
    alpha2Code: 'PS',
  },
  {
    name: 'Panama',
    alpha2Code: 'PA',
  },
  {
    name: 'Papouasie-Nouvelle-Guinée',
    alpha2Code: 'PG',
  },
  {
    name: 'Paraguay',
    alpha2Code: 'PY',
  },
  {
    name: 'Pérou',
    alpha2Code: 'PE',
  },
  {
    name: 'Philippines',
    alpha2Code: 'PH',
  },
  {
    name: 'Pologne',
    alpha2Code: 'PL',
  },
  {
    name: 'Portugal',
    alpha2Code: 'PT',
  },
  {
    name: 'Puerto Rico',
    alpha2Code: 'PR',
  },
  {
    name: 'Qatar',
    alpha2Code: 'QA',
  },
  {
    name: 'République de Macédoine du Nord',
    alpha2Code: 'MK',
  },
  {
    name: 'Roumanie',
    alpha2Code: 'RO',
  },
  {
    name: 'Fédération de Russie (la)',
    alpha2Code: 'RU',
  },
  {
    name: 'Rwanda',
    alpha2Code: 'RW',
  },
  {
    name: 'Saint Kitts et Nevis',
    alpha2Code: 'KN',
  },
  {
    name: 'Sainte Lucie',
    alpha2Code: 'LC',
  },
  {
    name: 'Saint-Vincent-et-les Grenadines',
    alpha2Code: 'VC',
  },
  {
    name: 'Samoa',
    alpha2Code: 'WS',
  },
  {
    name: 'Saint-Marin',
    alpha2Code: 'SM',
  },
  {
    name: 'Sao Tomé-et-Principe',
    alpha2Code: 'ST',
  },
  {
    name: 'Arabie saoudite',
    alpha2Code: 'SA',
  },
  {
    name: 'Sénégal',
    alpha2Code: 'SN',
  },
  {
    name: 'Serbie',
    alpha2Code: 'RS',
  },
  {
    name: 'Seychelles',
    alpha2Code: 'SC',
  },
  {
    name: 'Sierra Leone',
    alpha2Code: 'SL',
  }, {
  name: 'Singapour',
  alpha2Code: 'SG',
  },
  {
    name: 'Slovaquie',
    alpha2Code: 'SK',
  },
  {
    name: 'Slovénie',
    alpha2Code: 'SI',
  },
  {
    name: 'Îles Salomon',
    alpha2Code: 'SB',
  },
  {
    name: 'Somalie',
    alpha2Code: 'SO',
  },
  {
    name: 'Afrique du Sud',
    alpha2Code: 'ZA',
  },
  {
    name: 'Soudan du Sud',
    alpha2Code: 'SS',
  },
  {
    name: 'Espagne',
    alpha2Code: 'ES',
  },
  {
    name: 'Sri Lanka',
    alpha2Code: 'LK',
  },
  {
    name: 'Soudan',
    alpha2Code: 'SD',
  },
  {
    name: 'Suriname',
    alpha2Code: 'SR',
  },
  {
    name: 'Suède',
    alpha2Code: 'SE',
  },
  {
    name: 'Suisse',
    alpha2Code: 'CH',
  },
  {
    name: 'République arabe syrienne',
    alpha2Code: 'SY',
  },
  {
    name: 'Taiwan',
    alpha2Code: 'TW',
  },
  {
    name: 'Tadjikistan',
    alpha2Code: 'TJ',
  },
  {
    name: 'Tanzanie',
    alpha2Code: 'TZ',
  },
  {
    name: 'Thaïlande',
    alpha2Code: 'TH',
  },
  {
    name: 'Timor-Leste',
    alpha2Code: 'TL',
  },
  {
    name: 'Togo',
    alpha2Code: 'TG',
  },
  {
    name: 'Tonga',
    alpha2Code: 'TO',
  },
  {
    name: 'Trinité-et-Tobago',
    alpha2Code: 'TT',
  },
  {
    name: 'Tunisie',
    alpha2Code: 'TN',
  },
  {
    name: 'Turquie',
    alpha2Code: 'TR',
  },
  {
    name: 'Turkménistan',
    alpha2Code: 'TM',
  },
  {
    name: 'Tuvalu',
    alpha2Code: 'TV',
  },
  {
    name: 'Ouganda',
    alpha2Code: 'UG',
  },
  {
    name: 'Ukraine',
    alpha2Code: 'UA',
  },
  {
    name: 'Emirats Arabes Unis',
    alpha2Code: 'AE',
  },
  {
    name: 'Royaume-Uni',
    alpha2Code: 'GB',
  },
  {
    name: 'États-Unis d\'Amérique',
    alpha2Code: 'US',
  },
  {
    name: 'Uruguay',
    alpha2Code: 'UY',
  },
  {
    name: 'Ouzbékistan',
    alpha2Code: 'UZ',
  },
  {
    name: 'Vanuatu',
    alpha2Code: 'VU',
  },
  {
    name: 'Venezuela',
    alpha2Code: 'VE',
  },
  {
    name: 'Vietnam',
    alpha2Code: 'VN',
  },
  {
    name: 'Yémen',
    alpha2Code: 'OUI',
  },
  {
    name: 'Zambie',
    alpha2Code: 'ZM',
  },
  {
    name: 'Zimbabwe',
    alpha2Code: 'ZW',
        }
];
