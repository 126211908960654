/*
 *  La directive appHelp nécessite deux éléments siblings : .helpContent et .helpTitle
 */

import { Directive, ElementRef, EventEmitter, AfterViewInit, HostListener, Renderer2, OnDestroy, Output, Input } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { HelpDialogComponent } from '../components/dialogs/help-dialog/help-dialog.component';
import { Subscription } from 'rxjs';
import { ScrollToService, ScrollToConfigOptions } from '@nicky-lenaers/ngx-scroll-to';
import { TagService } from '../services/tagService/tag-service.service';

@Directive({
  selector: '[appHelp]',
  host: {
    '[class.help-done]': 'hasBeenFocused'
  }
})
export class HelpDirective implements AfterViewInit, OnDestroy {
  hasBeenFocused = false;
  showingHelp = false;
  opened = false;
  private isSelect = false;
  element: ElementRef;
  content: string;
  title: string;
  dialogRef: MatDialogRef<HelpDialogComponent>;
  @Input() tag: string;
  @Output() resetState: EventEmitter<null>;
  @Output() helpClosed: EventEmitter<null>;
  private group: ElementRef;
  private screenWidthEnough: boolean;
  subscribed: Subscription;
  timer = null;

  constructor(el: ElementRef, private renderer: Renderer2, public dialog: MatDialog,
              public scroller: ScrollToService, private tagService: TagService) {
    this.element = el;
    this.resetState = new EventEmitter();
    this.helpClosed = new EventEmitter();
  }

  ngAfterViewInit(): void {
    this.renderer.setStyle(this.element.nativeElement, 'position', 'relative');
    const helpBlock = this.renderer.createElement('div');
    this.renderer.addClass(helpBlock, 'help-blocker');
    this.renderer.appendChild(this.element.nativeElement, helpBlock)
    let checkingParent = this.renderer.parentNode(this.element.nativeElement);
    while (!checkingParent.classList.contains('groupForm')) {
      checkingParent = this.renderer.parentNode(checkingParent);
    }
    this.renderer.addClass(checkingParent, 'helped');
    this.group = new ElementRef(checkingParent);
    const contentEl: HTMLElement = this.group.nativeElement.parentNode.querySelector('.helpContent') as HTMLElement;
    const titleEl: HTMLElement = this.group.nativeElement.parentNode.querySelector('.helpTitle') as HTMLElement;
    this.content = contentEl.innerHTML;
    this.title = titleEl.innerText;
  }

  @HostListener('click', ['$event'])
  clickEvent($event: Event) {
    this.showHelp($event);
  }

  @HostListener('focus', ['$event'])
  focus($event) {
    // if (this.showingHelp && typeof(this.element.nativeElement.blur) === 'function') {
    //   this.element.nativeElement.blur();
    // }
    this.showHelp($event);
  }

  @HostListener('openedChange', ['$event'])
  openSelect(event) {
    if (!this.hasBeenFocused) {
      this.isSelect = true;
      document.querySelector('.cdk-overlay-transparent-backdrop').dispatchEvent(new Event('click'));
    } else if (document.querySelector('.helpPanel')) {
      this.renderer.setStyle(document.querySelector('.helpPanel'), 'visibility', 'visible');
    }
  }

  prevent(event) {
    event.stopPropagation();
    event.preventDefault();
  }

  showHelp(event) {
    if (this.hasBeenFocused) {
      return;
    }
    this.prevent(event);
    this.resetState.emit();
    if (this.showingHelp) {
      return;
    }
    this.showingHelp = true;
    this.renderer.setStyle(this.group.nativeElement, 'z-index', 1001);
    const widthNeededforDisplay = (this.group.nativeElement.getBoundingClientRect().left + this.group.nativeElement.offsetWidth) * 2;
    this.screenWidthEnough = window.innerWidth > widthNeededforDisplay;
    const scrollConfig: ScrollToConfigOptions = {
      target: this.group,
      offset: -2 * window.innerHeight / 3,
      duration: 300
    };
    this.scroller.scrollTo(scrollConfig);
    setTimeout(() => this.openModal(), 301);
  }

  openModal() {
    if (this.opened) {
      return;
    }
    this.opened = true;
    let width = this.group.nativeElement.offsetWidth ;
    if (window.innerWidth > this.group.nativeElement.offsetWidth * 2) {
      width = window.innerWidth - 70 - this.group.nativeElement.getBoundingClientRect().left - this.group.nativeElement.offsetWidth;
    }
    this.dialogRef = this.dialog.open(HelpDialogComponent, {
      maxWidth: ( this.screenWidthEnough ? width : window.innerWidth - 100) + 'px',
      panelClass: this.screenWidthEnough ? 'helper-dialog' : ['helper-dialog', 'above'],
      disableClose: true,
      restoreFocus: true,
      data: {
        title: this.title,
        content: this.content,
        element: this.group
      }
    });
    this.subscribed = this.dialogRef.afterClosed().subscribe( () => {
      this.afterClose();
    });
  }

  afterClose() {
    this.showingHelp = false;
    this.renderer.setStyle(this.group.nativeElement, 'z-index', 1);
    this.hasBeenFocused = true;
    this.tagService.sendTagWithLabel('Click', this.tag);
    if (this.subscribed) {
      this.subscribed.unsubscribe();
    }
    if (this.isSelect) {
      this.element.nativeElement.click();
      if (document.querySelector('.helpPanel')) {
        this.renderer.removeClass(document.querySelector('.helpPanel'), 'helpPanel');
      }
    }
    this.helpClosed.emit()
  }

  ngOnDestroy() {
    if (this.subscribed) {
      this.subscribed.unsubscribe();
    }
  }
}
