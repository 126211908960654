<div>
  <mat-icon>support_agent</mat-icon>
  <span>Besoin d'aide ?</span>
</div>
<div>
  <div>
    <!--<h4>Contactez le service client GS1 France au <b>01.40.22.18.00.</b></h4>
    <h4>Nous sommes joignables du lundi au vendredi entre 8h30 et 17h30.</h4>-->
    <span class="gs1-little">Vous rencontrez un problème ou avez une question ?</span>
    <button gs1Button [appearance]="'clear'" [color]="'link'" [thin]="true" id="contactServiceClient" (click)="launchModal()">Le service client vous rappelle</button> 
  </div>
  <div class="action-bar"><button (click)="close()" mat-button><mat-icon>close</mat-icon></button></div>

</div>
