<form [formGroup]="billContactForm" #formBillContact="ngForm" (ngSubmit)="saveContact()">
  <h3 mat-dialog-title>Ajout d'un contact de facturation</h3>

  <mat-dialog-content class="mat-dialog-content ">

    <gs1-radios [block]="true">
      <gs1-radio formControlName="genderId" [name]="'monsieur'" [value]="genderIdValues.Monsieur">Monsieur</gs1-radio>
      <gs1-radio formControlName="genderId" [name]="'madame'" [value]="genderIdValues.Madame">Madame</gs1-radio>
      <span slot="error" *ngIf="displayError('genderId', 'required')">Le genre est requis</span>
    </gs1-radios>

    <div class="columns one-to-one">

      <gs1-input formControlName="lastname" [label]="'Nom'" [name]="'lastname'">
        <span slot="error" *ngIf="displayError('lastname', 'required')">Un nom est requis</span>
        <span slot="error" *ngIf="displayError('lastname', 'minlength')">Un nom doit comprendre au moins 2 caractères</span>
        <span slot="error" *ngIf="displayError('lastname', 'pattern')">Ce nom n'est pas valide</span>
      </gs1-input>

      <gs1-input formControlName="firstname" [label]="'Prénom'" [name]="'firstname'">
        <span slot="error" *ngIf="displayError('firstname', 'required')">Un prénom est requis</span>
        <span slot="error" *ngIf="displayError('firstname', 'minlength')">Un prénom doit comprendre au moins 2 caractères</span>
        <span slot="error" *ngIf="displayError('firstname', 'pattern')">Ce prénom n'est pas valide</span>
      </gs1-input>
    </div>

    <gs1-input formControlName="street" [name]="'street'" [label]="'Adresse principale'">
      <span slot="error" *ngIf="displayError('street', 'required')">L'adresse est requise</span>
    </gs1-input>
    
    <gs1-input formControlName="street2" [name]="'street2'" [label]="'Complément d\'adresse (optionnel)'"></gs1-input>

    <div class="columns one-to-one">
      <gs1-input [label]="'Code postal'" formControlName="zipCode" [name]="'zipCode'"></gs1-input>

      <gs1-input [label]="'Ville'" formControlName="city" [name]="'city'"></gs1-input>

      <gs1-select-wrapper>
        <label for="functionId">Fonction</label>
        <ng-select formControlName="functionId" name="functionId" [bindValue]="'id'" [bindLabel]="'label'" [items]="fonctions | async"
          [clearable]="false" [searchable]="false" [multiple]="false"  [closeOnSelect]="true"></ng-select>
        <span slot="error" *ngIf="displayError('functionId', 'required')">La fonction est requise</span>
      </gs1-select-wrapper>


      <gs1-phone formControlName="phone" [name]="'phone'" [label]="'Téléphone'">
        <span slot="error" *ngIf="displayError('phone', 'required')">Le téléphone est requis</span>
        <span slot="error" *ngIf="displayError('phone', 'validatePhoneNumber')">Ce téléphone n'est pas valide</span>
      </gs1-phone>

      
    </div>

    <gs1-input formControlName="email" name="email" [showValid]="true" [label]="'Email professionnel'" [helper]="'Il sera également l\'identifiant de connexion'">
      <span slot="error" *ngIf="displayError('email', 'required')">L'adresse email est requise</span>
      <span slot="error" *ngIf="displayError('email', 'email')">L'email doit être de la forme xxx@xxx.xx et sans accents</span>
      <span slot="error" *ngIf="displayError('email', 'emailsMatches')">L'email de facturation ne peut pas être le même que celui de l'entreprise, veuillez saisir une autre adresse</span>
    </gs1-input>
  </mat-dialog-content>
  <div mat-dialog-actions>
      <button gs1Button type="button" [mat-dialog-close]="billContactForm.value" [appearance]="'outlined'">Annuler</button>
      <button gs1Button [disabled]="formBillContact.valid !== true" type="submit">Enregistrer</button>
  </div>
</form>
