<app-stepper [step]=3></app-stepper>
<div class="page-content">
  <app-support-sticker *ngIf="isNeedHelpEnabled"[currentForm]="null"></app-support-sticker>
  <div class="intro">
    <h3> Que souhaitez-vous identifier ? </h3>
    <p>Pour vous permettre de créer vos codes GS1, nous allons vous attribuer un préfixe unique réservé à votre entreprise et une plage de codification.</p>    
    <p>Vous pourrez créer vos codes et les utiliser pour identifier vos produits, vos lieux, ou les deux.</p>
  </div>

<form *ngIf="needForm" [formGroup]="needForm" #formNeed="ngForm" (ngSubmit)="saveAndProceed()">
 
  <gs1-accordion>
    <gs1-accordion-item [opened]="checkedToOpen" [hideAccordionControls]="true" [color]="'orange'">
      <gs1-checkbox slot="header" (checkChanged)="changingcheck($event)" [name]="'openAccordion'">
        <span class="gs1-color-blue gs1-accordion-header">Je souhaite identifier des produits avec des GTIN et leur code-barres</span>
      </gs1-checkbox>
    <gs1-form-group class="first-form">
      <span class="gs1-color-blue">Vous souhaitez utiliser des codes-barres/ GTIN pour identifier des produits…</span>
      <gs1-checkbox-group [label]="'Plusieurs choix possibles :'" [groupControl]="needForm.get('brandOwnership')" formGroupName="brandOwnership" [linear]="true">
        <gs1-checkbox [name]="'isBrandOwner'" formControlName="isBrandOwner">
          à votre marque <i class="bi bi-question-circle"   matTooltip="Vous êtes propriétaire de la marque commerciale des produits que vous souhaitez identifier." matTooltipPosition="above" ></i>
 
        </gs1-checkbox>

        <gs1-checkbox [name]="'isBrandTiers'" formControlName="isBrandTiers">
          de marque tierce <i class="bi bi-question-circle" matTooltip="Vous n'êtes pas propriétaire de la marque commerciale des produits que vous souhaitez identifier." matTooltipPosition="above"></i>
        </gs1-checkbox>
        <gs1-checkbox [name]="'isWithoutBrand'" formControlName="isWithoutBrand">
          sans marque <i class="bi bi-question-circle" matTooltip="Vous souhaitez identifier un produit qui n’a pas de marque commerciale." matTooltipPosition="above"></i>
        </gs1-checkbox>
        <span slot="error" *ngIf="displayError('brandOwnership', 'notSelected')">Veuillez cocher au moins l'un de ces champs</span>
      </gs1-checkbox-group>
      <gs1-alert class="gs1-spacer-3" *ngIf="infoBlockToShow !== ''" [type]="infoBlockToShow === 'Owner' ? 'success' : infoBlockToShow === 'Other' ? 'warning' : 'info'" [title]="'Règles de codification GS1'">
        <div class="gs1-little">
  
          <p>
            Le GTIN identifie de manière unique et non ambiguë le produit. <b>1 GTIN = 1 produit et 1 produit = 1 GTIN.</b>
            <br>
            <span *ngIf="infoBlockToShow === 'Without'">Seul le fabricant</span><span *ngIf="infoBlockToShow !== 'Without'">Seul le propriétaire de la marque commerciale</span> ou <span class="tooltip-text"   [matTooltip]="'Il s\'agit du propriétaire du cahier des charges du produit qui peut sous-traiter à des tiers la fabrication du produit.'">le détenteur des spécifications techniques</span> est habilité à codifier les produits.              
            <span [ngClass]="{'warning': ['Other','WithoutAndOther'].includes(infoBlockToShow)}"><br><u>Codifier à sa place est contraire aux règles de codification GS1.</u></span>
          </p>



          <span *ngIf="infoBlockToShow === 'OwnerAndWithoutAndOther' || infoBlockToShow === 'OwnerAndOther' ||  infoBlockToShow === 'OwnerAndWithout' ||  infoBlockToShow === 'Other'">
            <b [style.color]="infoBlockToShow === 'Other' ? '#a15700' : '#002c6c'">Dans votre cas:</b><br>
            <ol class="step-3-list">
              <li class="valid" *ngIf="infoBlockToShow === 'OwnerAndOther' || infoBlockToShow === 'OwnerAndWithoutAndOther' ||  infoBlockToShow === 'OwnerAndWithout'"><mat-icon class="icon">check_circle</mat-icon><span>Vous pouvez codifier les produits de <u>vos marques commerciales</u>. </span></li>
              <li class="error" *ngIf="infoBlockToShow === 'OwnerAndWithoutAndOther' ||  infoBlockToShow === 'OwnerAndOther'"><mat-icon class="icon">cancel</mat-icon><span>Pour les produits de <u>marque tierce</u>, vous n’êtes pas habilité à les codifier.</span></li>
              <li class="warning" *ngIf="infoBlockToShow === 'OwnerAndWithoutAndOther' || infoBlockToShow === 'OwnerAndWithout'"><mat-icon class="icon">error</mat-icon><span>Pour les produits <u>sans marque</u>, vous êtes habilité à les codifier uniquement si vous êtes le fabricant ou le détenteur des spécifications techniques.</span></li>
            </ol> 
          </span>
        
    
          <p  class="gs1-little" *ngIf="['WithoutAndOther','Without'].includes(infoBlockToShow)">
            <span *ngIf="infoBlockToShow === 'Without'">Etes-vous</span><span *ngIf="infoBlockToShow === 'WithoutAndOther'">Pour les produits sans marque, êtes-vous</span> le fabricant ou le détenteur des spécifications techniques ?
            <mat-radio-group formGroupName="isMaker" class="maker-group">
              <mat-radio-button class="gs1-color-ui-7" name="maker" value="yes" (click)="isMaker(true)">Oui</mat-radio-button>
              <mat-radio-button class="gs1-color-ui-7" name="maker" value="no" (click)="isMaker(false)">Non</mat-radio-button>
            </mat-radio-group>
          </p>

          <ol class="step-3-list">
            <li class="valid" *ngIf="['WithoutAndOther','Without', 'Other'].includes(infoBlockToShow) && showNeeds"  ><mat-icon class="icon">check_circle</mat-icon><span>Dans ce cas vous êtes habilité à codifier des produits sans marque.</span></li>
          </ol>
    
          <ng-container *ngIf="infoBlockToShow === 'Other' || ['WithoutAndOther','Without'].includes(infoBlockToShow) && searchCodeResult">
            <ol class="step-3-list">
              <li class="error" *ngIf="!showNeeds"><mat-icon class="icon">cancel</mat-icon><span><u>Vous n’êtes pas habilité à codifier ce produit</u>. Nous vous invitons à rechercher le GTIN afin de ne pas créer un doublon.</span></li>
            </ol>
            <p class="important">Comment trouver le <span class="tooltip-text" (click)="displayTooltip($event)">GTIN<div class="false-tooltip"><img src='/assets/images/Code_EAN13.jpg'/><span>Le GTIN dans cet exemple est 3200000003699</span></div></span> de mon produit ?</p>
            <ul>
              <li>En regardant le code-barres présent sur le produit ou son emballage, le GTIN correspond à la suite de chiffres sous les barres.</li>
              <li>En demandant au fabricant ou au détenteur de la marque commerciale du produit de vous communiquer le GTIN.</li>
            </ul>
          </ng-container>
        </div>
      </gs1-alert>
      
      <gs1-radios *ngIf="infoBlockToShow === 'Other' || ['WithoutAndOther','Without'].includes(infoBlockToShow) && searchCodeResult">
          <gs1-radio formControlName="codeResearchedAndFound" [name]="'no-found-codes'" [label]="'Je certifie avoir compris les règles de codification GS1 et souhaite poursuivre mon adhésion.'" [value]="false" (click)="setShowNeed(true)"></gs1-radio>
          <gs1-radio formControlName="codeResearchedAndFound" [name]="'found-codes'" [label]="'J\'ai retrouvé les GTIN de mes produits. Je ne souhaite pas adhérer.'" [value]="true" (click)="setShowNeed(false)"></gs1-radio>
      </gs1-radios>

    </gs1-form-group>
    <div #needFormGroup></div>
    <ng-container *ngIf="showNeeds">
      <gs1-form-group>
        <div class="rows one-to-two codes">
          <div class="groupForm">
            <h4 class="title">Nombre de code(s) GTIN souhaité(s) pour identifier vos produits</h4>
            <span class="description">Estimez votre besoin en codes sur les prochaines années car ils sont utilisables sans limite de durée tant que vous maintenez votre adhésion.</span> 

            <gs1-input [type]="'number'" [inputSize]="'200px'" name="number-codes" formControlName="need" #needInput>
              <span slot="error" *ngIf="displayError('need', 'required')">Veuillez saisir votre besoin en nombre de codes.</span>
              <span slot="error" *ngIf="displayError('need', 'min')">Le nombre de codes doit être supérieur à 0.</span>
              <span slot="error" *ngIf="displayError('need', 'max')">Vous ne pouvez pas dépasser 999999 codes.</span>
              <span slot="error" *ngIf="displayError('need', 'pattern')">Merci de renseigner un nombre.</span>
            </gs1-input>
          </div>

          <div class="block-info2">
            <h4>Exemples d'estimations</h4>
            <div class="examples"> 
            <div class="example"> 
              <h5>Textile</h5>
              <div class="code-count">
                <span class="detail">2 modèles x 3 couleurs x 2 tailles = </span> 
                <span class="detail b">&nbsp; 12 codes</span>
              </div>
              <div class="products">
                <div class="product">
    
                  <img src="/assets/images/Textile/bigShirts.svg"
                  class="img">
                </div>
                <div class="product">
                  <img src="/assets/images/Textile/smallShirts.svg"
                  class="img">
                </div>
              </div>
             
            </div>
            <mat-divider></mat-divider>
            <div class="example"> 
              <h5>Vins et Spiritueux</h5>
              <div class="code-count">
                <span class="detail">2 formats x 2 couleurs de vin = </span>
                <span class="detail b">&nbsp; 4 codes</span>  
              </div>
              <div class="products">
                <div class="product">
    
                  <img src="/assets/images/bouteilles/BigBottles.svg"
                  class="img">
                </div>
                <div class="product">
                  <img src="/assets/images/bouteilles/SmallBottles.svg"
                  class="img">
                </div>
              </div>
              </div>
            </div>
          </div>
        </div>
      </gs1-form-group>


    </ng-container>
  </gs1-accordion-item>
  </gs1-accordion>
</form>
<form *ngIf="needFormGln" [formGroup]="needFormGln" #formNeedGln="ngForm" (ngSubmit)="saveAndProceed()">

  <gs1-accordion>
  <gs1-accordion-item [opened]="checkedToOpenGln" [hideAccordionControls]="true" [color]="'orange'">

    <gs1-checkbox slot="header" (checkChanged)="changingcheckGln($event)" [name]="'openAccordionGln'">
      <span class="gs1-color-blue gs1-accordion-header">Je souhaite identifier des lieux, des entités avec des GLN</span>
    </gs1-checkbox>

    <gs1-alert class="gs1-spacer-3"  [type]="'info'" [title]="'Lors de votre adhésion, un code GLN vous est automatiquement attribué'">
      <div class="gs1-little">
        <p> Il identifie votre entreprise de manière unique en France et à l’international. 
          <br> Si vous avez besoin d’identifier de façon distincte d’autres lieux ou entités, merci d’en préciser le nombre ci-dessous.
        </p>

          <p><b> Règles de codification GS1 :</b><br>
          Seul le propriétaire ou responsable de l’entité, du lieu ou de la fonction est habilité à codifier ces derniers avec un GLN.
        <br><u>Codifier à sa place est contraire aux règles de codification GS1.</u>
      </div>

    </gs1-alert>
    <div class="gs1-little">
      <p>Si vous disposez <u>d’au moins deux lieux (physique ou digital)</u> à identifier dans le cadre d’échanges inter-entreprise, veuillez poursuivre ci-dessous.</p>
    </div>

    <gs1-form-group class="gln-needs">
      <div class="rows one-to-two codes">
        <div class="groupForm">
          <h4 class="title">Nombre de code(s) GLN souhaité(s) pour identifier vos lieux et entités</h4>
          <span class="description">Estimez votre besoin en codes sur les prochaines années car ils sont utilisables sans limite de durée tant que vous maintenez votre adhésion.</span> 

          <gs1-input [type]="'number'" [inputSize]="'200px'" name="number-codes" formControlName="glnNeed" #needInputGln>
            <span slot="error" *ngIf="displayErrorGln('glnNeed', 'required')">Veuillez saisir votre besoin en nombre de codes.</span>
            <span slot="error" *ngIf="displayErrorGln('glnNeed', 'min')">Le nombre de codes doit être supérieur à 0.</span>
            <span slot="error" *ngIf="displayErrorGln('glnNeed', 'max')">Vous ne pouvez pas dépasser 999999 codes.</span>
            <span slot="error" *ngIf="displayErrorGln('glnNeed', 'pattern')">Merci de renseigner un nombre.</span>
          </gs1-input>
        </div>

        <div class="block-info2">
          <h4>Exemples de lieux, entités à codifier</h4>
          <div class="examples"> 
          <div class="example gln"> 
            <h5>Lieu physique</h5>
            <div class="code-count">
              <span class="detail-gln">Un lieu physique fixe est un lieu tangible non mobile pouvant être 
                identifié par une adresse postale ou des coordonnées GPS.</span> 
            </div>
            <div class="products gln">
              <div class="product gln">
                <img src="/assets/images/lieux/lieuPhysique.svg"

                class="img">
              </div>
            </div> 
          </div>
          <mat-divider></mat-divider>
          <div class="example gln"> 
            <h5>Une fonction/ entité</h5>
            <div class="code-count">
              <span class="detail-gln">Une fonction est une subdivision ou un département d’une entreprise. 
                 Elles sont généralement segmentées selon les tâches effectuées au sein d’une organisation.</span>
            </div>
            <div class="products gln">
              <div class="product gln">
                <img src="/assets/images/fonctions/fonction.svg"
                class="img">
              </div>
            </div>
          </div>

          <div class="example gln"> 
            <h5>Lieu digital</h5>
            <div class="code-count">
              <span class="detail-gln">Une fonction est une subdivision ou un département d’une entreprise. 
                Elles sont généralement segmentées selon les tâches effectuées 
                au sein d’une organisation.</span>
            </div>
            <div class="products gln">
              <div class="product gln">
                <img src="/assets/images/lieuDigital/lieuDigital.svg"
                class="img">
              </div>
            </div>
            </div>
            
          </div>
        </div>
      </div>
    </gs1-form-group>
    </gs1-accordion-item>
</gs1-accordion>

</form>
 
<div *ngIf="showSummary">

<gs1-link-card [title]="'Récapitulatif de votre besoin'">
   
  <span *ngIf="checkedToOpen && checkedToOpenGln && nbCode && nbLocation " class="rappel">
    Vous avez demandé {{nbCode}} codes pour identifier vos produits et {{nbLocation}} codes pour identifier vos lieux. Nous allons vous attribuer une plage de <span style='color:#002c6c'><b> {{realRange}} codes </b> </span>
   qui vous permettra d'identifier à la fois vos produits et vos lieux.
    <!-- Compte tenu du nombre de codes souhaités, nous allons vous attribuer une plage de <span style='color:#002c6c'><b> {{realRange}} codes. </b> </span>
    <br>À savoir : chaque code peut être utilisé à la fois pour identifier un produit et pour identifier un lieu/ une entité.  -->
  </span>   
  <span *ngIf="checkedToOpen && !checkedToOpenGln && nbCode && !nbLocation " class="rappel">
    Vous avez demandé {{nbCode}} codes pour identifier vos produits. Nous allons vous attribuer une plage de <span style='color:#002c6c'><b> {{realRange}} codes. </b> </span>
  </span>  
  <span *ngIf="!checkedToOpen && checkedToOpenGln && !nbCode && nbLocation " class="rappel">
    Vous avez demandé {{nbLocation}} codes pour identifier vos lieux. Nous allons vous attribuer une plage de <span style='color:#002c6c'><b> {{realRange}} codes. </b> </span>
  </span> 
  <span class="rappel" *ngIf="depassement"><br><br>Vous pouvez attribuer le même code de 13 chiffres à un produit, en tant que GTIN, et à un lieu, en tant que GLN. Pas de risque d’erreur, les contextes d’usage sont différents !</span>

</gs1-link-card>

<gs1-link-card [title]="'Montant de votre adhésion'">
  <div class="price-card gs1-spacer-5">
    <div class="columns price-info">
      <p class="gs1-color-blue gs1-little rappel"><span> <i>Les réponses apportées ci-dessus sont indépendantes du montant de votre adhésion, <u>calculé sur la base de votre chiffre d’affaires déclaré en étape 2.</u></i></span></p>
      <span class="price"><span class="real-price">{{(tarif | async)?.totalAmountTTC | number:'1.2-2':'fr-FR'}} € TTC</span><p class="ht-price">{{(tarif | async)?.totalAmountHT | number:'1.2-2':'fr-FR'}} € HT</p></span>
    </div>
  </div>    

</gs1-link-card>

<div class="action-bar">
  <button id="nextStep" gs1Button [disabled]="isLoading || (checkedToOpen && !needForm.valid) || (checkedToOpenGln && !needFormGln.valid) || !checkedToOpen && !checkedToOpenGln" (click)="saveOrConfirmNeed()">Continuer</button>
</div>
</div>
<ng-template #noNeedLeaveDialog>
  <h3 class="dialogTitle" matDialogTitle>Vous êtes sur le point de quitter votre adhésion</h3>
  <mat-dialog-content>
    <p>GS1 France vous remercie pour votre visite et reste à votre disposition si vous avez de nouveaux besoins en codification.</p>
  </mat-dialog-content>
  <mat-dialog-actions>
      <button  gs1Button [appearance]="'outlined'" matDialogClose (click)="selectGoodInfoBlock()">Annuler</button>
      <a href="https://www.gs1.fr" (click)="sendLeavingTag()"gs1Button matDialogClose cdk-focus-start >Quitter le parcours</a>
  </mat-dialog-actions>
</ng-template>
<ng-template #confirmDialog>
  <h3 matDialogTitle>Merci de confirmer votre besoin en codification</h3>
  <mat-dialog-content>
    <p class="gs1-little">
      Une plage de {{realRange}} codes vous est attribuée. Si vous n'avez pas saisi le bon nombre de codes, vous pouvez le corriger en cliquant sur le bouton "Je corrige".
      <!-- A titre d'information, un email de confirmation de création de votre profil sera envoyé à l'adresse <span class="important">{{contactForm.get('email').value}}</span>. Si cette adresse est incorrecte, vous pouvez la corriger.</p> -->
  </mat-dialog-content>
  <mat-dialog-actions>
    <button gs1Button matDialogClose [appearance]="'outlined'" (click)="correctNeed()">Je corrige</button>
    <button gs1Button cdk-focus-start (click)="saveAndProceed()">Je confirme</button>
  </mat-dialog-actions>
</ng-template>