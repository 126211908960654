import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NavigationEnd, Router, RouterState } from '@angular/router';
import { AdhesionService } from 'src/app/services/adhesion/adhesion.service';
import { LoginService } from 'src/app/services/login/login.service';
import { TagService } from 'src/app/services/tagService/tag-service.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  @ViewChild('confirmLeaveDialog', { static: true }) leaveModal: TemplateRef<any>;
  isWineAndSpirits = false;
  headerText = 'Votre adhésion à GS1 France';
  constructor(public loginService: LoginService, public dialog: MatDialog, public tagService: TagService, private adhesionService: AdhesionService, private router:Router) { }

  ngOnInit(): void {
    this.router.events.subscribe(event => {
      if(event instanceof NavigationEnd && event.url.includes('confirmation-adhesion-vins-et-spiritueux')) {
        this.isWineAndSpirits = true;
        this.headerText = 'Bienvenue chez GS1 France'
      }
    })
  }

  openLeaveModal() {
    this.dialog.open(this.leaveModal);
    this.tagService.sendTagWithStep('Leave funnel CTA');
  }

  sendLeavingTag() {
    this.tagService.sendTagWithStep('Leave funnel confirmation');
    const leaveUrl = this.loginService.getLogoutUrl();
    this.loginService.logOut();
    window.location.href = leaveUrl;
  }

  isDone() {
    return this.adhesionService.isFinished();
  }

}
